// @flow
import type { CurrencyType } from 'types/resources/currency';

import { expireIn, getSGTTime, isCurrentDateInRange } from 'utils/time';
import { getCurrentCurrency, $ } from 'utils/currency';
import { MIL_SECS_IN_DAY } from 'constants/constants';

import config from 'constants/environment';

const { start, end } = config.campaign;

const isCampaignTime = !!(start && end && isCurrentDateInRange(start, end));
const isLastDayOfCampaign =
  end && Math.ceil((new Date(end) - getSGTTime()) / MIL_SECS_IN_DAY) === 1;

const checkAvailability = discount => !!discount;

const isSellingFeeDiscountAvailable = ({
  discount,
  type,
}: {
  discount: number,
  type: 'fixed' | 'fixed-reduction' | 'percentage',
}): boolean => (type === 'fixed' && discount >= 0) || discount > 0;

const isVoucherDiscountAvailable = ({
  shippingDiscount,
  sneakerDiscount,
  apparelDiscount,
  collectiblesDiscount,
  allDiscount,
}: {
  shippingDiscount: number,
  sneakerDiscount: number,
  apparelDiscount: number,
  collectiblesDiscount: number,
  allDiscount: number,
}) => ({
  Shipping: checkAvailability(shippingDiscount),
  Sneakers: checkAvailability(sneakerDiscount),
  Apparel: checkAvailability(apparelDiscount),
  Collectibles: checkAvailability(collectiblesDiscount),
  All: checkAvailability(allDiscount),
});

const campaignTimeLeft = end ? expireIn(end, 'hour', true) : '';
const daySinceCampaignStarted = Math.floor(
  (getSGTTime() - new Date(config.campaign.start || '')) / MIL_SECS_IN_DAY
);

const campaignPromoValue = [30, 25, 90, 150, 30, 300000, 3000, 30];
const getCampaignPromoDiscount = (): string => {
  const { id: currencyId = 2 }: CurrencyType = getCurrentCurrency();
  return $(campaignPromoValue[currencyId - 1]);
};

const campaignConfig = {
  showSellAlertMessage: true,
  showPromotionDialog: false,
  isCampaignTime,
};

export {
  isSellingFeeDiscountAvailable,
  isVoucherDiscountAvailable,
  getCampaignPromoDiscount,
  daySinceCampaignStarted,
  isLastDayOfCampaign,
  campaignTimeLeft,
  isCampaignTime,
  campaignConfig,
};
