// @flow
export default {
  s3: {
    imagesBucket: process.env.REACT_APP_S3_IMAGES_BUCKET || 'novelship-images',
    mainBucket: process.env.REACT_APP_S3_MAIN_BUCKET || 'novelship',
  },
  campaign: {
    start: process.env.REACT_APP_CAMPAIGN_START,
    end: process.env.REACT_APP_CAMPAIGN_END,
  },
  mobileApp: {
    appleId: process.env.REACT_APP_APPLE_APP_ID || '',
    scheme: process.env.REACT_APP_MOBILE_SCHEME || '',
    appleRating: process.env.REACT_APP_APP_APPLE_RATING,
  },
  algolia: {
    appId: process.env.REACT_APP_ALGOLIA_APP_ID || '',
    apiKey: process.env.REACT_APP_ALGOLIA_API_KEY || '',
    indice: process.env.REACT_APP_ALGOLIA_INDICE || '',
    pagingLimit: parseInt(process.env.REACT_APP_ALGOLIA_PAGING_LIMIT, 10) || 500,
  },
  facebook: {
    pixelID: process.env.REACT_APP_FACEBOOK_PIXEL_ID,
  },
};
