// @flow

import { currentLanguage } from 'store/constants/language';
import type { ProductType } from 'types/resources/product';
import { hasLatinChars } from 'utils';

const getMax = (a, b) => (a > b ? a : b);

const productGetEffectiveWeight = (
  product: ProductType | any = { actual_weight: 0, vol_weight: 0 },
  totalAddOnsWeight: { vol_weight: number, actual_weight: number } = {
    vol_weight: 0,
    actual_weight: 0,
  }
) => {
  const weight = getMax(
    product.vol_weight + totalAddOnsWeight.vol_weight,
    product.actual_weight + totalAddOnsWeight.actual_weight
  );
  const roundTo = weight < 5000 ? 500 : 1000;

  return Math.ceil(weight / roundTo) * roundTo;
};

const LangMap: { [key: string]: string } = {
  en: 'EN',
  id: 'ID',
  'zh-Hans': 'CN',
  'zh-Hant-TW': 'CN',
  ja: 'JP',
  es: 'ES',
  ko: 'KO',
};

function getCelebrityName(celebrity: Object) {
  const language = LangMap[currentLanguage];
  if (celebrity && language in celebrity) {
    return celebrity[language];
  }
}

const getProductListPrice = (product: ProductType, countryId: number) =>
  product.country_lowest_list_prices?.[countryId] ??
  // $FlowFixMe don't know how to type
  product[`${countryId}_price`] ??
  product.lowest_listing_price;

const replaceQuotedText = (input: string, replacement: string): string => {
  if (!input) return '';
  if (!replacement) return input;
  const regex = /'(.*?)'|"(.*?)"/g;
  return input.replace(regex, `'${replacement}'`);
};

const ProductGetLocalName = (name: string, product: ProductType) => {
  if (!name) return name;

  switch (currentLanguage) {
    case 'zh-Hant-TW':
      return replaceQuotedText(name, product.nickname_zh);
    default:
      return name;
  }
};

const ProductGetDisplaySKU = (sku?: string) => {
  if (!sku) return;
  if (hasLatinChars(sku)) return;
  return sku;
};

export {
  productGetEffectiveWeight,
  getCelebrityName,
  getProductListPrice,
  ProductGetLocalName,
  ProductGetDisplaySKU,
};
