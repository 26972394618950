// @flow
import { lazy } from 'react';

const reloadOnFail = fn =>
  new Promise(res =>
    fn()
      .then(res)
      .catch(() => window.location.reload(true))
  );

const safeLazy = (fn: () => any): any => lazy(() => reloadOnFail(fn));

export default safeLazy;
