// @flow
import type { ProductType } from 'types/resources/product';

import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Trans } from '@lingui/macro';

import { Button, Text, Box } from 'components/base';
import AlgoliaInsights from 'services/algolia-insights';
import Spinner from 'components/misc/Spinner';
import env from 'constants/environment';

import SearchResultsNotFound from './components/SearchResultsNotFound';
import SearchResultsFound from './components/SearchResultsFound';
import SearchProductCard from './components/SearchProductCard';

const SearchResults = ({ closeSearchSidebar }: { closeSearchSidebar: () => void }) => {
  const { search, isSearching, isLoadingMore, searchProducts, searchProductsFound } = useStoreState(
    s => s.search
  );
  const userId = useStoreState(s => s.user.user.id);
  const { setIsLoadingMore, loadMore, debouncedFetchSearchProducts } = useStoreActions(
    a => a.search
  );

  const showMoreProducts = () => {
    setIsLoadingMore(true);
    loadMore({
      page: searchProducts.results.length / 20,
      search,
    });
  };

  const onProductClick = (product: ProductType, index: number) => {
    closeSearchSidebar();
    AlgoliaInsights.productClicked({
      userId,
      position: index,
      sort: 'search',
      ...product,
    });
  };

  useEffect(() => {
    if (search) {
      debouncedFetchSearchProducts({ search });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      {isSearching ? (
        <Box p={5} textAlign="center">
          <Spinner />
        </Box>
      ) : searchProductsFound ? (
        <>
          <SearchResultsFound
            search={search}
            onSearchResultClick={closeSearchSidebar}
            resultsCount={searchProducts.totalByClasses}
          />
          <Box mt={5} mx={4} flexDirection="row">
            <Text fontWeight="bold">
              <Trans>TOP RESULTS</Trans>
            </Text>
          </Box>
        </>
      ) : (
        <>
          <SearchResultsNotFound search={search} onProductRequestClick={closeSearchSidebar} />
          {!!searchProducts.results.length && (
            <>
              <Text px={5} py={3} fontWeight="bold">
                <Trans>MOST POPULAR</Trans>
              </Text>
              <Box className="h-divider" />
            </>
          )}
        </>
      )}
      {!isSearching &&
        searchProducts.results.map((p, i) => (
          <SearchProductCard
            key={p.objectID}
            product={p}
            onProductClick={() => onProductClick(p, i)}
          />
        ))}
      <Box p={5}>
        {!isSearching &&
          searchProducts.results &&
          searchProducts.results.length < searchProducts.total &&
          searchProducts.results.length < env.algolia.pagingLimit && (
            <Button variant="white" width="100%" onClick={showMoreProducts} loading={isLoadingMore}>
              <Trans>SHOW MORE</Trans>
            </Button>
          )}
      </Box>
    </>
  );
};

export default SearchResults;
