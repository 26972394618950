// @flow
import type { ProductType } from 'types/resources/product';

import React from 'react';

import { Image, Link, Flex, Text, Box } from 'components/base';

import InstantAvailableIndicator from 'components/product/InstantAvailableIndicator';
import { ProductGetLocalName } from 'utils/product';

const SearchProductCard = ({
  product,
  onProductClick,
}: {
  product: ProductType,
  onProductClick: () => void,
}) => (
  <Link to={`/${product.name_slug}`} onClick={onProductClick}>
    <Flex p={5} width="100%">
      <Box className="center" mr={5} pt={2} width={74} style={{ position: 'relative' }}>
        <InstantAvailableIndicator
          isInstantAvailable={product.is_instant_available}
          justifyContent="flex-end"
          view="icon"
          size="sm"
          style={{ position: 'absolute', right: 0, top: 0 }}
        />
        <Image src={product.image} height={40} />
      </Box>

      <Box flex={1}>
        <Text color="gray2" fontSize={1}>
          {product.category_level_1}
        </Text>
        <Text color="black3" fontSize={2} fontWeight="bold" lineHeight={1.3}>
          {ProductGetLocalName(product.name, product)}
        </Text>
      </Box>
    </Flex>
    <Box className="h-divider" />
  </Link>
);

export default SearchProductCard;
