import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { Trans } from '@lingui/macro';

import { Link, Text, Flex, Box } from 'components/base';
import theme from 'styles/theme';
import Analytics from 'services/analytics';
import slugify from 'utils/string/slugify';

const SearchResultsFound = ({
  search,
  resultsCount,
  onSearchResultClick,
}: {
  search: string,
  resultsCount?: { Sneakers: number, Apparel: number, Collectibles: number, All: number },
  onSearchResultClick: () => void,
}) => (
  <Box width="100%">
    {['Sneakers', 'Apparel', 'Collectibles', 'Trading Cards', 'All'].map(
      productClass =>
        !!(resultsCount && resultsCount[productClass]) && (
          <Link
            onClick={() => {
              Analytics.productSearchView(search, Number(resultsCount[productClass]));
              onSearchResultClick();
            }}
            key={productClass}
            to={`/${
              productClass === 'All' ? 'search' : slugify(productClass).toLowerCase()
            }?q=${search}`}
          >
            <Flex
              px={5}
              height={60}
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                {productClass !== 'All' ? (
                  <Flex fontSize={2} fontWeight="bold">
                    {search}&nbsp;
                    <Text color="gray3">
                      <Trans>in {productClass}</Trans>
                    </Text>
                  </Flex>
                ) : (
                  <Flex fontSize={2} fontWeight="bold">
                    <Text color="gray3">
                      <Trans>All results for</Trans>
                    </Text>
                    &nbsp;{search}
                  </Flex>
                )}
                <Text mt={1} color="gray3" fontSize={1}>
                  <Trans>{resultsCount[productClass]} results</Trans>
                </Text>
              </Box>
              <FaChevronRight size={20} color={theme.colors.textBlack} />
            </Flex>
            <Box className="h-divider" />
          </Link>
        )
    )}
  </Box>
);

export default SearchResultsFound;
