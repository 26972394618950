// @flow
import React from 'react';
import { space, layout, typography, color, system } from 'styled-system';
import { Link as RouterLink } from '@reach/router';
import styled from 'styled-components';

const textDecoration = system({
  textDecoration: true,
});

const StyledLink = styled(RouterLink)`
  ${space}
  ${layout}
  ${typography}
  ${color}
  ${textDecoration}
`;

const Link = ({ to, children, ...props }: { to: string, children: any }) => {
  const url = to && to.replace(window.location.origin, '');
  const isExternal =
    (url && /:\/\/|\/test-api\/|\/api\/|\/edge\/|\/partners|\/news|\/news-tw|\/blog/.test(url)) ||
    undefined;

  return (
    <StyledLink
      to={encodeURI(url)}
      href={url}
      as={to ? isExternal && 'a' : 'div'}
      target={isExternal && '_blank'}
      rel={isExternal && 'noopener noreferrer'}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
