import country from 'store/resources/country';
import currency from 'store/resources/currency';
import user from 'store/resources/user';
import guestUser from 'store/resources/guest-user';

import context from 'store/views/context';
import browse from 'store/views/browse';
import search from 'store/views/search';
import share from 'store/views/share';
// import home from 'store/views/home';
import referralInputDialog from 'store/views/referralInputDialog';
import marketingEmailDialog from 'store/views/marketingEmailDialog';

export default {
  // Resources
  currency,
  country,
  user,
  guestUser,
  // Views
  context,
  browse,
  search,
  share,
  // home,
  referralInputDialog,
  marketingEmailDialog,
};
