// @flow
import type {
  ConfigBuyTrxnGSTFeeType,
  PaymentMethodEnumType,
  PaymentMethodType,
} from 'types/resources/paymentMethod';
import type { ProductCollectionType } from 'types/resources/productCollection';
import type { ProductClassType, ProductType } from 'types/resources/product';
import type { DeliveryFeePromotionType } from 'types/resources/deliveryFeePromotion';
import type { ShippingFeePromotionType } from 'types/resources/shippingFeePromotion';
import type { SellingFeePromotionType } from 'types/resources/sellingFeePromotion';
import type { PromocodeType } from 'types/resources/promocode';
import type { PaymentMethodFeeType } from 'types/resources/paymentMethodFee';

import { action, computed, Action, Computed } from 'easy-peasy';

import { cacheGet, cacheSet } from 'utils/localStorageCache';

type CollectionType = {
  [key: ProductClassType]: ProductCollectionType[],
};
const defaultCollection = {
  All: [],
  Sneakers: [],
  Apparel: [],
  Collectibles: [],
  'Trading Cards': [],
};

type CryptoRatesType = { [key: 'BTC' | 'ETH' | 'USDT']: number };
type PayoutCryptoConfigType = {
  balance: number,
  min: number,
  tokens: { name: string, code: string }[],
};
type PromotionLoyaltyMultiplierType = {
  country_id: number,
  payment_method: string,
  multiplier: number,
};
type NSPayBonusConfigType = {
  base_bonus: { min_buy: number, multiplier: number }[],
  campaign_bonus: number,
  campaign_start: string,
  campaign_end: string,
};

type DeliveryProtectionBuyingFeeType = { fee: number };

type TrustpilotConfigType = {
  score: number,
  reviewCount: number,
  url: string,
};

type ContextStoreType = {
  set: Action<
    ContextStoreType,
    {
      deliveryFeePromotions: DeliveryFeePromotionType[],
      shippingFeePromotions: ShippingFeePromotionType[],
      sellingFeePromotions: SellingFeePromotionType[],
      productCollections: { browse: CollectionType, home: CollectionType },
      searchesTrending: string[],
      paymentMethods: PaymentMethodType[],
      promocodes: PromocodeType[],
      cryptoRates: CryptoRatesType,
      payoutCryptoConfig: PayoutCryptoConfigType,
      promotionLoyaltyMultiplier: PromotionLoyaltyMultiplierType[],
      nsPayBuyBonusConfig: NSPayBonusConfigType,
      deliveryProtectionBuyingFee: DeliveryProtectionBuyingFeeType,
      trustpilotConfig: TrustpilotConfigType,
    }
  >,
  deliveryFeePromotions: DeliveryFeePromotionType[],
  shippingFeePromotions: ShippingFeePromotionType[],
  sellingFeePromotions: SellingFeePromotionType[],
  productCollections: { browse: CollectionType, home: CollectionType },
  getCollectionBySlug: Computed<
    ContextStoreType,
    (string: string, _class: 'Sneakers' | 'Apparel' | 'Collectibles' | 'All') => any
  >,
  searchesTrending: string[],
  paymentMethods: PaymentMethodType[],
  getAvailablePaymentMethods: Computed<
    ContextStoreType,
    (buyPrice?: number, product?: ProductType) => PaymentMethodType[],
    any
  >,
  getPaymentMethodBySlug: Computed<
    PaymentMethodType,
    (slug: PaymentMethodEnumType) => PaymentMethodType
  >,
  promocodes: PromocodeType[],
  cryptoRates: CryptoRatesType,
  payoutCryptoConfig: PayoutCryptoConfigType,
  promotionLoyaltyMultiplier: PromotionLoyaltyMultiplierType[],
  nsPayBuyBonusConfig: NSPayBonusConfigType,
  trustpilotConfig: TrustpilotConfigType,
  buyTrxnPaymentFee: PaymentMethodFeeType[],
  buyTrxnGSTFee: ConfigBuyTrxnGSTFeeType[],
  deliveryProtectionBuyingFee: DeliveryProtectionBuyingFeeType,
  selectedCountryId: number,
  setSelectedCountryId: Action<ContextStoreType, number>,
};

// No Data in here is changed after the <App /> is mounted and data is set once
const ContextStore: ContextStoreType = {
  set: action(
    (
      store,
      {
        paymentMethods,
        deliveryFeePromotions,
        shippingFeePromotions,
        sellingFeePromotions,
        searchesTrending,
        productCollections,
        promocodes,
        cryptoRates,
        payoutCryptoConfig,
        promotionLoyaltyMultiplier,
        nsPayBuyBonusConfig,
        buyTrxnPaymentFee,
        buyTrxnGSTFee,
        deliveryProtectionBuyingFee,
        trustpilotConfig,
        currentCountry,
      }
    ) => {
      store.deliveryFeePromotions = cacheSet('last_delivery_fee_promotions', deliveryFeePromotions);
      store.shippingFeePromotions = cacheSet('last_shipping_fee_promotions', shippingFeePromotions);
      store.sellingFeePromotions = cacheSet('last_selling_fee_promotions', sellingFeePromotions);
      store.searchesTrending = cacheSet('last_searchesTrending', searchesTrending);
      store.paymentMethods = cacheSet('last_paymentMethods', paymentMethods);
      store.promocodes = cacheSet('last_promocodes', promocodes);
      store.cryptoRates = cacheSet('last_crypto_rates', cryptoRates);
      store.payoutCryptoConfig = cacheSet('last_payout_crypto_config', payoutCryptoConfig);
      store.promotionLoyaltyMultiplier = cacheSet(
        'last_promotion_loyalty_multiplier',
        promotionLoyaltyMultiplier
      );
      store.nsPayBuyBonusConfig = cacheSet('last_ns_pay_buy_bonus_config', nsPayBuyBonusConfig);
      store.buyTrxnPaymentFee = cacheSet('last_buy_trxn_payment_fee', buyTrxnPaymentFee);
      store.buyTrxnGSTFee = cacheSet('last_buy_trxn_gst_fees', buyTrxnGSTFee);
      store.deliveryProtectionBuyingFee = cacheSet(
        'last_delivery_protection_buying_fee',
        deliveryProtectionBuyingFee
      );
      store.trustpilotConfig = cacheSet('last_trustpilot_config', trustpilotConfig);

      const browseProductCollections = { ...productCollections };
      Object.keys(browseProductCollections).forEach(key => {
        browseProductCollections[key] = browseProductCollections[key].filter(
          pc =>
            (pc.countries?.length ? pc.countries.includes(currentCountry.shortcode) : true) &&
            pc.browse_visible
        );
      });
      const homeProductCollections = { ...productCollections };
      Object.keys(homeProductCollections).forEach(key => {
        homeProductCollections[key] = homeProductCollections[key].filter(
          pc =>
            (pc.countries?.length ? pc.countries.includes(currentCountry.shortcode) : true) &&
            pc.home_visible
        );
      });

      store.productCollections = cacheSet('last_product_collections', {
        browse: browseProductCollections,
        home: homeProductCollections,
      });
    }
  ),

  // Promotions
  deliveryFeePromotions: cacheGet('last_delivery_fee_promotions', []),
  shippingFeePromotions: cacheGet('last_shipping_fee_promotions', []),
  sellingFeePromotions: cacheGet('last_selling_fee_promotions', []),
  getLatestReleasePromotion: computed(s => _class =>
    s.sellingFeePromotions &&
    s.sellingFeePromotions.find(
      sfp =>
        sfp.product_collection &&
        sfp.product_collection.slug === 'latest-release' &&
        sfp.product_collection.category === _class
    )
  ),

  // Collections
  productCollections: cacheGet('last_product_collections', {
    browse: defaultCollection,
    home: defaultCollection,
  }),

  getCollectionsByClass: computed(state => (_class, page) =>
    [
      ...(state.productCollections?.[page]?.[_class] || []),
      ...state.productCollections?.[page]?.All,
    ].sort((a, b) => a.browse_sequence - b.browse_sequence)
  ),
  getCollectionBySlug: computed(state => (slug, _class, page) =>
    state.getCollectionsByClass(_class, page).find(c => c.slug === slug) || {}
  ),

  // payment methods
  paymentMethods: cacheGet('last_paymentMethods', []),
  getPaymentMethodBySlug: computed(state => _slug => {
    const slug = _slug === 'card' ? 'adyen_card' : _slug;
    return state.paymentMethods.find(c => c.slug === slug) || {};
  }),
  getAvailablePaymentMethods: computed(
    [
      (
        { paymentMethods },
        {
          user: { user },
          currency: { current: currentCurrency },
          country: { current: currentCountry },
        }
      ) => ({
        paymentMethods,
        user,
        currentCurrency,
        currentCountry,
      }),
    ],
    ({ paymentMethods, user, currentCurrency, currentCountry }) => (buyPrice, product) => {
      const { isAdmin, country: buyingCountry } = user;
      const { code: currencyCode } = currentCurrency;
      const countryCode = buyingCountry.shortcode || currentCountry.shortcode;
      const applePayAvailable = !!(
        window.ApplePaySession && window.ApplePaySession.canMakePayments()
      );

      return paymentMethods
        .filter(
          p =>
            p.platforms.includes('web') &&
            (p.admin_only ? isAdmin : true) &&
            (p.slug === 'adyen_apple_pay' ? applePayAvailable : true) &&
            (!Object.keys(p.countries).length ||
              (p.countries[countryCode] &&
                p.countries[countryCode].active &&
                p.countries[countryCode].currency === currencyCode &&
                (p.countries[countryCode].admin_only ? isAdmin : true) &&
                !(p.slug === 'paidy' && String(product?.class).toLowerCase() === 'collectibles') &&
                (buyPrice
                  ? (p.countries[countryCode].min
                      ? Number(p.countries[countryCode].min) <= buyPrice
                      : true) &&
                    (p.countries[countryCode].max
                      ? Number(p.countries[countryCode].max) >= buyPrice
                      : true)
                  : true)))
        )
        .map(p => ({
          ...p,
          config:
            countryCode === 'MY' && p.slug === 'grabpay_paylater'
              ? [{ period: 'monthly', interest: 0, installments: 4 }]
              : p.config,
        }));
    }
  ),

  // Trending Searches
  searchesTrending: cacheGet('last_searchesTrending', []),

  // public promocodes
  promocodes: cacheGet('last_promocodes', []),

  // crypto rates
  cryptoRates: cacheGet('last_cryptoRates', {}),

  payoutCryptoConfig: cacheGet('last_payout_crypto_config', { min: 0, balance: 0, tokens: [] }),

  // current promotion loyalty multiplier
  promotionLoyaltyMultiplier: cacheGet('last_promotion_loyalty_multiplier', []),

  nsPayBuyBonusConfig: cacheGet('last_ns_pay_buy_bonus_config', {}),

  buyTrxnPaymentFee: cacheGet('last_buy_trxn_payment_fee', []),

  buyTrxnGSTFee: cacheGet('last_buy_trxn_gst_fee', []),

  deliveryProtectionBuyingFee: cacheGet('last_delivery_protection_buying_fee', {}),

  trustpilotConfig: cacheGet('last_trustpilot_config', {
    url: 'https://www.trustpilot.com/review/novelship.com',
    score: 4.1,
    reviewCount: 3261,
  }),

  selectedCountryId: cacheGet('selected_country_id'),
  setSelectedCountryId: action((store, id) => {
    cacheSet('selected_country_id', id);
    store.selectedCountryId = +id;
  }),
};

export default ContextStore;
