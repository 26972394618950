// @flow
import type { UserType } from 'types/resources/user';
import type { ProductType } from 'types/resources/product';
import type { CurrencyType } from 'types/resources/currency';
import type { TikTokTrackingEvents, TikTokTrackingProperties } from './tiktok.type';

import { getContentProperties, getTrackingProperties } from './utils';

class TikTokAnalyticsManager {
  currency: string;

  setContext({ currency }: { currency: CurrencyType }) {
    this.currency = currency.code;
  }

  identify = (user: UserType) => {
    if (!window.ttq) return;
    window.ttq.identify({
      email: user.email, // tiktok will automatically hash these values with sha-256 https://ads.tiktok.com/marketing_api/docs?rid=5ipocbxyw8v&id=1701890972946433
      ...(user.phone && user.country_code
        ? { phone_number: `${String(user.country_code)}${String(user.phone)}` }
        : {}),
    });
  };

  track = (eventName: TikTokTrackingEvents, properties: TikTokTrackingProperties) => {
    if (!window.ttq) return;
    window.ttq.track(eventName, properties);
  };

  pageView = () => {
    if (!window.ttq) return;
    window.ttq.page();
  };

  trackEvent = (eventName: TikTokTrackingEvents, value: number | null, product: ProductType) =>
    value && this.track(eventName, getTrackingProperties(product, this.currency, value));

  search = (query: string) => this.track('Search', { query });

  addToWishlist = (product: ProductType) =>
    this.track('AddToWishlist', {
      ...getContentProperties(product),
      currency: this.currency,
      quantity: 1,
    });

  signUp = () => this.track('CompleteRegistration', {});
}

const TikTokAnalytics = new TikTokAnalyticsManager();

export default TikTokAnalytics;
