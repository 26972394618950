// @flow
import type { UserType } from 'types/resources/user';
import type { ProductType } from 'types/resources/product';
import type { CurrencyType } from 'types/resources/currency';
import type { TransactionType } from 'types/resources/transaction';
import crypto from 'crypto';
import { lowerArray } from 'utils/index';

class ImpactAnalyticsManager {
  currency: string;

  setContext({ currency }: { currency: CurrencyType }) {
    this.currency = currency.code;
  }

  identify = (user?: UserType) => {
    const hashedEmail =
      user && user.email
        ? crypto
            .createHash('sha256')
            .update(encodeURI(user.email))
            .digest('hex')
        : '';

    const impactIdentify = () =>
      user?.id
        ? window.ire('identify', {
            customerId: user?.id || '',
            customerEmail: hashedEmail,
          })
        : window.ire('identify');
    if (window.ire) {
      return impactIdentify();
    }
    // $FlowFixMe
    document.querySelector('#ire').addEventListener('load', () => {
      if (window.ire) {
        return impactIdentify();
      }
    });
  };

  purchaseConfirm = ({
    product,
    buy,
    user,
  }: {
    product: ProductType,
    buy: TransactionType & any,
    user?: UserType,
  }) => {
    if (!window.ire) return;

    const isUserFirstPurchase = !(user && lowerArray(user.groups).includes('has-purchase'));
    const hashedEmail =
      user && user.email
        ? crypto
            .createHash('sha256')
            .update(encodeURI(user.email))
            .digest('hex')
        : '';
    window.ire(
      'trackConversion',
      process.env.REACT_APP_IMPACT_TRACK_CONVERSION_ID || '',
      {
        ...(user && user.email ? { customerEmail: hashedEmail } : {}),
        orderId: buy.ref,
        customerId: buy.buyer_id,
        customerStatus: isUserFirstPurchase ? 'New' : 'Returning',
        currencyCode: this.currency,
        orderPromoCode: buy.promocode_id ? String(buy.promocode_id) : '',
        // orderDiscount: 5.00,
        items: [
          {
            subTotal: buy.total_price_local,
            category: product.class,
            sku: product.sku || product.id,
            quantity: 1,
            name: product.name,
          },
        ],
      },
      {
        verifySiteDefinitionMatch: true,
      }
    );
  };
}
const ImpactAnalytics = new ImpactAnalyticsManager();

export default ImpactAnalytics;
