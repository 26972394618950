// @flow
export default function asyncScriptLoad(
  id: string,
  src: string,
  objInWindow?: string,
  onLoad?: () => void
) {
  return new Promise<void>(resolve => {
    if (document.getElementById(id) && (!objInWindow || (objInWindow && window[objInWindow]))) {
      return resolve();
    }

    const fjs = document.getElementsByTagName('script')[0];
    const js = document.createElement('script');
    js.type = 'text/javascript';
    js.async = true;
    js.src = src;
    js.id = id;

    const func = function() {
      if (!this.readyState || this.readyState === 'complete') {
        if (onLoad) onLoad();
        return resolve();
      }
    };

    js.onload = func;
    js.onreadystatechange = func;

    fjs.parentNode && fjs.parentNode.insertBefore(js, fjs);
  });
}
