// UNUSED
// @flow
import type { TransactionType } from 'types/resources/transaction';
import type { OfferListType } from 'types/resources/offerList';
import type { ProductType } from 'types/resources/product';
import type { UserType, SignupMethodType } from 'types/resources/user';

import { mapProductForTracking } from './product';

const modes = {
  buy: 'Purchase',
  offer: 'Offer',
  list: 'List',
  sell: 'Sale',
};
const mapOperationMode = (mode: 'buy' | 'offer' | 'list' | 'sell' | any) => modes[mode] || mode;

const getPrice = (checkout: TransactionType & OfferListType) =>
  Math.round((checkout.base_price || checkout.price || 0) * 100) / 100;

/* - - - - - - */
/* GTM */
/* - - - - - */
// Ref: https://developers.google.com/tag-manager/enhanced-ecommerce#purchases

const GATrackProductConfirm = (
  sale: TransactionType & OfferListType,
  product: ProductType,
  operation: 'Offer' | 'Purchase' | 'List' | 'Sale' | 'Consignment'
) => {
  if (!/Purchase|Sale/.test(operation)) return;

  window.dataLayer.push({
    ecommerce: {
      purchase: {
        actionField: {
          id: sale.id,
          revenue: getPrice(sale),
          shipping: operation === 'Offer' ? sale.fees.delivery : sale.fee_delivery,
          operation: mapOperationMode(operation),
        },
        products: [
          {
            ...mapProductForTracking(product),
            price: getPrice(sale),
          },
        ],
      },
    },
  });
};

const GATrackPageView = () => {
  const {
    location: { pathname, search, hash },
    dataLayer,
  } = window;

  dataLayer.push({
    event: 'Pageview',
    pagePath: pathname + search + hash,
    pageTitle: document.title,
  });
};

const GATrackSignup = (user: UserType, method: SignupMethodType) => {
  window.dataLayer.push({
    event: 'registrationComplete',
    'user-id': user.id,
    method,
  });
};

const GATrackConsent = (response: 'granted' | 'denied') => {
  window.gtag('consent', 'update', {
    ad_storage: response,
    ad_user_data: response,
    ad_personalization: response,
    analytics_storage: response,
    functionality_storage: response,
    personalization_storage: response,
    security_storage: response,
    regions: window.EU_REGIONS,
  });
};

export { GATrackProductConfirm, GATrackPageView, GATrackSignup, GATrackConsent };
