// @flow
import styled from 'styled-components';
import { Theme } from 'styles';

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  th {
    border-bottom: 1px solid ${Theme.colors.gray7};
    color: ${Theme.colors.black2};
    font-weight: normal;
    background: ${Theme.colors.gray8};
    font-size: 16px;
  }
  td {
    color: ${Theme.colors.gray1};
    font-size: 14px;

    img {
      object-fit: contain;
      height: 32px;
      width: 32px;
    }
  }
  th,
  td {
    border-right: 1px solid ${Theme.colors.gray7};
    padding: 0 12px;
    height: 40px;
    &:last-child {
      border-right: none;
    }
  }
`;

export default Table;
