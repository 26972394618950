// @flow
import React, { useLayoutEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { IoMdSearch, IoMdClose } from 'react-icons/io';
import { navigate } from '@reach/router';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { Flex, Button } from 'components/base';
import mediaQuery from 'styles/mediaQuery';
import theme from 'styles/theme';
import { isMobile } from 'utils';

const SearchBar = ({ toggleSearchSidebar }: { toggleSearchSidebar: () => void }) => {
  const search = useStoreState(s => s.search.search);
  const { setSearch, setIsSearching } = useStoreActions(s => s.search);

  const onChange = (v: string) => {
    setIsSearching(true);
    setSearch(v);
  };

  const onReturnPress = ev => {
    if (ev.charCode === 13) {
      toggleSearchSidebar();
      return navigate(`/browse?q=${search}`);
    }
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      if (isMobile && window.scrollY !== 0) {
        window.scrollTo(0, 0);
      }
    }, 100);
  }, []);

  return (
    <SearchForm>
      <IoMdSearch className="icon search" size={28} color={theme.colors.gray3} />
      <input
        value={search}
        placeholder={i18n._(t`Search...`)}
        onChange={ev => onChange(ev.target.value)}
        onKeyPress={ev => onReturnPress(ev)}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      {!!search && (
        <Button className="icon close" variant="icon" onClick={() => setSearch('')}>
          <IoMdClose size={28} color={theme.colors.gray3} />
        </Button>
      )}
    </SearchForm>
  );
};

const SearchForm = styled(Flex)`
  position: relative;
  width: 85%;
  .icon {
    position: absolute;
    top: 10px;
    &.search {
      left: 10px;
    }
    &.close {
      top: 5px;
      right: 0px;
    }
  }
  input {
    border: 1px solid ${theme.colors.gray6};
    padding-right: 12px;
    padding-left: 44px;
    border-radius: 5px;
    font-size: 14px;
    height: 50px;
    width: 100%;
    &::placeholder {
      color: theme.colors.gray3;
    }
  }
  ${mediaQuery.md`
    .icon {
      height: 20px;
      top: 8px;
      &.search {
        left: 6px;
      }
      &.close {
        right: -8px;
        top: 8px;
        svg {
          height: 20px;
        }
      }
    }
    input {
      padding-left: 36px;
      height: 36px;
    }
  `}
`;

export default SearchBar;
