// @flow
import { useState } from 'react';
import { i18n } from '@lingui/core';
import clipboardCopy from 'clipboard-copy';

const useCopy = (copyString: string, _preCopyButtonText?: string) => {
  const preCopyButtonText = _preCopyButtonText || 'Copy';
  const [copyButtonText, setCopyButtonText] = useState(preCopyButtonText);

  const copy = (_copyString?: string) => {
    const copyText = typeof _copyString === 'string' ? _copyString : copyString;
    clipboardCopy(copyText);
    setCopyButtonText('COPIED!');

    setTimeout(() => {
      setCopyButtonText(i18n._(preCopyButtonText || ''));
    }, 3e3);
  };

  return { copy, copyButtonText };
};

export default useCopy;
