// @flow
import * as React from 'react';
import { Location } from '@reach/router';
import Analytics from 'services/analytics';
import { cacheGet, cacheSet } from 'utils/localStorageCache';

let lastNavigationFromBrowserUI = true;

if (typeof window !== 'undefined') {
  window.addEventListener('popstate', () => {
    lastNavigationFromBrowserUI = true;
  });
}

function cacheNavigationHistory(href) {
  const key = 'go_back_history';
  let urls = cacheGet(key, []);
  const lastElem = urls[0];
  if (!lastElem) urls.push(href);
  else if (window.history.length <= 2) urls = [href];
  else if (lastElem && lastElem.split('?')[0] !== href.split('?')[0]) urls = [href, lastElem];
  cacheSet(key, urls);
}

const LocationManager = () => (
  <Location>
    {({ location: { hash, href } }) => {
      cacheNavigationHistory(href);

      /* AUTO SCROLL TO TOP MANAGER */
      if (typeof window.history !== 'undefined') {
        // Initial rendering and back/forward navigation uses browsers
        // native scroll history mechanism which tracks scroll position
        // for each history entry automatically
        requestAnimationFrame(() => {
          if (lastNavigationFromBrowserUI || hash) {
            if (hash) {
              const el = document.getElementById(hash.replace('#', ''));
              if (el) {
                el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
              }
            }
            lastNavigationFromBrowserUI = false;
          }
          // When adding new entries by navigating through clicking on actual
          // links in the page, we always scroll up to work around
          // the scrolling applied by automatic focussing as done
          // by reach routers accessibility tweaks.
          else window.scrollTo(0, 0);
        });
      }

      /* GOOGLE TAGS/ANALYTICS Manager */
      Analytics.pageView();

      return null;
    }}
  </Location>
);

export default LocationManager;
