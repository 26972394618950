// @flow
import * as React from 'react';
import type { ErrorBoundaryProps } from './ErrorBoundary';
import ErrorBoundary from './ErrorBoundary';

export type ErrorBoundaryContextValue = {
  resetErrorBoundary: () => void,
};

const ErrorBoundaryContext = React.createContext<ErrorBoundaryContextValue>({
  resetErrorBoundary: () => {},
});

export const useErrorBoundaryContext = () => React.useContext(ErrorBoundaryContext);

const ErrorBoundaryProvider = ({
  children,
  fallback,
  onReset,
}: ErrorBoundaryProps & { onReset?: () => void }) => {
  const [errorKey, setErrorKey] = React.useState(0);

  const resetErrorBoundary = () => {
    setErrorKey(prevKey => prevKey + 1);
    onReset && onReset();
  };

  return (
    <ErrorBoundaryContext.Provider value={{ resetErrorBoundary }}>
      <ErrorBoundary key={errorKey} onReset={resetErrorBoundary} fallback={fallback}>
        {children}
      </ErrorBoundary>
    </ErrorBoundaryContext.Provider>
  );
};
export default ErrorBoundaryProvider;
