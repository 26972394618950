// @flow
import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Box, Text, Flex, Image } from 'components/base';
import { getImgixUrl } from 'constants/constants';
import { Trans } from '@lingui/macro';
import { isMobile } from 'utils/index';

const LOGIN_MOBILE_STAR_SIZE = 12;
const STAR_SIZE = 20;
const TrustPilotStars = ({
  centered = false,
  starSize,
}: {
  centered?: boolean,
  starSize: number,
}) => {
  const { trustpilotConfig } = useStoreState(s => s.context);
  const trustPilotScore = parseFloat(trustpilotConfig.score);
  const completeGreenStars = Math.floor(trustPilotScore);
  const decimalPoints = parseFloat((trustPilotScore - completeGreenStars).toFixed(2));

  return (
    <Flex center={centered}>
      {[...Array(completeGreenStars).keys()].map(i => (
        <Flex
          key={i}
          center
          mx="2px"
          p={LOGIN_MOBILE_STAR_SIZE === starSize ? '1px' : 1}
          width={starSize}
          height={starSize}
          backgroundColor="#219653"
        >
          <Image src={getImgixUrl('icons/trust-white-star.svg')} />
        </Flex>
      ))}
      {Math.trunc(trustPilotScore) !== 5 && (
        <Flex
          mx="2px"
          width={starSize}
          position="relative"
          height={starSize}
          backgroundColor="#D9D9D9"
        >
          <Image
            src={getImgixUrl('icons/trust-white-star.svg')}
            position="absolute"
            p={LOGIN_MOBILE_STAR_SIZE === starSize ? '1px' : 1}
          />
          <Flex
            width={decimalPoints * starSize}
            height={starSize}
            style={{ position: 'absolute', backgroundColor: '#219653' }}
          />
        </Flex>
      )}
      {completeGreenStars + 1 < 5 &&
        [...Array(5 - (completeGreenStars + 1)).keys()].map(i => (
          <Flex
            key={i}
            center
            mx="2px"
            p={LOGIN_MOBILE_STAR_SIZE === starSize ? 0.8 : 1}
            width={starSize}
            height={starSize}
            backgroundColor="#D9D9D9"
          >
            <Image src={getImgixUrl('icons/trust-white-star.svg')} />
          </Flex>
        ))}
    </Flex>
  );
};

const TrustPilotProductPage = ({ ...props }: {}) => {
  const { trustpilotConfig } = useStoreState(s => s.context);
  return (
    <a href={trustpilotConfig.url}>
      <Box width={220} center {...props}>
        <Flex center mb={1}>
          <Flex center width={24} height={24} mr={1}>
            <Image src={getImgixUrl('icons/trust-green-star.svg')} style={{ marginTop: -2 }} />
          </Flex>
          <Text center fontWeight={700} fontSize={3} lineHeight="26px">
            Trustpilot
          </Text>
        </Flex>

        <TrustPilotStars centered starSize={STAR_SIZE} />

        <Flex center>
          <Text fontSize={1} lineHeight="22px">
            <Trans>
              TrustScore <b> {trustpilotConfig.score} </b>Based on{' '}
              {trustpilotConfig.reviewCount && (
                <b>
                  {trustpilotConfig.reviewCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                  reviews
                </b>
              )}
            </Trans>
          </Text>
        </Flex>
      </Box>
    </a>
  );
};

const TrustPilotLogIn = ({ ...props }: {}) => {
  const { trustpilotConfig } = useStoreState(s => s.context);
  const trustPilotScore = parseFloat(trustpilotConfig.score);

  return (
    <Flex width={1} center {...props}>
      <a
        href={trustpilotConfig.url}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Text mr={2} fontSize={1} fontWeight="bold" lineHeight="22px">
          {trustPilotScore > 4 ? <Trans>Excellent</Trans> : <Trans>Great</Trans>}
        </Text>

        <TrustPilotStars centered starSize={isMobile ? LOGIN_MOBILE_STAR_SIZE : STAR_SIZE} />

        <Flex center>
          <Text mx={[2, 3]} fontSize={1} fontWeight="bold" lineHeight="22px" className="nowrap">
            <Trans>{trustPilotScore} out of 5</Trans>
          </Text>
          <Flex center>
            <Flex center width={[15, 15, 24, 24, 24]} height={[15, 15, 24, 24, 24]} mr={1}>
              <Image src={getImgixUrl('icons/trust-green-star.svg')} style={{ marginTop: -2 }} />
            </Flex>
            <Text center fontWeight={700} fontSize={[1, 1, 3, 3, 3]} lineHeight="26px">
              Trustpilot
            </Text>
          </Flex>
        </Flex>
      </a>
    </Flex>
  );
};

const TrustPilotFooterWidget = ({ ...props }: {}) => {
  const { trustpilotConfig } = useStoreState(s => s.context);
  return (
    <a href={trustpilotConfig.url}>
      <Box width={1} {...props}>
        <Flex alignItems="center" mb={1}>
          <Flex center width={35} height={35} mr={1}>
            <Image src={getImgixUrl('icons/trust-green-star.svg')} style={{ marginTop: -2 }} />
          </Flex>
          <Text
            center
            color="white"
            fontWeight={700}
            letterSpacing={0.56}
            fontSize={5}
            lineHeight="26px"
          >
            Trustpilot
          </Text>
        </Flex>

        <TrustPilotStars starSize={30} />

        <Box mt={1}>
          <Text color="white" fontSize={1} letterSpacing={0.36}>
            <Trans>
              TrustScore <strong id="trust-score">{trustpilotConfig.score}</strong>
            </Trans>
          </Text>
          {trustpilotConfig.reviewCount && (
            <Text color="white" fontSize={1} letterSpacing={0.36}>
              <Trans>
                <strong id="businessEntity-numberOfReviews-total">
                  {trustpilotConfig.reviewCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </strong>{' '}
                reviews
              </Trans>
            </Text>
          )}
        </Box>
      </Box>
    </a>
  );
};

export { TrustPilotLogIn, TrustPilotProductPage, TrustPilotFooterWidget };
