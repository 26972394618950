// @flow
import { format as fechaFormat } from 'fecha';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { MIL_SECS_IN_DAY } from 'constants/constants';

const toDate = (
  dateValue: string | number | Date | typeof undefined,
  type?:
    | 'product'
    | 'weekdays'
    | 'DD/MM/YYYY'
    | 'YYYY-MM-DD'
    | 'YY-MM-DD HH:mm:ss'
    | 'DD/MM/YYYY - HH:mm'
    | 'MMM DD'
    | 'DD MMM YYYY'
    | 'D MMM'
    | 'DD MMM'
    | 'Do'
): string => {
  try {
    if (!dateValue) {
      return '';
    }
    const date = new Date(dateValue);

    let format = 'DD MMM’YY'; // default type 'date'
    if (type === 'product') {
      format = date.getFullYear() !== new Date().getFullYear() ? 'D MMM’YY' : 'D MMM';
    } else if (type === 'weekdays') {
      format = 'ddd, D MMM YYYY';
    } else if (type) {
      format = type;
    }
    return fechaFormat(date, format);
  } catch (error) {
    return '';
  }
};

const toLocalizedDate = (
  dateValue: string | Date,
  format: 'short' | 'long' | 'monthYear' | Intl$DateTimeFormatOptions = 'long'
): string => {
  try {
    if (!dateValue) {
      return '';
    }

    const dateFormats: { [key: string]: Intl$DateTimeFormatOptions } = {
      short: { day: '2-digit', month: 'short' },
      long: {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      },
      monthYear: { month: 'short', year: '2-digit' },
    };

    const dateFormat = typeof format === 'string' ? dateFormats[format] : format;

    return i18n.date(new Date(dateValue), dateFormat);
  } catch (error) {
    return '';
  }
};

const toTime = (timeValue: string, format: string = 'h:mm A'): string =>
  fechaFormat(new Date(timeValue), format);

const getSGTTime = (date?: any) => {
  const local = date || new Date();
  const UTC = local.getTime() + local.getTimezoneOffset() * 60000;
  return new Date(UTC + 3600000 * 8);
};

const expireIn = (
  expired_at: string | Date,
  unit: 'hour' | 'day' = 'day',
  compareSGT: boolean = false
): string => {
  const expirationDateTime = new Date(expired_at);
  const today = compareSGT ? getSGTTime() : new Date();

  if (expirationDateTime < today) {
    return i18n._(t`Expired`);
  }

  const timeDiff = Math.abs(expirationDateTime.getTime() - today.getTime());
  const diffInUnits = Math.ceil(timeDiff / (1000 * 3600 * (unit === 'day' ? 24 : 1)));
  return diffInUnits + (diffInUnits > 1 ? ` ${i18n._(`${unit}s`)}` : ` ${i18n._(unit)}`);
};

const getNextWeekDays = (days: number, startDate?: string) => {
  const today = startDate ? new Date(startDate) : new Date();
  const weekDays = [];

  let i = 1;
  while (weekDays.length !== days) {
    const d = new Date();
    d.setDate(today.getDate() + i);
    const day = d.getDay();
    if (day !== 0 && day !== 6) {
      weekDays.push(d);
    }
    i += 1;
  }

  return weekDays;
};

const isCurrentDateInRange = (_startTime: string | Date, _endTime: string | Date): boolean => {
  const startTime = new Date(_startTime);
  const endTime = new Date(_endTime);
  const currentTime = getSGTTime();
  return startTime <= currentTime && currentTime <= endTime;
};

const isCurrentDateInRangeSG = (_startTime: string | Date, _endTime: string | Date): boolean => {
  const startTime = getSGTTime(new Date(_startTime));
  const endTime = getSGTTime(new Date(_endTime));
  const currentTime = getSGTTime();
  return startTime <= currentTime && currentTime <= endTime;
};

const daysBetweenDates = (startDate: string | Date, endDate?: string | Date): number => {
  const from = new Date(startDate).getTime();
  const to = (endDate ? new Date(endDate) : new Date()).getTime();
  return Math.ceil(Math.abs(from - to) / MIL_SECS_IN_DAY);
};

type RelativeTimeFormatUnitSingular =
  | 'year'
  | 'quarter'
  | 'month'
  | 'week'
  | 'day'
  | 'hour'
  | 'minute'
  | 'second';
const divider = [1, 60, 60, 24, 31, 12];
const units: RelativeTimeFormatUnitSingular[] = [
  'second',
  'minute',
  'hour',
  'day',
  'month',
  'year',
];

const getTimeAndUnit = (
  seconds: number
): { time: number, unit: RelativeTimeFormatUnitSingular } => {
  let time = seconds;
  let unit = units[0];

  let i = 0;
  while (Math.round(time / divider[i])) {
    const _t = Math.round(time / divider[i]);
    if (unit[i] === 'day' && _t >= 7 && _t < 31) {
      return { time: Math.round(time / (31 / 7)), unit: 'week' };
    }
    time = _t;
    unit = units[i];
    i += 1;
  }

  return { time, unit };
};

const toLocalizedRelativeTime = (dateValue: string | Date, locale: string = 'en'): string => {
  try {
    if (!dateValue) {
      return '';
    }

    const rtf = new window.Intl.RelativeTimeFormat(locale, {
      localeMatcher: 'best fit',
      numeric: 'auto',
      style: 'long',
    });

    const seconds = Math.floor((Number(new Date()) - Number(new Date(dateValue))) / 1000);
    const { time, unit } = getTimeAndUnit(seconds);

    return rtf.format(-time, unit);
  } catch (error) {
    return '';
  }
};

export {
  toTime,
  toDate,
  toLocalizedDate,
  expireIn,
  getSGTTime,
  getNextWeekDays,
  isCurrentDateInRangeSG,
  isCurrentDateInRange,
  daysBetweenDates,
  toLocalizedRelativeTime,
};
