// @flow
import * as React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { StoreProvider } from 'easy-peasy';
import LocationManager from 'components/misc/LocationManager';
import { GlobalStyles, Theme } from './styles';
import Store from './store';
import BaseApp from './App';

import './services/sentry';
import 'delayed-scroll-restoration-polyfill';

import * as serviceWorker from './serviceWorker';
import ErrorBoundaryProvider from './ErrorBoundaryContext';

// Disable React error overlay during development
// not recommended to set true NO_DEV_ERRORS
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_NO_DEV_ERRORS === 'true') {
  import('react-error-overlay').then(module => {
    module.stopReportingRuntimeErrors();
  });
}

const App = () => (
  <ErrorBoundaryProvider>
    <LocationManager />
    <GlobalStyles />
    <ThemeProvider theme={Theme}>
      <StoreProvider store={Store}>
        <BaseApp />
      </StoreProvider>
    </ThemeProvider>
  </ErrorBoundaryProvider>
);

const root = document.getElementById('root');
if (root !== null) ReactDOM.render(<App />, root);

// register service worker for PWA
serviceWorker.register();
