// @flow
import searchInsights from 'search-insights';

import env from 'constants/environment';

import { AlgoliaIndices } from './algolia';

searchInsights('init', {
  appId: env.algolia.appId,
  apiKey: env.algolia.apiKey,
});

type AlgoliaInsightsParamsType = {
  userId?: number,
  name_slug: string,
  position: number,
  objectID: string,
  queryID: string,
  sort: $Keys<typeof AlgoliaIndices>,
};

const AlgoliaInsights = {
  productClickEvents: new Map<string, any>(),

  productClicked: ({
    userId,
    name_slug,
    position,
    objectID,
    queryID,
    sort = 'search',
  }: AlgoliaInsightsParamsType) => {
    const userID = userId || 'Guest-User';
    const params = {
      userToken: String(userID),
      positions: [position + 1],
      eventName: 'Product Clicked',
      index: AlgoliaIndices[sort] || AlgoliaIndices.search,
      objectIDs: [objectID],
      queryID,
    };

    AlgoliaInsights.productClickEvents.set(name_slug, params);
    searchInsights('clickedObjectIDsAfterSearch', params);
  },

  productConversion: ({
    name_slug,
    mode,
  }: {
    name_slug: string,
    mode: 'Purchase' | 'Sale' | 'Offer' | 'List' | 'Consignment',
  }) => {
    const { positions, ..._params } = AlgoliaInsights.productClickEvents.get(name_slug) || {};
    if (_params.queryID) {
      const eventName = /(Purchase|Sale)/.test(mode)
        ? `Product ${mode === 'Purchase' ? 'Purchased' : 'Sold'}`
        : `${mode} Created`;

      const params = {
        ..._params,
        eventName,
      };

      searchInsights('convertedObjectIDsAfterSearch', params);
    }
  },

  productWishListed: (name_slug: string) => {
    const { positions, ..._params } = AlgoliaInsights.productClickEvents.get(name_slug) || {};
    if (_params.queryID) {
      const params = {
        ..._params,
        eventName: 'Product Wishlisted',
      };

      searchInsights('convertedObjectIDsAfterSearch', params);
    }
  },
};

export default AlgoliaInsights;
