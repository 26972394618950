// @flow
import type { APIListResponse } from 'types';
import type { SortType } from 'types/views/browse';

import { action, thunk, Action, Thunk, Actions } from 'easy-peasy';

import { cacheGet, cacheSet } from 'utils/localStorageCache';
import { ProductType } from 'types/resources/product';
import { debounce } from 'utils';
import { getUserVisitingStatus } from 'utils/user';
import AlgoliaClient, { AlgoliaConstants } from 'services/algolia';
import Analytics from 'services/analytics';
import { TOP_LEVEL_CATEGORIES_CLASS_MAP } from 'store/constants/browseData';

export type SearchResponseType = APIListResponse<ProductType> & {
  searchProductsFound?: boolean,
  totalByClasses?: {
    All?: number,
    Sneakers?: number,
    Apparel?: number,
    Collectibles?: number,
  },
};
export type AlgoliaParamsType = {
  filterString?: string,
  filter?: any,
  sort?: SortType,
  page?: number,
};
type SearchThunkPayload = { search?: string } & AlgoliaParamsType;

export type SearchStoreType = {
  search: string,
  isSearching: boolean,
  isLoadingMore: boolean,
  searchProductsFound: boolean,
  searchProducts: SearchResponseType,

  setSearch: Action<SearchStoreType, string>,
  setIsSearching: Action<SearchStoreType, boolean>,
  setIsLoadingMore: Action<SearchStoreType, boolean>,
  setSearchProducts: Action<SearchStoreType, SearchResponseType>,
  appendSearchProducts: Action<SearchStoreType, SearchResponseType>,

  fetchSearchProducts: Thunk<SearchStoreType, SearchThunkPayload>,
  debouncedFetchSearchProducts: Thunk<SearchStoreType, SearchThunkPayload>,
  loadMore: Thunk<SearchStoreType, SearchThunkPayload>,
};

const storeRecentSearches = (search: string) => {
  const recentSearches = cacheGet('recent_searches', []);
  const searchesTrending = cacheGet('last_searchesTrending', []);
  if (recentSearches !== undefined && !searchesTrending.includes(search)) {
    const index = recentSearches.findIndex(r => r === search);
    if (index >= 0) recentSearches.splice(index, 1);
    if (recentSearches.length >= 5) recentSearches.pop();
    recentSearches.unshift(search);
    cacheSet('recent_searches', recentSearches);
  }
};

const getMostPopularSort = (sort?: SortType, { country }): SortType | void => {
  const currentCountryCode = country.current.shortcode;
  if (sort === 'mostPopular') {
    return Object.keys(AlgoliaClient).includes(`mostPopular${currentCountryCode}`) // $FlowFixMe
      ? `mostPopular${currentCountryCode}`
      : 'mostPopular';
  }
  return sort;
};

const getUpcomingSortFilter = (sort?: SortType): string => {
  if (sort && sort.startsWith('upcomingRelease')) {
    const countryShortCode = sort.replace('upcomingRelease', '');
    const suffix = countryShortCode !== 'US' ? `_${countryShortCode}` : '';
    const d = new Date();
    const threeDaysAgo = Math.floor(d.setDate(d.getDate() - 3) / 1000);
    return `drop_date_timestamp${suffix} > ${threeDaysAgo}`;
  }
  return '';
};

const getLatestReleaseSortFilter = (sort?: SortType): string => {
  if (sort && sort.startsWith('latestRelease')) {
    const d = new Date();
    const fourteenDaysAfter = Math.floor(d.setDate(d.getDate() + 14) / 1000);
    return `drop_date_timestamp < ${fourteenDaysAfter}`;
  }
  return '';
};

const getAnalyticsTags = ({ country }, searchbar) => {
  const countryCode = country.current.shortcode;
  const userVisitingStatus = getUserVisitingStatus();
  const tags = ['web', userVisitingStatus, countryCode];
  if (searchbar) tags.push('searchbar');

  return tags;
};

export const fetchProducts = (
  search?: string,
  _params?: AlgoliaParamsType,
  storeState: any,
  searchbar?: boolean
) => {
  const userId = storeState.user.user?.id || 'Guest-User';
  const { page, sort, filterString } = _params || {};
  const analyticsTags = getAnalyticsTags(storeState, searchbar);

  const params = {
    page,
    facets: ['class', 'category_level_1'],
    facetingAfterDistinct: true,
    filters: [filterString, getUpcomingSortFilter(sort), getLatestReleaseSortFilter(sort)]
      .filter(Boolean)
      .join(' AND '),
    userToken: String(userId),
    analyticsTags,
    ...AlgoliaConstants,
  };
  const _sort = getMostPopularSort(sort, storeState);
  const Algolia = _sort && AlgoliaClient[_sort] ? AlgoliaClient[_sort] : AlgoliaClient.search;
  return Algolia<ProductType>(search || '', params)
    .then(({ hits, nbHits, facets, queryID }) => {
      const _facets = facets ? facets.class : {};

      Object.keys(TOP_LEVEL_CATEGORIES_CLASS_MAP)
        .filter(key => facets.category_level_1?.[key])
        .forEach(key => {
          _facets[key] = facets.category_level_1[key];
        });

      return {
        results: hits.map(p => ({ ...p, queryID })),
        total: nbHits,
        totalByClasses: { ..._facets, All: nbHits } || {},
      };
    })
    .then(resp => {
      if (resp.total === 0) {
        return AlgoliaClient.mostPopular<ProductType>('', {
          page,
          analyticsTags,
          ...AlgoliaConstants,
        }).then(({ hits, nbHits, queryID }) => ({
          results: hits.map(p => ({ ...p, queryID })),
          total: nbHits,
        }));
      }
      if (search) storeRecentSearches(search);
      return { ...resp, searchProductsFound: true };
    })
    .catch(() => ({ results: [], total: 0, searchProductsFound: false }));
};

const fetchSearchProducts = (
  actions: Actions<SearchStoreType>,
  search?: string,
  params?: AlgoliaParamsType,
  storeState?: any
) => {
  actions.setIsSearching(true);
  fetchProducts(search, params, storeState, true).then(actions.setSearchProducts);
};

const debouncedFetchSearchProducts = debounce(fetchSearchProducts, 700);

const SearchStore: SearchStoreType = {
  search: '',
  searchProductsFound: false,
  setSearch: action((store, search = '') => {
    store.search = search;
  }),

  searchProducts: {
    results: [],
    total: 0,
    totalByClasses: { Sneakers: 0, Apparel: 0, Collectibles: 0 },
  },
  setSearchProducts: action((store, searchProducts) => {
    store.isSearching = false;
    store.searchProducts = searchProducts;
    store.searchProductsFound = !!searchProducts.searchProductsFound;
    Analytics.productSearch('Search', store.search, searchProducts.results.length);
  }),
  appendSearchProducts: action((store, searchProducts) => {
    store.isLoadingMore = false;
    store.searchProducts.results.push(...searchProducts.results);
  }),

  isSearching: false,
  setIsSearching: action((store, isSearching) => {
    store.isSearching = isSearching;
  }),

  isLoadingMore: false,
  setIsLoadingMore: action((store, isLoadingMore) => {
    store.isLoadingMore = isLoadingMore;
  }),

  fetchSearchProducts: thunk((actions, { search, ...params }, { getStoreState }) =>
    fetchSearchProducts(actions, search, params, getStoreState())
  ),
  debouncedFetchSearchProducts: thunk((actions, { search, ...params }, { getStoreState }) =>
    debouncedFetchSearchProducts(actions, search, params, getStoreState())
  ),
  loadMore: thunk((actions, { search, ...params }, { getStoreState }) =>
    fetchProducts(search, params, getStoreState()).then(actions.appendSearchProducts)
  ),
};

export default SearchStore;
