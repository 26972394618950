// @flow
/* eslint-disable jsx-a11y/label-has-for */
import * as React from 'react';
import styled from 'styled-components';
import { Theme } from 'styles';
import { i18n } from '@lingui/core';

import fieldToTitle from 'utils/string/fieldToTitle';
import { Text } from 'components/base';
import ErrorMessage from './ErrorMessage';
import { Input, Textarea } from './Input';
import PasswordInput from './PasswordInput';

type Props = {
  name: string,
  value: any,
  type?: string,
  error?: string,
  label?: string,
  placeholder?: string,
  className?: string,
  maxLength?: string,
};

// Automatically sets field name to placeholder using fieldToTitle if no placeholder provided.
// to show different placeholder, pass prop placeholder="custom placeholder"
// to show no placeholder, pass prop placeholder=""

/**
 * This component handles both View and Function logic of a Input + label.
 * NOTE: IF DIFFERENT VIEW IS REQUIRED, CREATE A NEW COMPONENT.
 * NOTE: DO NOT MODIFY THIS TO SUPPORT MORE CASES.
 */
const FormField = ({
  name,
  label,
  error,
  placeholder,
  className = '',
  value,
  maxLength,
  type = 'text',
  ...props
}: Props) => {
  const inputProps = {
    placeholder: i18n._(placeholder || fieldToTitle(name)),
    className: `${className} ${error ? 'error' : ''}`,
    value,
    maxLength,
    ...props,
    id: name,
    name,
    type,
  };

  return (
    <StyledLabel htmlFor={name}>
      {type === 'password' ? (
        <PasswordInput {...inputProps} />
      ) : type !== 'textarea' ? (
        <Input {...inputProps} />
      ) : (
        <>
          <Textarea {...inputProps} />
          {maxLength && (
            <Text
              style={{ position: 'absolute', bottom: 6, right: 8 }}
              fontSize={1}
              color={maxLength <= value?.length ? 'red' : 'gray3'}
            >
              {value?.length}/{maxLength}
            </Text>
          )}
        </>
      )}

      <span>{i18n._(label || placeholder || fieldToTitle(name))}</span>
      {typeof error === 'string' && <ErrorMessage mt="2px">{i18n._(error)}</ErrorMessage>}
    </StyledLabel>
  );
};

const StyledLabel = styled.label`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  span {
    position: absolute;
    left: 4px;
    top: 3px;
    opacity: 0;
    transform: scale(1.2);
    transition: all 0.1s linear;
    font-size: 10px;
    color: ${Theme.colors.gray2};
    transform-origin: top left;
    letter-spacing: 0.2px;
  }
  input:not(:placeholder-shown) {
    padding-top: 8px;
  }
  textarea:not(:placeholder-shown) {
    padding-top: 16px;
    padding-bottom: 24px;
    resize: none;
  }
  input:not(:placeholder-shown) ~ span,
  textarea:not(:placeholder-shown) ~ span {
    opacity: 1;
    transform: scale(1);
  }
  input[type='file'] {
    margin-top: 12px;
    height: 38px;
  }
`;

export default FormField;
