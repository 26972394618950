// @flow
import type { UserType } from 'types/resources/user';

import React from 'react';
import { IoIosArrowForward, IoMdSearch } from 'react-icons/io';
import { useStoreState } from 'easy-peasy';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import { MdCardGiftcard } from 'react-icons/md';

import { Flex, Text, Link, Box, Image, Button } from 'components/base';
import { mediaQuery, Theme } from 'styles';
import { PromotionLink } from 'components/promotion/SiteWidePromotion';
import { goToUserAuth } from 'utils/route';
import useToggle from 'utils/hooks/useToggle';

import Analytics from 'services/analytics';
import { referralDiscountString } from 'constants/referrals';
import { getNewsSitePath } from 'utils/country';
import PageContainer from './PageContainer';
import MobileHeader from './MobileHeader';
import SearchSidebar from '../../views/search/SearchSidebar';
import BrowseMenu from '../misc/BrowseMenu/BrowseMenu';
import AboutMenu from '../misc/BrowseMenu/AboutMenu';

const ReferralPromo = () => (
  <Box bg="gray7" borderRadius={4}>
    <Flex center my={2}>
      <MdCardGiftcard size={16} />
      <Text fontWeight="bold" ml={2}>
        <Trans>GET {referralDiscountString('referrer')} OFF</Trans>
      </Text>
    </Flex>
  </Box>
);

const Navbar = () => {
  const user: { user: UserType, isAuthenticated: boolean } = useStoreState(s => s.user);
  const [isSearchSidebarVisible, toggleSearchSidebar] = useToggle(false);

  return (
    <>
      <MobileHeader toggleSearchSidebar={toggleSearchSidebar} />
      <NavContainer className="sm-above" id="desktop-header">
        <PageContainer>
          <Flex as="nav" justifyContent="space-between" className="navbar" height={[0, 0, 52, 74]}>
            <Nav className="logo">
              <Flex center>
                <Link to="/">
                  <Image
                    src="/images/ns-black-logo.svg"
                    alt="Logo"
                    height={20}
                    className="md-above"
                  />
                  <Image
                    src="/images/ns-black-logo.svg"
                    alt="Logo"
                    height={14}
                    className="md-below"
                  />
                </Link>
              </Flex>
            </Nav>

            <Nav flex={1} justifyContent="flex-end">
              <PromotionLink />
              {user.isAuthenticated && (
                <Link to="/referrals">
                  <ReferralPromo />
                </Link>
              )}
              <Box mx={2} />
              <Link to="mobile-app">
                <Text>
                  <Trans>Download App</Trans>
                </Text>
              </Link>
              <Link className="dropdown-menu-trigger" to="/browse">
                <Flex>
                  <Text>
                    <Trans>Browse</Trans>
                  </Text>
                  &nbsp;
                  <IoIosArrowForward size={12} color="#999" />
                  <Box className="dropdown-menu">
                    <object>
                      <BrowseMenu />
                    </object>
                  </Box>
                </Flex>
              </Link>
              <Link className="dropdown-menu-trigger" to="">
                <Flex style={{ width: 'unset' }}>
                  <Link to="/about">
                    <Text>
                      <Trans>About</Trans>
                    </Text>
                  </Link>
                  <Box className="dropdown-menu">
                    <AboutMenu />
                  </Box>
                </Flex>
              </Link>
              <Link to={getNewsSitePath()}>
                <Text>
                  <Trans>News</Trans>
                </Text>
              </Link>
              <Link to="sell" onClick={Analytics.sellInitiate}>
                <Text>
                  <Trans>Sell</Trans>
                </Text>
              </Link>
              {user.isAuthenticated ? (
                <Link to="dashboard/profile">
                  <Text>
                    <Trans>My Account</Trans>
                  </Text>
                </Link>
              ) : (
                <>
                  <Button variant="clear" onClick={() => goToUserAuth({ page: 'login' })}>
                    <Text>
                      <Trans>Log In</Trans>
                    </Text>
                  </Button>
                  <Button variant="clear" onClick={goToUserAuth} className="md-above">
                    <Text>
                      <Trans>Sign Up</Trans>
                    </Text>
                  </Button>
                </>
              )}
              <Button variant="icon" onClick={toggleSearchSidebar} style={{ padding: 0 }}>
                <Flex center color="black3">
                  <IoMdSearch size={24} />
                </Flex>
              </Button>
            </Nav>
          </Flex>
        </PageContainer>
      </NavContainer>
      <SearchSidebar
        isSearchSidebarVisible={isSearchSidebarVisible}
        toggleSearchSidebar={toggleSearchSidebar}
      />
    </>
  );
};

const NavContainer = styled(Box)`
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  padding: 0 12px;
`;

const Nav = styled(Flex)`
  align-items: center;
  a,
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;

    :hover {
      text-decoration: underline;
    }

    & > div {
      width: 100%;
      align-items: center;
      padding: 0 12px;
      font-size: 14px;
      ${mediaQuery.md`
        font-size: 13px;
        padding: 0 8px;
      `}
      white-space: nowrap;
    }
  }

  .dropdown-menu-trigger {
    position: relative;
    text-decoration: none !important;
    margin-top: -1px;
    > div {
      border: 1px solid transparent;
      border-bottom: none;
      ${mediaQuery.md`
        width: auto;
        border-color:transparent!important;
      `}
    }

    .dropdown-menu {
      display: none;
      z-index: 1;
      position: absolute;
      left: 0px;
      top: 40px;

      a,
      button {
        border: none;
        text-decoration: none !important;
      }
    }
    &:hover {
      > div {
        border-color: ${Theme.colors.gray7};
        height: 40px;
      }
      .dropdown-menu {
        display: block;
        box-shadow: rgb(0 0 0 / 12%) 2px 2px 6px 0px;
      }
    }
  }
`;

export default Navbar;
