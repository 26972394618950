// @flow
import type { CurrencyType } from 'types/resources/currency';

import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { $, getCurrentCurrency } from 'utils/currency';
import { getCurrentCountry } from 'utils/country';
import { getUtmParams } from 'utils/share';

// Referrer -> referral giver
// Referee -> referral receiver

const referralDiscountString = (type: 'referrer' | 'referee') => {
  const currency: CurrencyType = getCurrentCurrency();

  if (type === 'referrer') {
    return $(currency.referrer_promocode_value);
  }
  return $(currency.referee_promocode_value);
};

const referralShareMessage = (referralCode: string, utm_campaign?: string) => {
  const url = `${
    window.location.origin
  }/signup?referral_code=${referralCode}&country=${getCurrentCountry().shortcode.toLowerCase()}`;
  const utmUrl = `${url}${getUtmParams('copy', url, utm_campaign || '', false)}`;
  return {
    text: `${i18n._(
      t`Get ${referralDiscountString(
        'referee'
      )} off your first purchase when you sign up on Novelship using my referral code`
    )} ${referralCode}`,
    url,
    utmUrl,
  };
};

export { referralDiscountString, referralShareMessage };
