// @flow
import React from 'react';
import styled from 'styled-components';
import { Theme } from 'styles';
import { Flex, Box } from 'components/base';

const StyledErrorMessage = styled(Box)`
  color: ${Theme.colors.red};
  margin: 2px 0;
  letter-spacing: 0.1px;
  text-align: left;
  font-size: 12px;
`;

const ErrorMessage = ({ children }: { children: any }) =>
  children ? <StyledErrorMessage>{children}</StyledErrorMessage> : null;

const ErrorMessageBox = ({ children, ...props }: { children: any }) =>
  children ? (
    <Flex m="0 auto" py={2} px={3} style={{ border: `1px solid ${Theme.colors.red}` }} {...props}>
      <StyledErrorMessage>{children}</StyledErrorMessage>
    </Flex>
  ) : null;

export default ErrorMessage;
export { ErrorMessageBox };
