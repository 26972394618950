// @flow
import type { UserType } from 'types/resources/user';
import type { CurrencyType } from 'types/resources/currency';

import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { cacheGet, cacheSet } from 'utils/localStorageCache';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Image, Text, Flex, Link } from 'components/base';
import { APP_STORE_URL, getImgixUrl } from 'constants/constants';

import { SITE_VISITED_COUNT_CACHED, isMobile } from 'utils';
import { getHighestValueWelcomePromocode } from 'utils/promocode';
import InfoDialog from './InfoDialog';

const DIALOG_PERIOD = 14 * 24 * 60;

const WelcomeMobileAppDialog = () => {
  const user: UserType = useStoreState(s => s.user.user);
  const currency: CurrencyType = useStoreState(s => s.currency.current);
  const [show, setShow] = useState(false);

  const { value: welcomeDiscount, code: welcomeCode } = getHighestValueWelcomePromocode(
    currency,
    user
  );

  if (window.location.pathname.includes('mobile-app') || window.isNativeApp || window.isPrerender) {
    return null;
  }

  if (!cacheGet(`app_promotion_dialog`) && SITE_VISITED_COUNT_CACHED === 12) {
    // show only on second visit
    setShow(cacheSet(`app_promotion_dialog`, true, DIALOG_PERIOD));
  }

  return (
    <InfoDialog
      isOpen={show}
      onClose={() => setShow(false)}
      buttonText={i18n._(t`GOT IT`)}
      showClose
    >
      <Flex center flexDirection="column">
        <Text fontSize={4} my={4} className="bold ls06">
          {welcomeCode ? <Trans>WELCOME TO NOVELSHIP</Trans> : <Trans>DOWNLOAD OUR APP NOW!</Trans>}
        </Text>
        <Text fontSize={2}>
          {welcomeCode ? (
            <>
              <Trans>
                We’re glad to have you on board! Download our app and join our community of
                sneakerheads.
              </Trans>
              <br />
              {welcomeCode ? (
                <>
                  <Text pb={3}>
                    <Trans>
                      Get <b>{welcomeDiscount}</b> off your purchase with code{' '}
                    </Trans>
                  </Text>
                  <Text fontSize={3} mt={1} py={1} px={3} bg="yellow" display="inline">
                    <b>{welcomeCode}</b>
                  </Text>
                </>
              ) : null}
            </>
          ) : (
            <Trans>
              Join the Novelship community on our brand new app. Browse your favorite sneakers and
              apparel, and get access to exclusive app only offers.
              <br />
              Transform the way you buy and sell on Novelship!
            </Trans>
          )}
        </Text>
        <Flex center flexDirection="row" mt={2}>
          <Link
            to={isMobile ? APP_STORE_URL.ios : 'mobile-app'}
            onClick={() => setShow(false)}
            target="_self"
          >
            <Image
              src={getImgixUrl('main/iOS_app_store.png')}
              sx={{ width: 140, borderRadius: 8 }}
              alt="App store download"
            />
          </Link>
          <Link to={isMobile ? APP_STORE_URL.android : 'mobile-app'} onClick={() => setShow(false)}>
            <Image
              src={getImgixUrl('main/Google_play_store.png')}
              sx={{ width: 140, borderRadius: 8 }}
              alt="Google play download"
            />
          </Link>
        </Flex>
      </Flex>
    </InfoDialog>
  );
};
export default WelcomeMobileAppDialog;
