// @flow

import { parseQuery } from 'utils';
import { cacheSet } from 'utils/localStorageCache';

const { transaction_id, utm_campaign, signup_reference, SSKEY, sskey } = parseQuery();

if (transaction_id) {
  cacheSet('shopback_id', transaction_id, 30 * 24 * 60);
}

if (SSKEY || sskey) {
  cacheSet('optimise_affiliate_id', SSKEY || sskey, 30 * 24 * 60);
}

const campaign = signup_reference || utm_campaign;
if (campaign) {
  cacheSet('utm_campaign_cached', campaign, 7 * 24 * 60);
}
