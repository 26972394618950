// @flow
import styled from 'styled-components';
import React from 'react';

import CookieUseConsent from './CookieUseConsent';
import MobileAppFooter from './MobileAppFooter';
import SecondaryFooter from './Secondary';
import PrimaryFooter from './Primary';

const Footer = () => (
  <>
    <StyledFooter className="ns-footer">
      <MobileAppFooter />
      <PrimaryFooter />
      <SecondaryFooter />
    </StyledFooter>
    <CookieUseConsent />
  </>
);

const StyledFooter = styled.footer`
  border-top: 1px solid #444;
`;

export default Footer;
