// @flow
import slugify from 'utils/string/slugify';
import browseData, { APPAREL_BRANDS, TOP_LEVEL_CATEGORIES_CLASS_MAP } from './browseData';

type CategoryType = {
  name: string,
  slug: string,
  url: string,
  tree: string,
  initialShowLimit?: number,
  children: CategoryType[],
};

const browseDataFlat = [];

function recursivelyAddFields(parent: CategoryType) {
  browseDataFlat.push(parent);
  if (!parent.slug) parent.slug = slugify(parent.name);
  if (!parent.url) parent.url = parent.slug;
  if (!parent.tree) parent.tree = parent.name;

  (parent.children || []).forEach(child => {
    child.slug = child.name.toLowerCase().replace(/ /g, '-');
    child.url = `${parent.url}/${child.slug || ''}`;
    child.tree = `${parent.tree}\\${child.name || ''}`;
    if (!child.children) child.children = [];

    browseDataFlat.push(child);
    recursivelyAddFields(child);
  });
}

browseData.forEach(recursivelyAddFields);

export { browseDataFlat, APPAREL_BRANDS, TOP_LEVEL_CATEGORIES_CLASS_MAP };
export default browseData;
export type { CategoryType };
