// @flow
import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Trans } from '@lingui/macro';

import { isCampaignTime } from 'constants/campaign';
import { Link, Text } from 'components/base';

const PromotionLink = () =>
  isCampaignTime && (
    <Link color="#FF7A7A" to="">
      <Text className="bold">{/* <Trans></Trans> */}</Text>
    </Link>
  );

const PromotionTicker = () => {
  const currentCountryShortCode = useStoreState(s => s.country.current.shortcode);
  return (
    <Link to="/sneakers">
      {/(TW|ID|JP)/.test(currentCountryShortCode) ? (
        <Trans>
          0% SELLER FEES SITE WIDE. <b>SELL NOW.</b>
        </Trans>
      ) : /(SG|AU|NZ|MY)/.test(currentCountryShortCode) ? (
        <Trans>
          FREE DELIVERY & SHIPPING SITE WIDE. <b>SHOP NOW.</b>
        </Trans>
      ) : (
        <></>
      )}
    </Link>
  );
};

export { PromotionLink, PromotionTicker };
