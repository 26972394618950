// @flow
import { action, thunk, Action, Thunk } from 'easy-peasy';
import { API } from 'api';
import { anonymousUser, type UserType } from 'types/resources/user';
import { GetUserWithDefaults } from 'utils/user';
import { sentryCapture } from 'services/sentry';

interface GuestUserStoreModel {
  user: UserType | null;
  isGuestUser: boolean;
  set: Action<GuestUserStoreModel, UserType>;
  fetch: Thunk<GuestUserStoreModel>;
}
const GuestUserStore: GuestUserStoreModel = {
  user: anonymousUser,
  isGuestUser: false,
  set: action((store, _user) => {
    const user = GetUserWithDefaults(_user);
    store.user = user;
    store.isGuestUser = true;
  }),
  fetch: thunk(async actions =>
    API.fetch('me/guest')
      .then(user => {
        if (user?.id) {
          actions.set(user);
          return user;
        }
      })
      .catch(err => {
        console.log(err);
        sentryCapture(err);
      })
  ),
  update: thunk((actions, payload) => API.put('me/guest', payload).then(() => actions.fetch())),
};

export default GuestUserStore;
