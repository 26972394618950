// @flow
import * as React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import styled from 'styled-components';
import { Theme } from 'styles';
import { i18n } from '@lingui/core';

import { Flex, Text, Link } from 'components/base';
import _browse from 'store/constants/browse';

const browse = JSON.parse(JSON.stringify(_browse));

const BrowseMenu = () => {
  const [menuList, updateMenuList] = React.useState([browse]);

  const showSubMenu = (menu, i, j) => {
    if (menuList[i][j].active || i > 0) {
      return;
    }
    const newMenuList = [...menuList];
    newMenuList.length = i + 1;
    newMenuList[i].forEach(m => (m.active = false));
    newMenuList[i][j].active = true;
    if (menu.children && menu.children.length) {
      newMenuList.push(menu.children);
    }
    updateMenuList(newMenuList);
  };

  return (
    <MenuContainer bg="white" color="black2">
      {menuList.map((menu, i) => (
        <MenuList key={i}>
          {menu.slice(0, 9).map((m, j) => (
            <MenuItem
              onMouseOver={() => showSubMenu(m, i, j)}
              onFocus={() => showSubMenu(m, i, j)}
              className={`${m.active && 'active'}`}
              to={`${m.url}?sort=mostPopular`}
              key={j}
            >
              <Text>{i18n._(m.name)}</Text>
              &nbsp;&nbsp;
              {m.children && m.children.length !== 0 && i === 0 && (
                <IoIosArrowForward size={11} color="#999" />
              )}
            </MenuItem>
          ))}
        </MenuList>
      ))}
    </MenuContainer>
  );
};

const MenuContainer = styled(Flex)`
  position: relative;
  border: 1px solid ${Theme.colors.gray7};
  border-top: none;
`;

const MenuList = styled(Flex)`
  max-height: calc(100vh - 90px);
  flex-flow: column wrap;
  flex-grow: 1;
  width: 157px;
`;

const MenuItem = styled(Link)`
  align-items: center;
  padding: 0 16px 0 6px;
  font-size: 13px;
  display: flex;
  height: 38px;
  width: 100%;
  position: relative;
  letter-spacing: 0.2px;
  > svg {
    position: absolute;
    right: 12px;
  }

  div {
    border: none !important;
  }

  &:hover,
  &.active {
    background: ${Theme.colors.gray7};
  }
`;

export default BrowseMenu;
