/* eslint-disable jsx-a11y/no-autofocus */
// @flow
import React from 'react';
import { IoMdSearch } from 'react-icons/io';
import styled from 'styled-components';

import { Flex, Image, Link, Button, Box } from 'components/base';
import { Theme } from 'styles';
import useToggle from 'utils/hooks/useToggle';
import SideMenu from 'components/Layout/SideMenu';
import { MdOutlineMenu } from 'react-icons/md';

const MobileHeader = ({ toggleSearchSidebar }: { toggleSearchSidebar: any => mixed }) => {
  const [isSideMenuOpen, toggleSideMenu] = useToggle(false);

  return (
    <HeaderContainer className="sm-below ns-mobile-header" id="mobile-header">
      <Header center bg="white">
        <Flex center className="left">
          <Button variant="icon" onClick={toggleSideMenu} mb={1} aria-label="menu">
            <MdOutlineMenu color="black" size={28} />
          </Button>
        </Flex>
        <Flex center flex="auto" className="middle">
          <Link to="/" px={4} pt={2} pb={3}>
            <Image src="/images/ns-black-logo.svg" alt="Logo" height={15} />
          </Link>
        </Flex>
        <Flex center className="right">
          <Button variant="icon" className="search" onClick={toggleSearchSidebar}>
            <IoMdSearch color="black" size={25} aria-label="search" />
          </Button>
        </Flex>
      </Header>
      <SideMenu toggleSideMenu={toggleSideMenu} isSideMenuOpen={isSideMenuOpen} />
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: 0.8px solid ${Theme.colors.gray7};
`;

const Header = styled(Flex)`
  height: 46px;
  width: 100%;

  .left {
    justify-content: flex-start;
  }

  .right {
    justify-content: flex-end;

    .search {
      padding-top: 6px;
    }
  }

  .middle {
    .search-bar {
      width: 100%;
      position: relative;

      .search-icon {
        font-size: 1.1em;
        position: absolute;
        top: 10px;
        left: 8px;
      }

      input[type='search'] {
        width: 100%;
        height: 38px;
        color: ${Theme.colors.black2};
        padding-left: 30px;
        padding-top: 5px;
        font-size: 16px;
        letter-spacing: 0.1px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
      }
    }
  }

  svg {
    font-size: 1.3em;
    vertical-align: middle;
  }
`;

export default MobileHeader;
