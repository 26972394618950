// @flow
import type { CountryShippingOptionType } from 'types/resources/countryShippingOption';
import type { CountryType } from 'types/resources/country';

import { action, Action, Computed, computed } from 'easy-peasy';

import { cacheGet, cacheSet } from 'utils/localStorageCache';
import { defaultCountry } from 'types/resources/country';

import { defaultCountryShippingOption } from 'types/resources/countryShippingOption';

type CountryStoreType = {
  countries: CountryType[],
  current: CountryType,
  getById: Computed<CountryStoreType, (id: number & string) => CountryType>,
  getShippingOptions: Computed<
    CountryStoreType,
    (countryId: number) => CountryShippingOptionType[]
  >,
  set: Action<CountryStoreType, { countries: CountryType[], currentCountry: CountryType }>,
};

const CountryStore: CountryStoreType = {
  countries: cacheGet('last_countries', [defaultCountry]),
  current: cacheGet('last_country', defaultCountry),
  getById: computed(state => id =>
    state.countries.find(c => c.id === parseInt(id)) || defaultCountry
  ),
  getByShortcode: computed(state => shortcode =>
    state.countries.find(c => c.shortcode === shortcode)
  ),
  getShippingOptions: computed(state => id => {
    if (!id) return [];
    return (
      state.countries.find(c => c.id === parseInt(id)).shipping_options || [
        defaultCountryShippingOption,
      ]
    );
  }),
  set: action((store, { countries, currentCountry }) => {
    store.current = cacheSet('last_country', currentCountry);
    store.countries = cacheSet('last_countries', countries);
  }),
};

export default CountryStore;
