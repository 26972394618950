// @flow
import { anonymousUser, type UserType } from 'types/resources/user';
import { navigate } from '@reach/router';
import { lowerArray, SITE_VISITED_COUNT_CACHED } from 'utils';
import { cacheGet, cacheRemove } from './localStorageCache';

const isAuthenticated = () => !!cacheGet('token');

const logoutUser = () => {
  cacheRemove('token');
  navigate('/');
};

const getUserEventCheckins = (user: UserType, eventCode: string) =>
  user.checkin.find(c => c.event === eventCode);

const getUserEventRaffle = (user: UserType, eventCode: string) =>
  user.raffle.find(c => c.event === eventCode);

function getUserSignupAndLoginMethodName(user: UserType) {
  if (user.google) {
    return 'google';
  }
  if (user.facebook) {
    return 'facebook';
  }
  if (user.apple) {
    return 'apple';
  }
  return 'email';
}

const getUserVisitingStatus = () => {
  const userVisitingStatus = SITE_VISITED_COUNT_CACHED === 1 ? 'new_user' : 'returning_user';
  return userVisitingStatus;
};

const GetUserWithDefaults = (user: UserType) => {
  user.onboarding_task = user.onboarding_task || anonymousUser.onboarding_task;
  user.favorite_brands = user.favorite_brands || anonymousUser.favorite_brands;
  user.size_preferences = user.size_preferences || anonymousUser.size_preferences;
  user.payout_info = user.payout_info || anonymousUser.payout_info;
  user.seller_fee = user.seller_fee || anonymousUser.seller_fee;
  user.country = user.country || anonymousUser.country;
  user.billing_country = user.billing_country || anonymousUser.country;
  user.selling_country = user.selling_country || anonymousUser.country;
  user.shipping_country = user.shipping_country || anonymousUser.country;

  user.address = user.address || anonymousUser.address;
  user.billing_address = user.billing_address || anonymousUser.billing_address;
  user.selling_address = user.selling_address || anonymousUser.selling_address;
  user.shipping_address = user.shipping_address || anonymousUser.shipping_address;

  user.shipping_stats = user.shipping_stats || anonymousUser.shipping_stats;

  // client only fields
  user.isAdmin = user.role === 'admin';
  user.firstTimePromocodeEligible = !lowerArray(user.groups).includes('has-purchase');
  user.refereeEligible =
    lowerArray(user.groups).includes('referee') && user.firstTimePromocodeEligible;
  user.showPowerSellerFeature =
    !!user.seller_fee.power_features || lowerArray(user.groups).includes('power-features');
  user.sneakerSize = user.size_preferences.Sneakers
    ? `${user.size_preferences.Sneakers.unit} ${user.size_preferences.Sneakers.size}${
        user.size_preferences.Sneakers.category === 'men' &&
        user.size_preferences.Sneakers.unit === 'US'
          ? 'M'
          : ''
      }`
    : '';
  user.teeSize = user.size_preferences.Apparel?.size || '';
  user.dob = user.dob ? new Date(user.dob.slice(0, -1)).toISOString() : '';

  return user;
};

export {
  logoutUser,
  getUserEventCheckins,
  getUserEventRaffle,
  getUserSignupAndLoginMethodName,
  isAuthenticated,
  getUserVisitingStatus,
  GetUserWithDefaults,
};
