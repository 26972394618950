// @flow
import * as React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { FaChevronRight } from 'react-icons/fa';
import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { Box, Flex, Text, Button } from 'components/base';
import { cacheGet, cacheRemove } from 'utils/localStorageCache';
import theme from 'styles/theme';

const RecentSearches = () => {
  const [recentSearches, setRecentSearches] = useState(cacheGet('recent_searches', []));
  const { searchesTrending = [] } = useStoreState(s => s.context);

  return (
    <Box>
      {!!recentSearches.length && (
        <>
          <TitleCard title={i18n._(t`RECENT SEARCHES`)}>
            <Button
              variant="clear"
              onClick={() => {
                cacheRemove('recent_searches');
                setRecentSearches([]);
              }}
            >
              <Text fontSize={1} fontWeight="bold" textDecorationLine="underline">
                <Trans>CLEAR</Trans>
              </Text>
            </Button>
          </TitleCard>
          {recentSearches.map(recentSearch => (
            <ItemCard key={recentSearch} title={recentSearch} />
          ))}
          <Box mt={3} />
        </>
      )}

      {!!searchesTrending.length && (
        <>
          <TitleCard title={i18n._(t`TRENDING`)} />
          {searchesTrending.map(trending => (
            <ItemCard key={trending} title={trending} showArrow />
          ))}
        </>
      )}
    </Box>
  );
};

const TitleCard = ({ title, children }: { title: string, children?: React.Node }) => (
  <Flex p={5} pb={3} alignItems="center" justifyContent="space-between">
    <Text fontWeight="bold">{title}</Text>
    {children}
  </Flex>
);

const ItemCard = ({ title, showArrow }: { title: string, showArrow?: boolean }) => {
  const { setSearch, setIsSearching } = useStoreActions(a => a.search);

  const search = () => {
    setIsSearching(true);
    setSearch(title);
  };

  return (
    <Button variant="clear" onClick={search} width={1}>
      <Flex
        px={5}
        width={1}
        height={40}
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        style={{
          border: `1px solid ${theme.colors.divider}`,
          borderTop: 'none',
        }}
      >
        <Text color="gray3" fontSize={2}>
          {title}
        </Text>
        {showArrow && <FaChevronRight size={18} color={theme.colors.gray2} />}
      </Flex>
    </Button>
  );
};

export default RecentSearches;
