// UNUSED
// @flow
import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Trans, t } from '@lingui/macro';
import type { UserType } from 'types/resources/user';

import ConfirmDialog from 'views/user/components/ConfirmDialog';
import { Text } from 'components/base';
import API from 'api/api';
import { getHighestValueWelcomePromocode } from 'utils/promocode';
import { getUserSignupAndLoginMethodName } from 'utils/user';

const MarketingEmailOptInDialog = () => {
  const user: UserType = useStoreState(s => s.user.user);
  const fetchUser = useStoreActions(a => a.user.fetch);
  const openReferralInputDialog = useStoreActions(a => a.referralInputDialog.openDialog);
  const closeDialog = useStoreActions(a => a.marketingEmailDialog.closeDialog);
  const isOpen = useStoreState(a => a.marketingEmailDialog.isOpen);
  const currency = useStoreState(s => s.currency.current);

  if (!user.id) {
    return null;
  }

  const { value: welcomePromocode } = getHighestValueWelcomePromocode(currency, user, false);
  const { notification_preferences: preferences, referred_by: isReferredUser } = user;

  const showReferralInputDialog = () => {
    if (getUserSignupAndLoginMethodName(user) !== 'email') {
      setTimeout(openReferralInputDialog, 200);
    } else if (user.referred_by) {
      setTimeout(openReferralInputDialog, 200);
    }
  };

  const enableMarketingEmail = () =>
    API.put('me', {
      notification_preferences: {
        ...preferences,
        promotions: { email: true, push: preferences.promotions.push },
      },
    }).then(() => {
      fetchUser();
      showReferralInputDialog();
    });

  const onCloseDialog = () => {
    closeDialog();
    showReferralInputDialog();
  };

  return (
    <ConfirmDialog
      isOpen={isOpen}
      toggleDialog={closeDialog}
      title={
        isReferredUser
          ? t`JOIN OUR EXCLUSIVE EMAIL LIST`
          : welcomePromocode
          ? t`GET ${welcomePromocode} OFF! ${`\n`}JOIN OUR EXCLUSIVE EMAIL LIST`
          : t`JOIN OUR EXCLUSIVE EMAIL LIST`
      }
      confirmText={t`Opt In Now`}
      onConfirm={enableMarketingEmail}
      onCancel={onCloseDialog}
    >
      <Text fontSize={2} center>
        {isReferredUser ? (
          <Trans>
            Unlock exclusive deals and stay up-to-date with the latest trends and offers by opt-in
            to our marketing email list.
          </Trans>
        ) : (
          <>
            <Trans>
              Stay up-to-date with the latest trends and offers by opt-in to the marketing email
              list.
            </Trans>{' '}
            {welcomePromocode && (
              <Trans>
                Opt in now to receive a {welcomePromocode} promo code! Don't miss out on this
                limited-time offer.
              </Trans>
            )}
          </>
        )}
      </Text>
    </ConfirmDialog>
  );
};

export default MarketingEmailOptInDialog;
