// @flow
import * as React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import styled from 'styled-components';
import { Input, ErrorMessage } from 'components/form';
import { Theme } from 'styles';
import { referralDiscountString } from 'constants/referrals';
import { Box, Text, Flex, Button, Image } from 'components/base';

import { API } from 'api';
import Analytics from 'services/analytics';
import { navigateToRoute } from 'utils/route';
import useToggle from 'utils/hooks/useToggle';
import { getImgixUrl } from 'constants/constants';
import Dialog from './Dialog';

const BlueText = ({ children }: { children: React.Node }) => (
  <Text color="blue" fontWeight="bold" style={{ display: 'inline-flex' }}>
    {children}
  </Text>
);

const SucessContent = ({ closeDialog }: { closeDialog: () => void }) => (
  <>
    <Box bg="gray7" borderRadius={4} display="flex" width={1} py={6}>
      <Image
        src={getImgixUrl('referrals/dollar_label.png')}
        margin="auto"
        height={100}
        width={100}
      />
    </Box>
    <Flex center flexDirection="column" px={2} py={3} mx={3}>
      <Text fontSize={4} mb={4} fontWeight="bold">
        <Trans>YOUR PROMO CODE IS IN</Trans>
      </Text>
      <Text fontSize={2} mb={8}>
        <Trans>
          <BlueText>{referralDiscountString('referee')}</BlueText> has been credited into your
          account. Hurry up and make a purchase with Novelship so that your friend will be rewarded
          with {referralDiscountString('referrer')} too.
        </Trans>
      </Text>
      <Button variant="black" width={1} onClick={closeDialog}>
        <Trans>CONTINUE BROWSING</Trans>
      </Button>
      <Button
        variant="white"
        width={1}
        mt={3}
        mb={2}
        onClick={() => {
          closeDialog();
          navigateToRoute('/dashboard/promotions');
        }}
      >
        <Trans>VIEW MY PROMOCODE</Trans>
      </Button>
    </Flex>
  </>
);

const ReferralInputDialog = () => {
  const isOpen = useStoreState(s => s.referralInputDialog.isOpen);
  const closeDialog = useStoreActions(a => a.referralInputDialog.closeDialog);
  const user = useStoreState(s => s.user.user);
  const fetchUser = useStoreActions(a => a.user.fetch);
  const [isLoading, toggleLoading]: [boolean, (any) => any] = useToggle(false);

  const isSuccess = user.referred_by || false;
  const [formError, setFormError] = React.useState('');
  const [referralInputValue, setReferralInputValue] = React.useState('');

  const submit = async () => {
    try {
      toggleLoading();
      setFormError('');
      await API.post('me/referral', {
        referral_code: referralInputValue,
      });

      await fetchUser();
      toggleLoading();
      Analytics.referralCodeApplied(referralInputValue);
    } catch (error) {
      toggleLoading();
      setFormError(error);
    }
  };

  const onClose = () => {
    setReferralInputValue('');
    closeDialog();
  };

  const onReferralChange = e => {
    const referralCode = e.target.value;
    setReferralInputValue(referralCode);
    setFormError('');
  };

  return (
    <Dialog isOpen={isOpen} width={[320, 432]} onClose={onClose}>
      {isSuccess ? (
        <SucessContent closeDialog={closeDialog} />
      ) : (
        <>
          <Text fontSize={4} my={4} textAlign="center" className="bold">
            <Trans>WELCOME TO NOVELSHIP</Trans>
          </Text>
          <Box className="h-divider" />
          <Flex center flexDirection="column" p={5}>
            <Text fontSize={2} mb={6}>
              <Trans>
                Own a referral code?
                <br />
                Enter now to redeem <BlueText>
                  {referralDiscountString('referee')} off
                </BlueText>{' '}
                your first purchase discount code.
              </Trans>
            </Text>
            <Flex width={1} flexDirection="column" mb={5}>
              <ReferralInput>
                <Input
                  type="text"
                  height={50}
                  placeholder={i18n._(t`Enter Referral Code`)}
                  value={referralInputValue}
                  onChange={onReferralChange}
                  disabled={isLoading}
                />
              </ReferralInput>
              <ErrorMessage>{i18n._(formError)}</ErrorMessage>
            </Flex>
            <Button variant="black" mt={5} width={1} loading={isLoading} onClick={submit}>
              <Trans>APPLY REFERRAL CODE</Trans>
            </Button>
            <Button variant="white" mt={3} width={1} onClick={onClose}>
              <Trans>CONTINUE BROWSING</Trans>
            </Button>
          </Flex>
        </>
      )}
    </Dialog>
  );
};

const ReferralInput = styled(Flex)`
  input {
    border-color: ${Theme.colors.gray3};
    border-radius: 4px;
    &:focus {
      border-color: ${Theme.colors.gray3} !important;
    }
    &::placeholder {
      color: ${Theme.colors.gray3};
    }
  }
`;

export default ReferralInputDialog;
