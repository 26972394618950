// @flow

import React from 'react';
import { Box, Flex, Image, Text, Link } from 'components/base';
import { APP_STORE_URL, getImgixUrl } from 'constants/constants';
import { Trans } from '@lingui/macro';
import theme from 'styles/theme';
import styled from 'styled-components';
import environment from 'constants/environment';
import { TrustPilotFooterWidget } from 'components/widgets/TrustpilotBar';
import ReviewsIOWidget from '../../widgets/ReviewsIOWidget';

const MobileAppFooter = () => (
  <Box bg="black" className="mobile-app-footer">
    <Flex
      className="md-below"
      pt={10}
      pb={4}
      justifyContent={['space-between', 'center', 'center']}
      mx={4}
      px={2}
    >
      <TrustPilotFooterWidget width={150} mr={[0, 64, 64]} />
      <ReviewsIOWidget height={100} width={125} />
    </Flex>
    <Flex center bg="black">
      <Flex
        center
        flexDirection={['column', 'row']}
        bg="black"
        color="white"
        maxWidth={900}
        mt={[4, 8]}
      >
        <Box p={4} width={[1, 1 / 2, 1 / 2]}>
          <Flex
            my={6}
            mx={4}
            className="md-above"
            alignItems="center"
            justifyContent="space-between"
          >
            <TrustPilotFooterWidget width={150} />
            <ReviewsIOWidget width={125} />
          </Flex>
          <Flex flexWrap="wrap">
            <Text fontSize={[6, 8]} fontWeight="bold" mx={2}>
              <Trans>Download the Novelship App </Trans>
            </Text>

            <Flex alignItems="center" width={1} mx={2} my={2}>
              <Text fontSize={4} fontWeight="bold" mr={2}>
                {environment.mobileApp.appleRating}
              </Text>
              <StarList className="star-rating" />
            </Flex>
            <Flex flexDirection="column">
              <Flex alignItems="center" mx={2}>
                •
                <Text fontSize={[1, 2]} fontWeight="bold" mx={2}>
                  <Trans>Shop sneakers, apparel and collectibles </Trans>
                </Text>
              </Flex>

              <Flex alignItems="center" mx={2}>
                •
                <Text fontSize={[1, 2]} fontWeight="bold" mx={2}>
                  <Trans>Get access to members-only exclusive offers, & the latest drops</Trans>
                </Text>
              </Flex>

              <Flex alignItems="center" mx={2}>
                •
                <Text fontSize={[1, 2]} fontWeight="bold" mx={2}>
                  <Trans>Brand new items at your fingertips</Trans>
                </Text>
              </Flex>

              <Link
                to="mobile-app"
                className="link"
                fontSize={[1, 2]}
                color="gray4"
                mx={2}
                my={1}
                alt="Learn more about Mobile App"
              >
                <Trans>Learn more about the App</Trans>
              </Link>
            </Flex>
          </Flex>
          <Flex center width={1} my={[2, 6]} maxWidth={540}>
            <Flex center flexDirection="column" mr={2}>
              <Link
                to={APP_STORE_URL.ios}
                className="center"
                alt="App store download"
                target="_self"
              >
                <Image
                  src={getImgixUrl('main/iOS_app_store.png')}
                  width={140}
                  sx={{
                    width: 140,
                    borderRadius: 8,
                    border: `1px solid ${theme.colors.gray1}`,
                    marginBottom: 12,
                  }}
                  alt="App store download"
                />
                <Image
                  width={110}
                  className="sm-above block center"
                  src={getImgixUrl('main/ios-qr.png?q=90')}
                  sx={{ width: 110, borderRadius: 8, margin: 'auto' }}
                  alt="App store download"
                />
              </Link>
            </Flex>

            <Flex center flexDirection="column" ml={2}>
              <Link to={APP_STORE_URL.android} alt="Google play download">
                <Image
                  width={140}
                  src={getImgixUrl('main/Google_play_store.png')}
                  sx={{
                    width: 140,
                    borderRadius: 8,
                    border: `1px solid ${theme.colors.gray1}`,
                    marginBottom: 12,
                  }}
                  mx={2}
                  alt="Google play download"
                />
                <Image
                  width={110}
                  className="sm-above block center"
                  src={getImgixUrl('main/android-qr.png?q=90')}
                  sx={{ width: 110, borderRadius: 8, margin: 'auto' }}
                  alt="Google play download"
                />
              </Link>
            </Flex>
          </Flex>

          <Text className="sm-above" textAlign="center" fontSize={0} mt={2}>
            <Trans>Scan this QR code with your smartphone to download the free app.</Trans>
          </Text>
        </Box>
        <Box p={5} width={[1, 1 / 2, 1 / 2]} fontSize={4}>
          <Image
            width={480}
            src={getImgixUrl('main/mobile-app-full-screen.png')}
            sx={{ width: '100%', borderRadius: 8 }}
            alt="Mobile App Screenshot"
          />
        </Box>
      </Flex>
    </Flex>
  </Box>
);

const StarListStyles = styled(Box)`
  width: calc(20px * ${parseFloat(environment.mobileApp.appleRating)});
  height: 20px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="-40 -40 80 80"><path fill="white" stroke="white" stroke-width="2"  d="M 0.000 20.000 L 23.511 32.361 L 19.021 6.180 L 38.042 -12.361 L 11.756 -16.180 L 0.000 -40.000 L -11.756 -16.180 L -38.042 -12.361 L -19.021 6.180 L -23.511 32.361 L 0.000 20.000 "/></svg>');

  :before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(20px * 5);
    z-index: -1;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="-40 -40 80 80"><path fill="white" stroke="white" stroke-width="2"  d="M 0.000 20.000 L 23.511 32.361 L 19.021 6.180 L 38.042 -12.361 L 11.756 -16.180 L 0.000 -40.000 L -11.756 -16.180 L -38.042 -12.361 L -19.021 6.180 L -23.511 32.361 L 0.000 20.000 "/></svg>');
  }
`;

const StarList = () => (
  <Box style={{ position: 'relative' }}>
    <StarListStyles />
  </Box>
);

export default MobileAppFooter;
