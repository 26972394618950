// @flow
import React, { useState, useEffect } from 'react';
import { Button, Link, Text, Box } from 'components/base';
import { useStoreState } from 'easy-peasy';
import { Trans } from '@lingui/macro';

import { cacheGet, cacheSet } from 'utils/localStorageCache';

import Dialog from './Dialog';

const isEnglishAddress = ({ line_1, line_2, line_3, city }) => {
  const enRegEx = /^[a-zA-Z0-9$@$!%*?&#^-_., +]+$/;
  return (
    (!line_1 || enRegEx.test(line_1)) &&
    (!line_2 || enRegEx.test(line_2)) &&
    (!line_3 || enRegEx.test(line_3)) &&
    (!city || enRegEx.test(city))
  );
};

const AddressUpdateAlertDialog = () => {
  const country = useStoreState(s => s.country.current);
  const user = useStoreState(s => s.user.user);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (user.id && country.id) {
      if (
        country.shortcode === 'TW' &&
        !(isEnglishAddress(user.address) && isEnglishAddress(user.shipping_address)) &&
        !cacheGet('tw_address_update_alert_shown')
      ) {
        setShow(true);
        cacheSet('tw_address_update_alert_shown', true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, country.id]);

  if (!user.id) return null;

  return (
    <Dialog isOpen={show} width={[320, 432]} onClose={() => setShow(false)}>
      <Text className="bold" my={4} fontSize={4} textAlign="center">
        <Trans>UPDATE YOUR ADDRESS</Trans>
      </Text>

      <Box className="h-divider" />

      <Box p={5}>
        <Text fontSize={2} textAlign="center">
          <Trans>
            To optimize shipping experience, please fill in your buying/seller address according to
            our updated structure.
          </Trans>
        </Text>
      </Box>

      <Box p={5}>
        <Link to="/dashboard/settings">
          <Button variant="black" width={1} onClick={() => setShow(false)}>
            <Trans>Go To Settings</Trans>
          </Button>
        </Link>
      </Box>
    </Dialog>
  );
};

export default AddressUpdateAlertDialog;
