// @flow
import * as React from 'react';
import styled from 'styled-components';
import { Theme } from 'styles';
import { Trans } from '@lingui/macro';

import { Flex, Link, Box } from 'components/base';
import { getFaqLink } from 'store/constants/language';

const AboutMenu = () => (
  <Box style={{ display: 'flex' }}>
    <MenuContainer bg="white" color="black2">
      <MenuList>
        <MenuItem to={getFaqLink('buyers_guide')}>
          <Trans>Buying Guide</Trans>
        </MenuItem>
        <MenuItem to="https://novelship.com/news/howtosell_2023/">
          <Trans>Selling Guide</Trans>
        </MenuItem>
        <MenuItem to={getFaqLink('main')}>
          <Trans>Support</Trans>
        </MenuItem>
      </MenuList>
    </MenuContainer>
    <MenuContainer bg="white" color="black2">
      <MenuList>
        <MenuItem to="/about">
          <Trans>Company</Trans>
        </MenuItem>
        <MenuItem to="/authentication">
          <Trans>Authenticity</Trans>
        </MenuItem>
        <MenuItem to="/careers">
          <Trans>Careers</Trans>
        </MenuItem>
      </MenuList>
    </MenuContainer>
  </Box>
);

const MenuContainer = styled(Flex)`
  position: relative;
  border: 1px solid ${Theme.colors.gray7};
  border-top: none;
`;

const MenuList = styled(Flex)`
  max-height: calc(100vh - 90px);
  flex-flow: column wrap;
  flex-grow: 1;
  width: 128px;
`;

const MenuItem = styled(Link)`
  text-align: left;
  padding: 0 16px 0 12px;
  font-size: 13px;
  display: flex;
  justify-content: flex-start !important;
  height: 38px;
  width: 100%;
  position: relative;
  letter-spacing: 0.02em;
  > svg {
    position: absolute;
    right: 12px;
  }

  div {
    border: none !important;
  }

  &:hover,
  &.active {
    background: ${Theme.colors.gray7};
  }
`;

export default AboutMenu;
