// @flow
import type { UserType } from 'types/resources/user';

// Smart(est) async loading of sentry, and setting user context
function onSentryLoaded(user = {}) {
  window.Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_RELEASE,
    // sampleRate: 0.5, // enable after we hit lot more users
    whitelistUrls: [/novelship\.com/, /test\.novelship\.com/],
    ignoreErrors: [
      'top.GLOBALS',
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'atomicFindClose',
      'fb_xd_fragment',
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      'failed to fetch',
      'ResizeObserver',
      'chunk',
    ],
    ignoreUrls: [
      /graph\.facebook\.com/i,
      /connect\.facebook\.net\/en_US\/all\.js/i,
      /extensions\//i,
      /^chrome:\/\//i,
      /unexpected/i,
      /pixel/i,
    ],
  });
  if (user.id) window.Sentry.setUser({ email: user.email, id: user.id });
}

function SentryInit(user: UserType) {
  if (process.env.NODE_ENV !== 'production' || window.isPrerender) return;

  if (window.Sentry) {
    onSentryLoaded(user);
  } else {
    // $FlowFixMe
    document.querySelector('#js-sentry').addEventListener('load', () => onSentryLoaded(user));
  }
}

const sentryCapture = (err: string) =>
  process.env.NODE_ENV === 'production' && window.Sentry && window.Sentry.captureException(err);

export { SentryInit, sentryCapture };
