// @flow
import type { SharePayload, ShareType } from 'types/views/share';

const getUtmParams = (content: string, url: string, campaign: string, encode?: boolean = true) => {
  if (!campaign) return '';

  const separator = typeof url === 'string' && url.includes('?') ? '&' : '?';
  const utmParams = `${separator}utm_source=web&utm_medium=nativeshare&utm_campaign=${campaign}&utm_content=${content}`;

  return encode ? encodeURIComponent(utmParams) : utmParams;
};

const share = (type: ShareType, sharePayload: SharePayload) => {
  let url;

  const { utm_campaign } = sharePayload;
  const utmParams = getUtmParams(type, sharePayload.url, utm_campaign);
  switch (type) {
    case 'facebook':
      url = `https://www.facebook.com/sharer/sharer.php?u=${
        sharePayload.encodedUrl
      }${utmParams}&quote=${encodeURIComponent(sharePayload.text)}`;
      break;

    case 'twitter':
      url = `https://twitter.com/intent/tweet?text=${sharePayload.encodedText}&url=${
        sharePayload.encodedUrl
      }${utmParams}&via=novelship&hashtags=${sharePayload.encodedHashtags || ''}`;
      break;

    // instagram doesn't allow, so open inbox page only
    case 'instagram':
      url = `https://www.instagram.com/direct/inbox`;
      break;

    case 'line':
      url = `https://line.me/R/msg/text/?${sharePayload.encodedText}. ${sharePayload.urlText}${utmParams}`;
      break;

    case 'email':
      url = `mailto:?subject=${document.title}&body=${sharePayload.encodedText}%20${sharePayload.urlText}${utmParams}`;
      break;

    case 'sms':
      url = `sms:&body=${sharePayload.encodedText}%20${sharePayload.urlText}${utmParams}`;
      break;

    case 'whatsapp':
      url = `https://api.whatsapp.com/send?text=${sharePayload.encodedText}%20${sharePayload.urlText}${utmParams}`;
      break;

    default:
      return null;
  }

  const win = window.open(url, '_blank');
  if (win) return win.focus();
};

export { share, getUtmParams };
