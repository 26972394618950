// @flow
import { navigate } from '@reach/router';
import { parseQuery, buildQueryString } from '.';

type AuthParams = {
  page?: 'login' | 'signup',
  mode?: 'clean' | 'buy' | 'sell',
  productSlug?: string,
  signup_reference?: string,
  referral_code?: string,
  email?: string,
};
const defaultAuthParams = {
  page: 'signup',
  mode: '',
  productSlug: '',
  signup_reference: '',
  referral_code: '',
  email: '',
};

/**
 * @param {('login' | 'signup')} [page='signup']
 * @param {('clean' | 'buy' | 'sell')} [mode]: clean => no redirectTo
 * @param {string} [productSlug]
 * @returns
 */
function getUserAuthPath({
  page = 'signup',
  mode = '',
  productSlug = '',
  signup_reference = '',
  referral_code = '',
  email,
}: AuthParams = defaultAuthParams) {
  let redirectTo = window.location.pathname + window.location.search;

  if (mode === 'clean') {
    return `/${page}`;
  }

  if (/login|signup/.test(redirectTo)) {
    redirectTo = parseQuery().redirectTo || '/';
  }

  let query = `?redirectTo=${redirectTo}`;
  if (email) {
    query += `&email=${email}`;
  }

  if (signup_reference) {
    query = `${query}&signup_reference=${signup_reference}`;
  }
  if (referral_code) {
    query = `${query}&referral_code=${referral_code}`;
  }

  if (productSlug && mode) {
    return `/${productSlug}/${mode}/${page}${query}`;
  }
  return `/${page}${query}`;
}

/**
 * @param {('login' | 'signup')} [page='signup']
 * @param {('clean' | 'buy' | 'sell')} [mode]: clean => no redirectTo
 * @param {string} [productSlug]
 * @returns
 */
function goToUserAuth({
  page = 'signup',
  mode = '',
  productSlug = '',
  signup_reference = '',
  referral_code = '',
  email = '',
}: AuthParams = defaultAuthParams) {
  return navigate(
    getUserAuthPath({
      page,
      mode,
      productSlug,
      signup_reference,
      referral_code,
      email,
    })
  );
}

const redirectAfterAuth = () => {
  const redirectTo = parseQuery().redirectTo || '/';

  // safeguards against `javascript:code` and `https://url`. todo: handle more cases
  if (/javascript|http|:/.test(redirectTo.toLowerCase())) return;

  navigate(redirectTo, { replace: true });
};

// --------------------------
/**
// @@ getRoutePath
// Input                    Current-Path             Output
// summary  -               /slug/buy/review         /slug/buy/summary
// profile/email            profile/shipping         profile/email
//
 * @param {string} path: new path
 * @param {*} query: new query
 * @param {boolean} [dropQuery=false] remove current query in url?
 * @returns
 */
const getRoutePath = (
  path: string,
  options?: { query?: any, dropQuery?: boolean, replace?: boolean } = {}
) => {
  let { pathname } = window.location;
  let newQuery = buildQueryString({ ...parseQuery(), ...options.query });
  pathname = pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname;
  const nextPath = path[0] === '/' ? path.substr(1) : path;
  const paths = nextPath.split('/');

  if (options.dropQuery) {
    newQuery = '';
  } else if (newQuery !== '') {
    newQuery = `?${newQuery}`;
  }

  let startPath;
  if (pathname.indexOf(`/${paths[0]}`) > -1) {
    startPath = pathname.slice(0, pathname.lastIndexOf(paths[0]));
  } else {
    startPath = `${pathname.slice(0, pathname.lastIndexOf('/'))}/`;
  }

  return startPath + nextPath + newQuery;
};

const navigateToRoute = (
  path: string,
  options?: { query?: any, dropQuery?: boolean, replace?: boolean } = {}
) => navigate(getRoutePath(path, options), { replace: !!options.replace });

export { getUserAuthPath, goToUserAuth, getRoutePath, redirectAfterAuth, navigateToRoute };
