// @flow
import type { UserType } from 'types/resources/user';
import type { ProductType } from 'types/resources/product';
import type { TransactionType } from 'types/resources/transaction';
import type { OfferListType } from 'types/resources/offerList';
import type { PromocodeType } from 'types/resources/promocode';
import type { CurrencyType } from 'types/resources/currency';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { lowerArray } from 'utils/index';
import { getBuyItem, getProductItem, getPromocodeInfo } from './utils';
import firebaseApp from './init';

import type { TrackingProperties } from '../product';
import { mapUrlToSiteType } from '../utils';

interface BuyType extends TransactionType, OfferListType {}

class FirebaseAnalyticsManager {
  analytics = getAnalytics(firebaseApp);

  currency: string;

  initialLandUrl = window.location.href;

  logEvent(eventName: string, eventParams: { [key: string]: any } = {}): void {
    const initialLand = this.initialLandUrl ? mapUrlToSiteType(this.initialLandUrl) : undefined;
    const eventWithInitialLand = {
      ...eventParams,
      ...(initialLand && { initial_land: initialLand }),
    };

    logEvent(this.analytics, eventName, eventWithInitialLand);
  }

  setContext({ currency }: { currency: CurrencyType }) {
    this.currency = currency.code;
  }

  setUserId = (id: string) => setUserId(this.analytics, id);

  logLogin = (method: 'email' | 'facebook' | 'google' | 'apple') =>
    this.logEvent('login', { method });

  logout = () => setUserId(this.analytics, null);

  logSignUp = (method: 'email' | 'facebook' | 'google' | 'apple') =>
    this.logEvent('sign_up', { method });

  logSearch = (searchTerm: string) => this.logEvent('search', { search_term: searchTerm });

  logViewItem = (product: ProductType, price: number) =>
    this.logEvent('view_item', {
      currency: this.currency,
      value: price,
      items: [{ ...getProductItem(product), price }],
    });

  logAddToCart = (product: ProductType, buy: BuyType, trackingData: TrackingProperties) =>
    this.logEvent('add_to_cart', {
      currency: this.currency,
      value: Number(trackingData['Product Price']),
      items: [
        {
          ...getProductItem(product),
          ...getBuyItem(buy),
          item_variant: String(trackingData.Size),
          price: Number(trackingData['Product Price']),
        },
      ],
    });

  logRemoveFromCart = (product: ProductType, buy: BuyType) => {
    const buyItem = getBuyItem(buy);

    this.logEvent('remove_from_cart', {
      currency: this.currency,
      value: buyItem.price,
      items: [{ ...getProductItem(product), ...buyItem }],
    });
  };

  logBeginCheckout = (product: ProductType, buy: BuyType) => {
    const buyItem = getBuyItem(buy);

    this.logEvent('begin_checkout', {
      currency: this.currency,
      value: buyItem.price,
      items: [{ ...getProductItem(product), ...buyItem }],
      ...(buy.promocode && { coupon: buy.promocode }),
    });
  };

  logPurchase = ({
    product,
    buy,
    user,
  }: {
    product: ProductType,
    buy: BuyType,
    user?: UserType,
  }) => {
    const buyItem = getBuyItem(buy);

    const isUserFirstPurchase = !(user && lowerArray(user.groups).includes('has-purchase'));
    const purchaseParams = {
      currency: this.currency,
      value: buyItem.price,
      items: [{ ...getProductItem(product), ...buyItem }],
      ...((buy.promocode_id || buy.promocode) && {
        coupon: String(buy.promocode_id || buy.promocode),
      }),
      shipping: (buy.isOffer ? buy.fees.delivery : buy.fee_delivery) || 0,
      transaction_id: String(buy.ref || buy.id),
      customer_type: isUserFirstPurchase ? 'New' : 'Returning',
    };
    if (isUserFirstPurchase) {
      this.logEvent('first_purchase', purchaseParams);
    }
    this.logEvent('purchase', purchaseParams);
  };

  logSelectPromotion = (product: ProductType, promocode: PromocodeType, buy: BuyType) =>
    this.logEvent('select_promotion', {
      ...getPromocodeInfo(promocode),
      items: [{ ...getProductItem(product), ...getBuyItem(buy) }],
    });

  logViewPromotion = (product: ProductType, buy: BuyType, promocode: PromocodeType) => {
    if (!(promocode && promocode.id)) return;

    this.logEvent('view_promotion', {
      ...getPromocodeInfo(promocode),
      items: [{ ...getProductItem(product), ...getBuyItem(buy) }],
    });
  };

  logAddToWishlist = (product: ProductType) =>
    this.logEvent('add_to_wishlist', {
      currency: this.currency,
      value: 0,
      items: [{ ...getProductItem(product) }],
    });
}

const FirebaseAnalytics = new FirebaseAnalyticsManager();
export default FirebaseAnalytics;
