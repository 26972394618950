// @flow
import * as React from 'react';
import { Redirect } from '@reach/router';
import { useStoreState } from 'easy-peasy';

type Props = {
  as: React.ComponentType<any>,
};

const PrivateRoute = (props: Props) => {
  const isAuthenticated = useStoreState(s => s.user.isAuthenticated);
  const { as: Component, ...restProps } = props;

  const redirectTo = `/login?redirectTo=${window.location.pathname + window.location.search}`;

  return isAuthenticated ? <Component {...restProps} /> : <Redirect to={redirectTo} noThrow />;
};

export default PrivateRoute;
