// @flow
import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Box, Flex, Text, Button } from 'components/base';
import { isMobile } from 'utils';
import { Theme } from 'styles';
import SiteWideTopTicker from 'components/Layout/SiteWideTopTicker';

import RecentSearches from './RecentSearches';
import SearchResults from './SearchResults';
import SearchBar from './components/SearchBar';

const SearchSidebar = ({
  isSearchSidebarVisible,
  toggleSearchSidebar = () => {},
}: {|
  isSearchSidebarVisible: boolean,
  toggleSearchSidebar: () => void,
|}) => {
  const { search } = useStoreState(s => s.search);

  const showTicker = document.documentElement && document.documentElement.scrollTop <= 26;

  return (
    isSearchSidebarVisible && (
      <>
        <SearchSidebarStyled width={[1, 1, 525, 525]}>
          {showTicker && (isMobile ? <SiteWideTopTicker /> : <Box height={[26, 26, 28]} />)}
          <Flex px={5} py={['5px', '7px', '9px', 14]} justifyContent="space-between">
            <SearchBar toggleSearchSidebar={toggleSearchSidebar} />
            <Button ml={5} variant="clear" onClick={toggleSearchSidebar}>
              <Text textDecoration="underline" fontWeight="bold">
                <Trans>CANCEL</Trans>
              </Text>
            </Button>
          </Flex>
          <Box className="h-divider" />
          <Box
            height={[
              'calc(100vh - 72px)',
              'calc(100vh - 76px)',
              'calc(100vh - 82px)',
              'calc(100vh - 106px)',
            ]}
            style={{ overflowY: 'auto' }}
          >
            {search ? (
              <SearchResults closeSearchSidebar={toggleSearchSidebar} />
            ) : (
              <RecentSearches />
            )}
          </Box>
        </SearchSidebarStyled>

        <Overlay onClick={toggleSearchSidebar} />
      </>
    )
  );
};

const SearchSidebarStyled = styled(Box)`
  background-color: ${Theme.colors.white};
  z-index: 1000000;
  position: fixed;
  height: 100vh;
  right: 0;
  top: 0;
`;

const Overlay = styled(Box)`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 9;
  right: 0;
  top: 0;
`;

export default SearchSidebar;
