// @flow
import type { CountryType } from 'types/resources/country';
import type { BaseType } from 'types/resources/base';
import type { LanguageType } from 'store/constants/language';
import type { SellerFeeType } from 'types/resources/sellerFee';

import { defaultCountry } from 'types/resources/country';
import { defaultSellerFee } from 'types/resources/sellerFee';

export interface AddressType {
  firstname: string;
  lastname: string;
  line_1: string;
  line_2: string;
  line_3: string;
  city: string;
  state: string;
  zip: string;
  country_code: string;
  phone: string;
  is_remote_area: boolean;
  country: CountryType;
}

const defaultAddress: AddressType = {
  firstname: '',
  lastname: '',
  line_1: '',
  line_2: '',
  line_3: '',
  city: '',
  state: '',
  zip: '',
  country_code: '',
  phone: '',
  is_remote_area: false,
  country: defaultCountry,
};

export type StripeCardType = {
  id: string,
  brand:
    | 'Visa'
    | 'MasterCard'
    | 'American Express'
    | 'Discover'
    | 'JCB'
    | 'Diners Club'
    | 'Unknown'
    | '',
  exp_month?: number,
  exp_year?: number,
  last4: string,
  customer?: string,
  funding?: string,
  country?: string,
};

export type AdyenCardType = {
  brand: string,
  cardSummary: string,
  expiryDate: string,
  fundingSource: string,
};

type PayoutType = {
  account_type?: string,
  account_number?: string,
  bank_name?: string,
  bank_country?: string,
  dob?: string,
  branch_code?: string,
  bsb_code?: string,
  branch_name?: string,
  katakana_name?: string,
  chinese_name?: string,
  corporate_id?: string,
};

type AccountManagerType = {
  id: number,
  name: string,
  email: string,
  code: string,
};

export type SignupMethodType = 'email' | 'facebook' | 'google' | 'apple';

export interface SizePreferencesType {
  Sneakers?: {
    category: 'men' | 'women' | 'youth' | 'pre school' | 'toddler' | 'infant',
    unit: 'US' | 'UK' | 'EU' | 'JP',
    brand?: string,
    size: string,
    eu_size: string,
    us_size: string,
  };
  Apparel?: { size: 'XS' | 'S' | 'M' | 'L' | 'XL' };
}

type PowerSellerStatsType = {
  tier?: string,
  tier_last_updated_at?: string | Date,
  current_period_started_at: string | Date,
  penalty_fee_waiver_count?: number,
  return_shipping_fee_waiver_count?: number,
};

type OnboardingTaskType = {
  tell_us_more_about_you: boolean,
  add_3_products_to_wishlist: boolean,
  turn_on_notification_and_email_optin: boolean,
  download_novelship_app: boolean,
};

export type ShippingStatsType = {
  to_ship_count: number,
  shipping_count: number,
  unfulfilled_count: number,
  to_ship_delayed_rate: number,
  to_ship_time_avg: number,
  has_to_ship: boolean,
};
export interface UserType extends BaseType {
  ref: string;
  // Ids
  country: CountryType;
  billing_country: CountryType;
  selling_country: CountryType;
  shipping_country: CountryType;

  country_id?: number;
  billing_country_id?: number;
  selling_country_id?: number;
  shipping_country_id?: number;

  seller_type?: 'power-seller';

  // New Seller Fee
  seller_fee_id: number;
  seller_fee: SellerFeeType;

  // Basic Information
  firstname?: string;
  lastname?: string;
  fullname?: string;
  username?: string;
  email: string;
  avatar?: string;
  dob?: string;
  size_preferences: SizePreferencesType;
  type?: 'sell' | 'buy' | 'both';
  locale: LanguageType;

  // Contact Information
  address: AddressType;
  billing_address: AddressType;
  selling_address: AddressType;
  shipping_address: AddressType;
  country_code?: string;
  phone?: string;

  // Social
  facebook?: string;
  google?: string;
  apple?: string;
  instagram?: string;

  // Status Properties
  role?: 'admin';
  email_verified: boolean;
  status: 'active' | 'vacation' | 'banned';

  // Settings
  notification_preferences: {
    email: boolean,
    push: boolean,
    promotions: { push: boolean, email: boolean },
    list_expiring: { push: boolean },
    offer_expiring: { push: boolean },
    sale_updates: { push: boolean },
    buyer_new_lowest_list: { push: boolean, email: boolean },
    seller_new_lowest_list: { push: boolean, email: boolean },
    wishlist_new_lowest_list: { push: boolean, email: boolean },
    wishlist_instant_delivery_available: { push: boolean, email: boolean },
    wishlist_new_highest_offer: { push: boolean },
    buyer_new_highest_offer: { push: boolean, email: boolean },
    seller_new_highest_offer: { push: boolean, email: boolean, threshold: number },
  };
  payout_info: PayoutType;

  // Promos, Credits & Points
  referred_users: number[];
  referral_code: string;
  referred_by?: number;
  promocodes: string[];
  favorite_brands: string[];
  signup_reference?: string;
  registrationId?: string;
  groups: string[];
  points: number;

  // Third Party Integrations
  stripe_buyer: StripeCardType;
  stripe_seller: StripeCardType;

  adyen_buyer: AdyenCardType;
  adyen_seller: AdyenCardType;

  // Campaign & Event Specific
  raffle: any[];
  checkin: any[];
  // referral?: any;

  hasBuyCard: boolean;
  hasBuyCardAndEnabled: boolean;
  hasSellCard: boolean;
  hasPayout: boolean;
  hasDelivery: boolean;
  hasSellingAddress: boolean;
  hasShippingAddress: boolean;
  buying_card_disabled: boolean;
  sell_enabled: boolean;
  isOnboardingTaskEligible: boolean;
  canLogin?: boolean;

  // Onboarding Task
  onboarding_task?: OnboardingTaskType;

  // Power Seller Perks/Stats
  power_seller_stats?: PowerSellerStatsType;
  seller_fee_new_start_at?: string | Date;

  // Account Manager
  account_manager?: AccountManagerType;

  shipping_stats: ShippingStatsType;

  // client only
  isAdmin?: boolean;
  showPowerSellerFeature?: boolean;
  refereeEligible?: boolean;
  firstTimePromocodeEligible?: boolean;
  sneakerSize?: string;
  teeSize?: string;
  showTrustpilotWidget: boolean;
}

const anonymousUser: UserType = {
  // $FlowFixMe
  id: undefined, // also used to check if user is logged in or not
  ref: '',
  email: '',
  country: defaultCountry,
  billing_country: defaultCountry,
  selling_country: defaultCountry,
  shipping_country: defaultCountry,
  // $FlowFixMe
  locale: undefined,

  seller_fee_id: 0,
  seller_fee: defaultSellerFee,

  size_preferences: {},
  address: defaultAddress,
  billing_address: defaultAddress,
  selling_address: defaultAddress,
  shipping_address: defaultAddress,

  email_verified: false,
  status: 'active',

  stripe_buyer: {
    id: '',
    brand: '',
    last4: '',
  },
  stripe_seller: {
    id: '',
    brand: '',
    last4: '',
  },
  adyen_buyer: {
    brand: '',
    cardSummary: '',
    expiryDate: '',
    fundingSource: '',
  },
  adyen_seller: {
    brand: '',
    cardSummary: '',
    expiryDate: '',
    fundingSource: '',
  },
  notification_preferences: {
    push: false,
    email: true,
    promotions: { push: true, email: true },
    sale_updates: { push: true },
    list_expiring: { push: true },
    offer_expiring: { push: true },
    buyer_new_lowest_list: { push: true, email: true },
    wishlist_new_lowest_list: { push: true, email: true },
    wishlist_instant_delivery_available: { push: true, email: true },
    wishlist_new_highest_offer: { push: true },
    seller_new_lowest_list: { push: true, email: true },
    buyer_new_highest_offer: { push: true, email: true },
    seller_new_highest_offer: { push: true, email: true, threshold: 85 },
  },
  payout_info: {},
  shipping_stats: {
    to_ship_count: 0,
    shipping_count: 0,
    unfulfilled_count: 0,
    to_ship_delayed_rate: 0,
    to_ship_time_avg: 0,
    has_to_ship: false,
  },

  groups: [],
  referred_users: [],
  points: 0,
  promocodes: [],
  favorite_brands: [],
  referral_code: '',

  raffle: [],
  checkin: [],

  hasBuyCard: false,
  hasBuyCardAndEnabled: false,
  hasSellCard: false,
  hasPayout: false,
  hasDelivery: false,
  hasSellingAddress: false,
  hasShippingAddress: false,
  buying_card_disabled: false,
  sell_enabled: false,
  showTrustpilotWidget: false,
  isOnboardingTaskEligible: false,

  getEventCheckins: () => undefined,
};

export { anonymousUser };
