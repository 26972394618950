// @flow
import { useEffect } from 'react';

const usePrerenderStyles = () => {
  useEffect(() => {
    let timeoutId;
    if (window.isPrerender) {
      timeoutId = setTimeout(() => {
        const el = document.createElement('style');
        // $FlowFixMe
        document.head.appendChild(el);
        const styles = document.querySelectorAll('style[data-styled]');
        for (const style of styles.values()) {
          // $FlowFixMe
          for (const rule of style.sheet.rules) {
            el.appendChild(document.createTextNode(rule.cssText));
          }
        }
        window.prerenderReady = true;
      }, 1000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);
};

export default usePrerenderStyles;
