// @flow
import React from 'react';
import { Box, Text, Flex, Image } from 'components/base';
import { IoMdStar, IoMdStarHalf } from 'react-icons/io';
import { Trans } from '@lingui/macro';
import { getImgixUrl } from 'constants/constants';

const REVIEWS_IO_LOGO_URI = getImgixUrl('icons/reviewsio-logo--inverted.svg');
const REVIEWS_IO_URL = 'https://www.reviews.io/company-reviews/store/novelship-com';

const ReviewsIOWidget = ({ ...props }: {}) => {
  const REVIEW_RATING = 4.2;
  const TOTAL_REVIEWS = 100;
  return (
    <a href={REVIEWS_IO_URL}>
      <Box {...props}>
        <Box bg="white" height="90%" p={2} style={{ borderRadius: 8 }}>
          <Flex alignItems="center" justifyContent="space-evenly" mb={1}>
            <IoMdStar color="black" size={19} />
            <IoMdStar color="black" size={19} />
            <IoMdStar color="black" size={19} />
            <IoMdStar color="black" size={19} />
            <IoMdStarHalf color="black" size={19} />
          </Flex>
          <Flex justifyContent="center" alignItems="flex-end" mt={1}>
            <Text
              color="black"
              fontWeight="bold"
              fontSize="15px"
              mr={1}
              height={20}
              lineHeight="20px"
            >
              {REVIEW_RATING}
            </Text>
            <Text color="black" fontSize={11} height={20} lineHeight="21px">
              out of 5
            </Text>
          </Flex>
          <Flex center mt="2px">
            <Text
              color="black"
              fontWeight="bold"
              fontSize="15px"
              mr={1}
              height={20}
              lineHeight="20px"
            >
              {TOTAL_REVIEWS}
            </Text>
            <Text color="black" fontSize={11} height={20} lineHeight="21px">
              <Trans>Reviews</Trans>
            </Text>
          </Flex>
        </Box>
        <Flex center bg="black" p={2}>
          <Image src={REVIEWS_IO_LOGO_URI} height={15} />
        </Flex>
      </Box>
    </a>
  );
};

export default ReviewsIOWidget;
