// @flow
import type { ProductClassType } from 'types/resources/product';
import type { ViewedProductType, UserViewedProductType } from 'types/resources/userViewedProduct';
import { ProductClassEnums } from 'types/resources/product';
import { cacheGet, cacheSet } from 'utils/localStorageCache';
import { API } from 'api';
import { isAuthenticated } from 'utils/user';
import { uniqBy } from 'utils';

class UserViewedProduct {
  cacheKey = `viewed_products_`;

  merge = (viewedProducts: $Shape<ViewedProductType>[]): ViewedProductType[] => {
    const cleaned = viewedProducts
      .filter(p => Number.isInteger(p.product_id))
      .map<ViewedProductType>(p => ({
        product_id: parseInt(p.product_id, 10),
        timestamp: parseInt(p.timestamp, 10) || Date.now(),
      }));

    const sorted = cleaned.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));

    return uniqBy(sorted, 'product_id').slice(0, 10);
  };

  cacheGet(productClass: ProductClassType) {
    const cached = cacheGet(`${this.cacheKey}${productClass}`, []);

    if (Array.isArray(cached)) return cached;
    return this.cacheSet([], productClass);
  }

  cacheSet(viewedProduct: ViewedProductType[], productClass: ProductClassType) {
    return cacheSet(`${this.cacheKey}${productClass}`, viewedProduct);
  }

  add(product: { id: number, class: ProductClassType }) {
    const productClass = product.class;
    const currentViewedProducts = this.cacheGet(productClass);
    const mergedViewedProducts = this.merge([{ product_id: product.id }, ...currentViewedProducts]);
    this.cacheSet(mergedViewedProducts, productClass);

    if (isAuthenticated()) {
      API.post('me/viewed-products', {
        products: mergedViewedProducts,
        product_class: productClass,
      }).then((viewedProducts: { results: UserViewedProductType }) =>
        this.cacheSet(viewedProducts.results.products, productClass)
      );
    }
  }

  syncAll() {
    if (!isAuthenticated()) return;

    API.post(
      'me/viewed-products/sync',
      ProductClassEnums.map(c => ({
        product_class: c,
        products: this.cacheGet(c),
      }))
    ).then((viewedProducts: { results: UserViewedProductType[] }) =>
      viewedProducts.results.forEach(p => this.cacheSet(p.products, p.product_class))
    );
  }
}

const UserViewedProductService = new UserViewedProduct();

export default UserViewedProductService;
