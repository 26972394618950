// @flow
import styled from 'styled-components';
import { Theme } from 'styles';
import { layout, compose } from 'styled-system';

const commonStyles = `
  transition: background 0.06s ease-in, padding 0.05s ease;
  font-size: 16px;
  width: 100%;
  border-radius: 0;
  border: 1px solid ${Theme.colors.gray6};
  color: ${Theme.colors.black3};
  letter-spacing: 0.1px;
  appearance: none;

  &:disabled {
    color: ${Theme.colors.gray1};
  }

  ::placeholder {
    color: ${Theme.colors.gray3};
    font-size: 15px;
  }

  &:focus {
    border-color: ${Theme.colors.gray5}!important;
  }
`;

const InputStyled = styled.input`
  ${commonStyles}
  padding: 0 12px;
  height: 50px;

  &.xs {
    height: 24px;
    padding: 6px 8px;
  }

  &.md {
    height: 36px;
  }

  &.error {
    border-color: #ff9194 !important;
  }

  &:focus {
    border-color: ${Theme.colors.gray5}!important;
  }

  &[type='checkbox'],
  &[type='radio'] {
    display: block;
    padding: 0;
    width: 18px;
    height: 18px;
    min-height: 18px;
    min-width: 18px;
    border: 2px solid ${Theme.colors.gray1};
    border-radius: 2px;

    &:disabled {
      border: 2px solid ${Theme.colors.gray3};
      &:hover {
        cursor: not-allowed;
      }
    }

    &.error {
      animation: shake_ns 0.7s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }

    &:checked {
      background: ${Theme.colors.gray1};
      &:after {
        content: url("data:image/svg+xml,%3Csvg width='12' height='11' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.92473 1.7002L3.97487 6.65005L1.5 4.17518' stroke='white' stroke-width='2'/%3E%3C/svg%3E");
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }

      &.blue {
        background: ${Theme.colors.blue};
      }
    }

    &.round {
      border-width: 2px;
      border-radius: 50%;
      background: ${Theme.colors.white};
      position: relative;
      &:checked:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 10px;
        height: 10px;
        background-color: ${Theme.colors.gray1};
        border-radius: 50%;
      }
    }

    &:checked:not(.round) {
      border: none;
    }

    &:focus {
      border-color: ${Theme.colors.gray1}!important;
    }

    &:hover:not(:checked) {
      background: ${Theme.colors.gray6};
    }
  }
`;

const Input = styled(InputStyled)(compose(layout));
const Textarea = styled.textarea`
  ${commonStyles}
  padding: 12px;
  outline: none;
`;

export { Input, Textarea };
