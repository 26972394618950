// @flow

import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { Select } from 'components/form';
import { currentLanguage, setLanguage, LANGUAGES } from '../../store/constants/language';

const LanguageSelector = (props: any) => {
  const isAuthenticated: boolean = useStoreState(s => s.user.isAuthenticated);
  const update = useStoreActions(a => a.user.update);

  const switchAppLocale = e => {
    const locale = e.target.value;
    if (isAuthenticated) {
      return update({ locale }).then(() => {
        setLanguage(locale);
      });
    }
    setLanguage(locale);
  };

  return (
    <Select id="language" value={currentLanguage} onChange={switchAppLocale} isLanguage {...props}>
      {Object.entries(LANGUAGES).map(l => (
        <option key={l[0]} value={l[0]}>
          {/* $FlowFixMe */}
          {l[1].name}
        </option>
      ))}
    </Select>
  );
};

export default LanguageSelector;
