// @flow
import { mediaQuery, Theme } from 'styles';
import styled from 'styled-components';

const DialogHeader = styled.header`
  background: ${Theme.colors.black2};
  color: white;
  text-transform: uppercase;
  text-align: center;
  line-height: 56px;
  font-size: 20px;
  height: 56px;
  font-weight: bold;
  letter-spacing: 0.2px;
  ${mediaQuery.xs`
    font-size: 14px;
  `}
`;

export default DialogHeader;
