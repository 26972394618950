// @flow
import environment from 'constants/environment';

const CATEGORY_LEVELS = [
  'class',
  'category_level_1',
  'category_level_2',
  'category_level_3',
  'category_level_4',
];

const s3ImageBucketUrl = `https://s3-ap-southeast-1.amazonaws.com/${environment.s3.imagesBucket}`;
const s3MainBucketUrl = `https://s3-ap-southeast-1.amazonaws.com/${environment.s3.mainBucket}`;
const imgixUrl = process.env.REACT_APP_IMGIX_URL || '';

const getImgixUrl = (path: string, fullQuality?: boolean) => {
  const quality = fullQuality ? `q=85&auto=format,compress` : `auto=format,compress`;

  return `${imgixUrl}${path.replace(imgixUrl, '').replace('https://ns.imgix.net/', '')}${
    path.includes('?') ? '&' : '?'
  }${quality}`;
};

const getDprSrcset = (image: string) => `${image} 1x, ${image}&dpr=2 2x`;

const productImageImgixQuery = '?fit=fill&bg=FFFFFF&trim=color&auto=format,compress&q=75';

const currentRouteWithQuery = () => window.location.href.replace(window.location.origin, '');

const phoneNumberLength = {
  '+65': 8,
  '+852': 8,
  '+60': 9,
  '+886': 9,
  '+1': 10,
  '+81': 11,
  '+82': 11,
};

const userInfoLength = {
  firstname: 20,
  lastname: 20,
  line_1: 35,
  line_2: 35,
  line_3: 35,
  city: 20,
  state: 20,
  line_4: 35,
  line_5: 35,
  line_6: 35,
  city2: 20,
  state2: 20,
};
const minUserInfoLength = { city: 3, city2: 3 };
const userAddressMap = {
  firstname: 'firstname2',
  lastname: 'lastname2',
  line_1: 'line_4',
  line_2: 'line_5',
  line_3: 'line_6',
  city: 'city2',
  state: 'state2',
  zip: 'zip2',
  phone: 'phone2',
  country_code: 'country_code2',
};

const MIL_SECS_IN_DAY = 24 * 60 * 60 * 1000;
const CACHE_TIME = {
  short: 5 * 60 * 1000,
  long: 15 * 60 * 1000,
};

const APP_STORE_URL = {
  ios: 'https://apps.apple.com/app/apple-store/id1553741278?pt=122688706&ct=website-promotion&mt=8',
  android:
    'https://play.google.com/store/apps/details?id=com.novelship.novelship&referrer=utm_source%3Dwebsite-promotion',
};

const ZIP_CODE_REGEX = {
  AU: /^\d{4}$/,
  BN: /^([A-Z]{2}\d{4})$/,
  CA: /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])[ ]?(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/,
  CN: /^\d{6}$/,
  FR: /^\d{5}$/,
  DE: /^\d{5}$/,
  HK: '',
  IN: /^\d{6}$/,
  ID: /^\d{5}$/,
  JP: /^\d{3}-\d{4}$/,
  MY: /^\d{5}$/,
  MM: /^\d{5}$/,
  NL: /^\d{4}[ ]?([A-Z]{2})?$/,
  NZ: /^\d{4}$/,
  PH: /^\d{4}$/,
  SG: /^\d{6}$/,
  KR: /^\d{5}$/,
  TW: /^\d{3}(\d{2})?$/,
  TH: /^\d{5}$/,
  AE: '',
  GB: /^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/,
  US: /^\d{5}(-\d{4})?$/,
  VN: /^\d{6}$/,
};

export const ALPHA_NUM_ZIP_COUNTRY = ['BN', 'CA', 'GB', 'NL'];

const PRODUCT_COLLECTIONS = {
  TRENDING_DEALS: 'trending-deals',
};

export {
  CATEGORY_LEVELS,
  CACHE_TIME,
  imgixUrl,
  s3MainBucketUrl,
  s3ImageBucketUrl,
  getImgixUrl,
  getDprSrcset,
  userInfoLength,
  minUserInfoLength,
  userAddressMap,
  phoneNumberLength,
  productImageImgixQuery,
  currentRouteWithQuery,
  MIL_SECS_IN_DAY,
  APP_STORE_URL,
  ZIP_CODE_REGEX,
  PRODUCT_COLLECTIONS,
};
