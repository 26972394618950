/* eslint-disable prefer-destructuring */
// @flow
import type { UserType } from 'types/resources/user';
import { action, thunk, computed, Computed, Thunk, Action } from 'easy-peasy';

import { API } from 'api';
import { anonymousUser } from 'types/resources/user';
import { cacheGet, cacheSet } from 'utils/localStorageCache';
import { logoutUser, getUserSignupAndLoginMethodName, GetUserWithDefaults } from 'utils/user';
import Analytics from 'services/analytics';
import UserViewedProductService from 'services/userViewedProduct';
import { lowerArray } from 'utils/index';

export type EmailPassword = {
  email: string,
  password: string,
  csrf?: string,
  otp?: string,
};

interface UserStoreType {
  user: UserType;
  isAuthenticated: Computed<UserStoreType, boolean>;
  set: Action<UserStoreType, UserType>;
  fetch: Thunk<UserStoreType>;
  update: Thunk<UserStoreType, UserType>;
  signup: Thunk<UserStoreType, UserType & EmailPassword>;
  login: Thunk<UserStoreType, EmailPassword>;
  logout: Thunk<UserStoreType>;
  authorize: Thunk<UserStoreType, string>;
  onAuthorize: Thunk<UserStoreType, { token: string }>;
}

// cleanup selling_fee include, get fee from store
const fetchUser = () => API.fetch('me');

const onUserLogin = user => {
  Analytics.login(user, getUserSignupAndLoginMethodName(user));
  UserViewedProductService.syncAll();

  // partner code validation
  const partnercode = cacheGet('partner_code');
  if (partnercode && !lowerArray(user.groups).includes(partnercode.toLowerCase())) {
    API.post('me/partner_promotion/validate', {
      code: partnercode,
      country_id: user.shipping_country_id || user.country_id,
    }).catch(err => console.log(err));
  }
  return user;
};

const isAuthenticated = () => !!cacheGet('token');

const UserStore: UserStoreType = {
  user: anonymousUser,
  isAuthenticated: computed(isAuthenticated),
  set: action((store, user) => {
    const _user = GetUserWithDefaults(user);
    store.user = _user;
  }),
  fetch: thunk(actions =>
    fetchUser()
      .catch(err => Promise.reject(err))
      .then(user => {
        actions.set(user);
        return user;
      })
      .catch(() => process.env.NODE_ENV === 'production' && actions.logout())
  ),
  update: thunk((actions, payload) =>
    API.put('me', payload).then(data => (data?.otpSent ? data : actions.fetch()))
  ),
  onAuthorize: thunk((actions, { token }) => {
    cacheSet('token', token);
    return actions.fetch().then(onUserLogin);
  }),
  login: thunk(async (actions, payload) => {
    const recaptcha = window.grecaptcha;
    if (!recaptcha) return;

    const verifyToken = await recaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {
      action: 'login',
    });

    const options = { headers: { 'x-verify': verifyToken } };
    return API.post('auth/login', payload, options).then(res =>
      res?.otpSent ? res : actions.onAuthorize(res)
    );
  }),
  authorize: thunk((actions, shortToken) => {
    cacheSet('token', shortToken);
    return API.post('auth/authorize').then(actions.onAuthorize);
  }),
  signup: thunk((actions, payload) =>
    API.post('auth/signup', payload).then(res =>
      res?.otpSent ? res : actions.login({ email: payload.email, password: payload.password })
    )
  ),
  logout: thunk(actions => {
    logoutUser();
    Analytics.logout();
    actions.set(anonymousUser);
  }),
};

export default UserStore;
