// @flow
// Don't flow type this file, issues with un typed dependencies
import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { system, compose } from 'styled-system';
import {
  Box as BaseBox,
  Card,
  Image,
  Heading,
  Text as BaseText,
  Flex as BaseFlex,
  Button as BaseButton,
} from 'rebass/styled-components';
import { Theme } from 'styles';
import Spinner from 'components/misc/Spinner';
import Table from './Table';
import Link from './Link';

const borderRadius = system({
  borderRadius: true,
});

const boxShadow = system({
  boxShadow: true,
});

const border = system({
  border: true,
});

const textTransform = system({
  textTransform: true,
});

const textDecoration = system({
  textDecoration: true,
});

const letterSpacing = system({
  letterSpacing: true,
});

const lineHeight = system({
  lineHeight: true,
});

const flexGrow = system({
  flexGrow: true,
});

const minHeight = system({
  minHeight: true,
});

const gap = system({ gap: true });

const StyledFlex = styled(BaseFlex)(
  compose(gap, border, boxShadow, textTransform, borderRadius, minHeight, flexGrow)
);

const Flex = (props: any) => {
  const { center, css } = props;

  return (
    <StyledFlex
      {...props}
      css={{
        ...css,
        ...(center && {
          alignItems: 'center',
          justifyContent: 'center',
        }),
      }}
    />
  );
};
Flex.propTypes = {
  center: PropTypes.bool,
};

type Props = {
  children?: React.Node,
  loading?: boolean,
  disabled?: boolean | number,
};
const Button = ({ children, loading, disabled, ...props }: Props) => (
  <ButtonStyle {...props} disabled={disabled || loading}>
    {loading ? <Spinner /> : children}
  </ButtonStyle>
);

const ButtonStyle = styled(BaseButton)`
  transition: background-color 0.06s ease;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  letter-spacing: 0.06em;
  padding: 0;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.fontSize === undefined && '14px'};
  font-weight: bold;
  border-radius: 4px;
  border-width: 2px;

  &.lg {
    padding: 4px 10px;
    height: 48px;
    font-size: 14px;
    line-height: 1.36;
    flex-wrap: wrap;
  }

  &.md {
    padding: 8px 12px;
    height: 36px;
    font-size: 14px;
  }

  &.sm {
    padding: 0 14px;
    height: 32px;
    font-size: 12px;
  }

  &.xs {
    padding: 0 8px;
    height: 24px;
    font-size: 10px;
    letter-spacing: 0.08em;
  }

  &.xs,
  &.sm {
    border-radius: 2px;
  }

  &.red {
    border-color: ${Theme.colors.red};
  }

  &.ghost {
    background-color: transparent;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${p => p.variant === 'icon' && `padding: 8px 12px;`}
  ${p => p.variant === 'clear' && `height: unset;`}
`;

const Box = styled(BaseBox)(compose(boxShadow, border, borderRadius));

const Chip = styled(Box)`
  text-transform: uppercase;
  letter-spacing: 0.06em;
  border-radius: ${props => props.borderRadius || '18px'};
  white-space: nowrap;
  font-weight: bold;
  padding: 2px 12px;
  font-size: ${props => props.fontSize || '10px'};
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.color};

  /* primary */
  color: ${props => props.textColor || 'white'};
  background-color: ${props => props.color};

  &.secondary {
    color: ${props => props.color};
    background-color: white;
  }
`;

const Text = styled(BaseText)(compose(textDecoration, textTransform, letterSpacing, lineHeight));

export { Box, Card, Image, Heading, Text, Flex, Button, Link, Table, Chip };
