// @flow
import type { UserType } from 'types/resources/user';

import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { QueryClientProvider } from 'react-query';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';

import { API, queryClient } from 'api';
import { setLanguage } from 'store/constants/language';
import { SentryInit } from 'services/sentry';
import initYellowMessenger from 'services/yellow-messenger';
import usePrerenderStyles from 'utils/hooks/usePrerenderStyles';
import ShareWidget from 'components/widgets/Share';
import getLocation from 'services/getLocation';
import Analytics from 'services/analytics';
import BaseSEO from 'seo/BaseSEO';
import Layout from 'components/Layout';
import 'services/url-query-cache';

import useGoogleOneTap from 'utils/hooks/useGoogleOneTap';
import Routes from './Routes';

const App = () => {
  const isAuthenticated: boolean = useStoreState(s => s.user.isAuthenticated);
  const user: UserType = useStoreState(s => s.user.user);
  const currentCurrencyId = useStoreState(s => s.currency.current.id);
  const selectedCountryId = useStoreState(s => s.context.selectedCountryId);

  const fetchUser = useStoreActions(a => a.user.fetch);
  const setCountries = useStoreActions(a => a.country.set);
  const setCurrencies = useStoreActions(a => a.currency.set);
  const setAppContext = useStoreActions(a => a.context.set);

  const [location, setLocation] = useState();
  const [context, setContext] = useState();

  usePrerenderStyles();
  useGoogleOneTap();

  const setupApp = (_location: any, _context: any, _user: UserType) => {
    setLanguage(_user && _user.locale);
    const {
      countries,
      currencies,
      promocodes,
      searchesTrending,
      payment_methods: paymentMethods,
      product_collections: productCollections,
      selling_fee_promotions: sellingFeePromotions,
      shipping_fee_promotions: shippingFeePromotions,
      delivery_fee_promotions: deliveryFeePromotions,
      cryptoRates,
      payoutCryptoConfig,
      promotionLoyaltyMultiplier,
      nsPayBuyBonusConfig,
      buyTrxnPaymentFee,
      buyTrxnGSTFee,
      deliveryProtectionBuyingFee,
      trustpilotConfig,
    } = _context;

    const detectedCountry = _location?.country
      ? countries.find(c => c.shortcode === _location.country)
      : null;

    const defaultCountry = countries.find(c => c.shortcode === 'US');

    const userCountryId = _user && (_user.shipping_country_id || _user.country_id);
    const userCountry = countries.find(c => c.id === userCountryId);

    const selectedCountry = selectedCountryId && countries.find(c => c.id === selectedCountryId);

    const currentCountry = userCountry || selectedCountry || detectedCountry || defaultCountry;

    const currentCurrency = currencies.find(c => c.id === currentCountry.currency_id);

    setCurrencies({ currencies, currentCurrency });
    setCountries({ countries, currentCountry });
    setAppContext({
      // deliveryFeePromotions: deliveryFeePromotions.filter(isPromotionTime),
      // shippingFeePromotions: shippingFeePromotions.filter(isPromotionTime),
      // sellingFeePromotions: sellingFeePromotions.filter(isPromotionTime),
      deliveryFeePromotions,
      shippingFeePromotions,
      sellingFeePromotions,
      productCollections,
      searchesTrending,
      paymentMethods,
      promocodes,
      cryptoRates,
      payoutCryptoConfig,
      promotionLoyaltyMultiplier,
      nsPayBuyBonusConfig,
      buyTrxnPaymentFee,
      buyTrxnGSTFee,
      deliveryProtectionBuyingFee,
      trustpilotConfig,
      currentCountry,
    });
    SentryInit(_user);
    Analytics.setContext({ currency: currentCurrency, country: currentCountry });
    Analytics.identify(_user);
    initYellowMessenger(_user || {}, currentCountry);
  };

  useEffect(() => {
    if (location && context) {
      setupApp(location, context, user);
    } else {
      Promise.all([
        getLocation(),
        API.fetch('context/app'),
        isAuthenticated ? fetchUser() : undefined,
      ]).then(([_location, _context, _user]: any[]) => {
        setLocation(_location);
        setContext(_context);
        setupApp(_location, _context, _user);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, user.country_id, user.shipping_country_id, currentCurrencyId, selectedCountryId]);

  return (
    <I18nProvider i18n={i18n}>
      <BaseSEO />
      <QueryClientProvider client={queryClient}>
        <Layout>
          <Routes />
          <ShareWidget />
        </Layout>
      </QueryClientProvider>
    </I18nProvider>
  );
};

export default App;
