import React, { useState } from 'react';
import { Text, Flex, Button } from 'components/base';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';
import { i18n } from '@lingui/core';
import { Theme } from 'styles';
import { Input } from './Input';
import fieldToTitle from '../../utils/string/fieldToTitle';

const PasswordInput = ({
  label,
  placeholder,
  name,
  ...props
}: {
  label: string,
  placeholder: string,
  name: string,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputProps = {
    placeholder,
    name,
    label,
    ...props,
  };

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      border={`1px solid ${Theme.colors.gray6}`}
    >
      <Input
        {...inputProps}
        type={showPassword ? 'text' : 'password'}
        width="90%"
        style={{ border: 'none' }}
      />
      <span>{i18n._(label || placeholder || fieldToTitle(name))}</span>
      <Button
        variant="clear"
        type="button"
        onClick={e => {
          e.preventDefault();
          setShowPassword(!showPassword);
        }}
        width="10%"
      >
        <Text alignContent="center" justifyContent="center" fontSize={18}>
          {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
        </Text>
      </Button>
    </Flex>
  );
};

export default PasswordInput;
