// @flow
import { BaseType } from './base';

type ProductCelebrityType = { [key: string]: string };
type CountryLowestListPrice = { [key: number]: number };
type DropDatesLocalType = { [string]: string | Date };

export type ProductClassType = 'Sneakers' | 'Apparel' | 'Collectibles';

const ProductClassEnums: ProductClassType[] = ['Sneakers', 'Apparel', 'Collectibles'];

export type SneakerSizeMapType = {
  [key: 'US' | 'US_W' | 'US_M' | 'EU' | 'JP' | 'UK']: string,
  displaySize: string,
};

export type SizeMapUnitsType = 'US' | 'UK' | 'EU' | 'JP';

export type ProductGender = 'men' | 'women' | 'youth' | 'infant' | 'unisex' | 'ps' | 'td';

export interface ProductType extends BaseType {
  name: string;
  short_name: string;
  name_slug: string;
  cost_retail: number;
  cost_manual_sgd: number;

  actual_weight: number;
  vol_weight: number;
  colorway: string;
  designer: string;
  main_color: string;
  midsole: string;
  nickname_english: string;
  nickname_zh: string;
  silhouette: string;
  sku: string;
  upper_material: string;
  season: string;
  gender: ProductGender;
  sizes: string[];
  size_chart_url: string;
  is_one_size: boolean;
  description: string;
  drop_year: number;
  drop_date: string;
  drop_dates_local: DropDatesLocalType;
  gallery: string[];
  image: string;
  offer_count: number;
  list_count: number;
  last_sale_price: number;
  last_sale_price_2: number;
  highest_offer_price: number;
  lowest_listing_price: number;
  country_lowest_list_prices: CountryLowestListPrice;
  recent_list_time: string;
  recent_lowest_list_time: string;
  recent_highest_offer_time: string;
  class: ProductClassType;
  main_brand: string;
  sub_brand: string;
  category: string;
  category_level_1: string;
  category_level_2: string;
  category_level_3: string;
  category_level_4: string;
  collections: string[];
  is_buy_now_only: boolean;
  is_apparel: boolean;
  is_sneaker: boolean;
  is_collectible: boolean;
  is_instant_available: boolean;
  size_specific: string;

  celebrity: ProductCelebrityType;

  // Client Only
  queryID: string;
  recommend_type: string;
  // algolia Only
  objectID: string;
  product_id: number;
  wishlist_active_count?: string;
  wishlist_change_percentage?: number;
  // curated lists sale param
  price?: number;
}

const defaultProduct: ProductType = {
  id: 0,
  name: '',
  short_name: '',
  name_slug: '',
  sku: '',
  cost_retail: 0,
  cost_manual_sgd: 0,
  actual_weight: 0,
  vol_weight: 0,
  colorway: '',
  designer: '',
  main_color: '',
  midsole: '',
  nickname_english: '',
  nickname_zh: '',
  silhouette: '',
  upper_material: '',
  season: '',
  gender: 'men',
  sizes: [],
  size_chart_url: '',
  is_one_size: true,
  gallery: [],
  image: '',
  description: '',
  drop_date: '',
  drop_year: 0,
  offer_count: 0,
  list_count: 0,
  last_sale_price: 0,
  last_sale_price_2: 0,
  highest_offer_price: 0,
  lowest_listing_price: 0,
  country_lowest_list_prices: {},
  recent_list_time: '',
  recent_lowest_list_time: '',
  recent_highest_offer_time: '',
  class: 'Sneakers',
  main_brand: '',
  sub_brand: '',
  category: '',
  category_level_1: '',
  category_level_2: '',
  category_level_3: '',
  category_level_4: '',
  collections: [],
  is_buy_now_only: false,
  drop_dates_local: {},

  is_sneaker: true,
  is_apparel: false,
  is_collectible: false,
  is_instant_available: false,
  size_specific: '',

  celebrity: {},

  // Client Only
  queryID: '',
  recommend_type: '',
  // algolia Only
  objectID: '',
  product_id: 0,
};

export type CuratedListsProductType = {
  lists: ProductType[],
  total: number,
  sizes: string[],
  gender: ProductGender[],
  mainBrands: string[],
  contentDetails: { title: string, description: string, subDescription: string },
};

export { defaultProduct, ProductClassEnums };

// note to me sizes and box old new corelation
