// @flow
import * as React from 'react';
import { useStoreState } from 'easy-peasy';
import { IoMdAlert } from 'react-icons/io';
import styled from 'styled-components';

import { Flex, Text } from 'components/base';
import Theme from 'styles/theme';

const ShipmentPendingAlert = ({
  children,
  shippingStats,
}: {
  children?: React.Node,
  shippingStats?:
    | { has_to_ship: boolean }
    | { has_to_ship: boolean, has_to_ship_delayed?: boolean },
}) => {
  // $FlowFixMe
  const { has_to_ship_delayed = null, has_to_ship } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    shippingStats || useStoreState(s => s.user.user.shipping_stats);

  return children ? (
    <Flex style={{ position: 'relative' }}>
      {children}
      {has_to_ship_delayed ? <RedAlertFloating /> : has_to_ship ? <RedDotFloating /> : <></>}
    </Flex>
  ) : (
    <Text p={1}>{has_to_ship_delayed ? <RedAlert /> : has_to_ship ? <RedDot /> : <></>}</Text>
  );
};

const RedAlert = styled(IoMdAlert)`
  color: ${Theme.colors.red};
  height: 12px;
  width: 12px;
  margin-bottom: -2px;
`;

const RedAlertFloating = styled(RedAlert)`
  position: absolute;
  right: -10px;
  top: -2px;
`;

const RedDot = styled(Text)`
  background-color: ${Theme.colors.red};
  border-radius: 100%;
  height: 8px;
  width: 8px;
`;

const RedDotFloating = styled(RedDot)`
  position: absolute;
  right: -4px;
`;

export default ShipmentPendingAlert;
