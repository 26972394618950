// @flow
import type { UserType } from 'types/resources/user';
import type { ProductType } from 'types/resources/product';
import type { CurrencyType } from 'types/resources/currency';
import env from 'constants/environment';
import { CountryType } from 'types/resources/country';
import { cleanObject } from '../../utils';
import { mapFBCustomParamForTracking } from './product';
import type { TrackingProperties } from './product';

class FacebookAnalyticsManager {
  currency: string;

  country: string;

  setContext({ currency, country }: { currency: CurrencyType, country: CountryType }) {
    this.currency = currency.code;
    this.country = country.shortcode;
  }

  identify = (user: UserType) => {
    if (!window.fbq) return;
    window.fbq('init', env.facebook.pixelID, {
      external_id: String(user.id),
      em: user.email ? user.email.toLowerCase() : null,
      fn: user.firstname ? user.firstname.toLowerCase() : null,
      ln: user.lastname ? user.lastname.toLowerCase() : null,
      country: this.country ? this.country.toLowerCase() : null,
    });
  };

  track = ({
    eventName,
    properties,
    eventID,
    customEvent,
  }: {
    eventName: string,
    properties?: TrackingProperties,
    eventID?: string,
    customEvent?: boolean,
  }) => {
    if (!window.fbq) return;
    if (customEvent) {
      window.fbq('trackCustom', eventName, cleanObject(properties));
    } else {
      window.fbq('track', eventName, cleanObject(properties), { eventID });
    }
  };

  addPaymentInfo = () => {
    this.track({ eventName: 'AddPaymentInfo' });
  };

  addToWishlist = (product: ProductType) => {
    this.track({
      eventName: 'AddToWishlist',
      properties: {
        content_name: product.name, // $FlowFixMe
        content_ids: [String(product.id)],
        content_type: 'product',
      },
    });
  };

  lead = (user: UserType) => {
    this.identify(user);
    this.track({ eventName: 'Lead' });
    this.track({ eventName: 'CompleteRegistration' });
  };

  contact = () => {
    this.track({
      eventName: 'Contact',
    });
  };

  initiateCheckout = ({ product, value }: { product: ProductType, value: number }) => {
    this.track({
      eventName: 'InitiateCheckout',
      properties: {
        currency: this.currency,
        content_category: product.class,
        // $FlowFixMe
        content_ids: [String(product.id)],
        content_type: 'product',
        num_items: 1,
        value,
      },
    });
  };

  trackFBPixelForDynamicAds = ({
    event,
    product,
    value,
    eventID,
  }: {
    event: 'Purchase' | 'AddToCart' | 'ViewContent',
    product: ProductType,
    value: number | null,
    eventID?: string,
  }) => {
    value &&
      this.track({
        eventName: event,
        properties: {
          ...mapFBCustomParamForTracking(product, this.currency),
          value,
        },
        eventID,
      });
  };

  search = (search: string) => {
    this.track({
      eventName: 'Search',
      properties: { search_string: search },
    });
  };

  sellInitiate = ({ product }: { product: ProductType }) => {
    this.track({
      eventName: 'Sell Initiate',
      properties: {
        ...mapFBCustomParamForTracking(product, this.currency),
      },
      customEvent: true,
    });
  };

  sellListConfirm = ({
    operation,
    view,
    value,
    product,
  }: {
    operation: 'List' | 'Sale' | 'Consignment',
    view: 'Confirm' | 'Review',
    value: number | null,
    product: ProductType,
  }) => {
    value &&
      this.track({
        eventName: `${operation} ${view}`,
        properties: {
          ...mapFBCustomParamForTracking(product, this.currency),
          value,
        },
        customEvent: true,
      });
  };
}

const FacebookAnalytics = new FacebookAnalyticsManager();

export default FacebookAnalytics;
