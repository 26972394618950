// @flow
import React from 'react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import NSHelmet from './NSHelmet';

const title = t`Novelship: Sneakers, Apparel, Collectibles`;
const description = t`Step Beyond Limits, Into Your Style. Shop sneakers, apparel and collectibles.`;

const BaseSEO = () => (
  <NSHelmet
    title={i18n._(title)}
    description={i18n._(description)}
    keywords="novelship, adidas, nike, yeezy, air jordan, supreme, bape, streetwear, apparel, off white, authentic sneakers, retro jordans, marketplace, buy sell, hype"
    image="https://images.novelship.com/main/novelship-logo.jpg"
    imageHeight={600}
    imageWidth={600}
  />
);

export default BaseSEO;
