// @flow
import React from 'react';

import { Button, Box, Text } from 'components/base';
import { Dialog } from 'components/dialogs';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';

const ConfirmDialog = ({
  isOpen = false,
  title,
  onConfirm,
  children,
  toggleDialog,
  onCancel = toggleDialog,
  confirmText = i18n._(t`CONFIRM`),
  closeText = i18n._(t`CANCEL`),
}: {
  title?: string,
  children: any,
  onConfirm: any => any,
  isOpen: boolean,
  toggleDialog: any => any,
  onCancel?: any => any,
  confirmText?: string,
  closeText?: string,
}) => (
  <Dialog isOpen={isOpen} onClose={toggleDialog} width={[320, 360]}>
    <Text textAlign="center" className="bold upper" fontSize={4} py={4} px={3}>
      {title}
    </Text>
    <Box className="h-divider" width="auto" />
    <Box p={5} pb={4}>
      <Box className="center" px={4} pb={4}>
        <Text mb={2}>{children}</Text>
      </Box>
      <Button
        type="button"
        variant="black"
        width={1}
        onClick={() => {
          onConfirm();
          toggleDialog();
        }}
      >
        {confirmText}
      </Button>
      <Button type="button" variant="white" width={1} onClick={onCancel} mt={2}>
        {closeText}
      </Button>
    </Box>
  </Dialog>
);

export default ConfirmDialog;
