import { action, Action } from 'easy-peasy';

export type MarketingEmailDialogStoreType = {
  isOpen: boolean,
  openDialog: Action<MarketingEmailDialogStoreType>,
  closeDialog: Action<MarketingEmailDialogStoreType>,
};

const MarketingEmailDialogStore: MarketingEmailDialogStoreType = {
  isOpen: false,
  openDialog: action(store => {
    store.isOpen = true;
  }),
  closeDialog: action(store => {
    store.isOpen = false;
  }),
};

export default MarketingEmailDialogStore;
