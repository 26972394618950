// @flow
import type { CountryType } from 'types/resources/country';
import type { UserType } from 'types/resources/user';

import asyncScriptLoad from 'utils/asyncScriptLoad';

let loading = false;
const initYellowMessenger = (user?: UserType, country: CountryType) => {
  window.ymConfig = {
    bot: process.env.REACT_APP_YELLOW_BOT_ID,
    host: 'https://r3.cloud.yellow.ai',
    payload: {
      language: user?.locale || 'en',
      token: user?.ref || null,
      user_ref: user?.ref || null,
      category: '',
      name: user?.fullname || null,
      email: user?.email || null,
      country: country?.name,
      channel: 'mobile',
    },
  };

  if (loading) return;

  if (window.YellowMessengerPlugin) {
    window.YellowMessengerPlugin.init({ payload: window.ymConfig.payload });
    return;
  }

  const scriptURL = 'https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js';
  const onLoad = () => {
    const i = (...args) => i.c(args);
    i.q = [];
    i.c = e => i.q.push(e);
    window.YellowMessenger = i;
  };

  loading = true;
  asyncScriptLoad('yellow-ai', scriptURL, 'YellowMessenger', onLoad).then(() => {
    loading = false;
    window.YellowMessenger('reattach_activator');
    window.YellowMessenger('update', window.ymConfig);
  });
};

export default initYellowMessenger;
