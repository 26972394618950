// @flow
import { useState } from 'react';

const useToggle = (initial: boolean): [boolean, () => void] => {
  const [value, setInputValue] = useState(initial);

  const toggle = (toggleTo?: boolean) => {
    if (typeof toggleTo === 'boolean') {
      setInputValue(toggleTo);
    } else {
      setInputValue(currentValue => !currentValue);
    }
  };

  return [value, toggle];
};

export default useToggle;
