// @flow
import React, { useEffect } from 'react';
import { Trans } from '@lingui/macro';

import { Box } from 'components/base';

const RedirectOut = () => {
  useEffect(() => {
    window.location.href = 'https://novelship.com/news/scanhere/';
  });

  return (
    <Box px={3} py={6}>
      <Trans>Redirecting...</Trans>
    </Box>
  );
};

export default RedirectOut;
