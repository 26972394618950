// @flow
import { createGlobalStyle } from 'styled-components';
import Theme from './theme';
import mediaQuery from './mediaQuery';

const GlobalStyles = createGlobalStyle`
  body {
    color: ${Theme.colors.black3};
  }

  /* https://cloud.google.com/recaptcha-enterprise/docs/faq#id_like_to_hide_the_badge_what_is_allowed */
  .grecaptcha-badge {
    visibility: hidden;
  }

  body.show-grecaptcha-badge {
    .grecaptcha-badge {
      visibility: unset;
    }
  }

  h1,h2,h3,h4,h5,h6 {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
  /* - - - - - - */
  /* Responsive Utils */
  /* - - - - - */

  /* sm-* and md-* have to be used together.
   don't mix sm-* and md-* if you don't know what you are doing */

  .sm-above {
    ${mediaQuery.sm`
      display: none!important;
    `}
  }
  .sm-below {
    ${mediaQuery.sm_above`
      display: none!important;
    `}
  }

  .md-above {
    ${mediaQuery.md`
      display: none!important;
    `}
  }
  .md-below {
    ${mediaQuery.lg`
      display: none!important;
    `}
  }


  body.hide-footer {
    .ns-footer {
      display: none;
    }
  }

  body.hide-footer_mobile-app {
    .mobile-app-footer {
      display: none;
    }
  }


  body.mobile-header-scrollable {
    .ns-mobile-header {
      position: relative;
    }
  }




  /* - - - - - - */
  /* Text */
  /* - - - - - */
  .bold {
    font-weight: bold !important;
  }

  .medium {
    font-weight: 500 !important;
  }

  .normal {
    font-weight: normal !important;
  }

  .small, small {
    font-size: 0.8em;
  }

  .unselect {
    user-select: none;
  }

  .upper {
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .underline {
    text-decoration: underline;
  }

  .strike {
    text-decoration: line-through;
  }

  .center {
    text-align: center;
  }

  .justify {
    text-align: justify;
  }

  .nowrap {
    white-space: nowrap;
  }

  .ls01 {
    letter-spacing: 0.1px;
  }

  .ls02 {
    letter-spacing: 0.2px;
  }

  .ls04 {
    letter-spacing: 0.02em;
  }

  .ls05 {
    letter-spacing: 0.05em;
  }

  .ls06 {
    letter-spacing: 0.06em;
  }

  .ls08 {
    letter-spacing: 0.08em;
  }

  .lh12 {
    line-height: 1.2;
  }

  .lh13 {
    line-height: 1.4;
  }

  .lh14 {
    line-height: 1.4;
  }

  .paragraph, p {
    line-height: 1.25;
  }

  /* - - - - - - */
  /* COLORS */
  /* - - - - - */
  .blue {
    color: ${Theme.colors.blue};
  }

  .red {
    color: ${Theme.colors.red};
  }

  .green {
    color: ${Theme.colors.green};
  }


  /* - - - - - - */
  /* Interactions */
  /* - - - - - */
  .tappable, .tappable * {
    cursor: pointer;
  }

  .hoverable {
    cursor: pointer;
    opacity: 0.9;
    transition: opacity 0.1s ease;

    &:hover {
      opacity: 1;
    }
  }

  /* - - - - - - */
  /* Display */
  /* - - - - - */
  .flex {
    display: flex;

    &.center {
      justify-content: center;
      align-items: center;
    }

    &.align-center {
      align-items: center;
    }

    &.column {
      flex-direction: column;
    }
  }

  .block {
    display: block;
  }

   /* - - - - - - */
  /* Font Family */
  /* - - - - - */
  .drukwide-font{
    font-family: 'Druk Wide Web';
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
  }

  /* - - - - - - */
  /* Images */
  /* - - - - - */
  img.cover {
    object-fit: cover;
  }

  img.contain {
    object-fit: contain;
  }

  /* most common, least priority links or hidden links  */
  .link {
    &:hover {
      text-decoration: underline;
    }
  }

  /* higher precedence links, external links */
  .link-page {
    text-decoration: underline;

    &:hover {
      color: ${Theme.colors.blue};
    }
  }

  .border {
    border: 1px solid ${Theme.colors.gray7}!important;
  }

  .w100 {
    width: 100%;
  }

  button.reset {
    background: transparent;
    display: flex;
    border: none;
  }

  button.mobile-cta {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    border: none;
    width: 100%;
    height: 56px;
    font-size: 16px;
    letter-spacing: 0.5px;
    word-spacing: 0.8px;
    text-transform: uppercase;
    background-color: ${Theme.colors.black};
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(255, 255, 255, 0.25);

    /* todo: this would be removed along with this class */
    border-radius: 0;

    &.sticky {
      position: sticky;
    }
    &:disabled {
      opacity: 1;
      background-color: ${Theme.colors.gray3}!important;
    }
    &:hover {
      color: white;
      background-color: ${Theme.colors.black};
    }
    ${mediaQuery.sm`
      display: flex;
    `}

    a {
      display: flex;
      width: 100%;
      height: 56px;
      align-items: center;
      justify-content: center;
    }
  }

  .h-divider {
    border-top: solid 1px ${Theme.colors.divider};
    min-height: 1px;
    width: 100%;
    &.dark {
      border-color: ${Theme.colors.black};
    }
    &.gray {
      border-color: ${Theme.colors.gray5};
    }
  }

  .v-divider {
    border-left: solid 1px ${Theme.colors.divider};
    flex-direction: column;
    align-self: stretch;
    min-height: 40px;
    display: flex;
    width: 1px;
    &.xs { min-height: 24px; }
    &.dark {
      border-color: ${Theme.colors.gray6};
    }
  }

  /* animations */
  @keyframes spin_ns {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes shake_ns {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-3px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(3px, 0, 0);
    }
  }

  /* @reach/dialog */
  :root {
    --reach-dialog: 1;
  }

  .hide-native-scrollbar {
    scrollbar-width: none; /* Firefox 64 */
    -ms-overflow-style: none; /* Internet Explorer 11 */
  }
  .hide-native-scrollbar::-webkit-scrollbar { /** WebKit */
    display: none;
  }

  /**
  /* react-select-search for component SelectSearchDropDown
  */
  .select-search {
    position: relative;
    box-sizing: border-box;
  }

  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }

  /**
  * Value wrapper
  */
  .select-search__value {
    position: relative;
    z-index: 1;
  }

  .select-search__value::after {
    content:"";
    background: url('/images/chevron-down.svg') no-repeat right white;
    background-size: 14px;
    display: inline-block;
    position: absolute;
    top: calc(50% - 6px);
    right: 14px;;
    width: 16px;
    height: 11px;
  }

  /**
  * Input
  */
  .select-search__input {
    display: block;
    height: 50px;
    width: 100%;
    background: #fff;
    border: 1px solid transparent;
    border-radius: 3px;
    outline: none;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 50px;
    -webkit-appearance: none;
  }

  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }

  /**
  * Options wrapper
  */
  .select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  }

  /**
  * Options
  */
  .select-search__options {
    list-style: none;
    padding: 0;
  }

  /**
  * Option row
  */
  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }

  /**
  * Option
  */
  .select-search__option,
  .select-search__not-found {
    display: block;
    height: 43px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
  }

  .select-search--multiple .select-search__option {
    height: 48px;
  }

  .select-search__option.is-selected {
    background: hsl(211, 10%, 92%);
  }

  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: hsl(211, 10%, 92%);
  }

  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: hsl(211, 10%, 80%);
  }

  /**
  * Group
  */
  .select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
  }

  /**
  * States
  */
  .select-search.is-disabled {
    opacity: 0.5;
  }

  .select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
    border-color: transparent !important;
  }

  .select-search:not(.is-disabled).error .select-search__input {
    border-width: 1px;
    border-color: #ff9194 !important;
    border-radius: 0;
  }

  /**
  * Modifiers
  */
  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }

  .select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    pointer-events: none;
  }

  .select-search--multiple .select-search__input {
    cursor: initial;
  }

  .select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
  }

  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }

  .select-search:not(.select-search--multiple) .select-search__input:hover {
    /* border-color: #2fcc8b; */
  }

  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 50px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
  }

  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
  }

  .select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
  }

  #ymDivBar {
    display: none !important;
    &>img {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 100% !important;
      background-color: white;
      height: 42px !important;
      width: 42px !important;
    }
    &.visible {
      display: flex !important;
    }
  }
`;

export default GlobalStyles;
