// @flow
import type { UserType } from 'types/resources/user';

import React from 'react';
import { FaInstagram, FaFacebookF, FaYoutube, FaTwitter } from 'react-icons/fa';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { SiLine } from 'react-icons/si';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Flex, Link, Box, Button } from 'components/base';
import { getFaqLink, currentLanguage } from 'store/constants/language';
import { getUserAuthPath } from 'utils/route';
import { isAuthenticated } from 'utils/user';
import { mediaQuery } from 'styles';
import LanguageSelector from 'components/misc/LanguageSelector';
import RegionSelector from 'components/misc/RegionSelector';
import Analytics from 'services/analytics';

import PageContainer from '../PageContainer';

const FooterContainer = styled(Box)`
  section {
    h4 {
      text-transform: uppercase;
      font-size: 14px;
      margin: 0;
    }
    ul {
      margin: 20px 0 0;
      list-style: none;
      padding: 0;

      ${mediaQuery.md`
        margin-top: 16px;
      `};

      li {
        color: rgba(255, 255, 255, 0.7);
        padding: 8px 0;
        font-size: 14px;
      }
    }
    .icon {
      background: rgba(255, 255, 255, 0.1);
      margin-right: 20px;
      color: #fff;
      padding: 16px;
      height: 48px;
      width: 48px;
      // fix screen overflow on lg screens
      ${mediaQuery.lg`
        margin-right: 12px;
        height: 43px;
        width: 43px;
        padding: 14px;
      `};
      ${mediaQuery.hg`
        margin-right: 20px;
        height: 48px;
        width: 48px;
        padding: 16px;
      `};
    }
  }

  a:hover,
  button:hover {
    text-decoration: underline;
  }
`;

const NewUserSection = () => {
  const user: UserType = useStoreState(s => s.user.user);
  const logout = useStoreActions(a => a.user.logout);

  return (
    <section>
      {user.id ? (
        <>
          <h4>
            <Trans>My Account</Trans>
          </h4>
          <ul>
            <li>
              <Link to="dashboard/buying">
                <Trans>Buying</Trans>
              </Link>
            </li>
            <li>
              <Link to="dashboard/selling">
                <Trans>Selling</Trans>
              </Link>
            </li>
            <li>
              <Link to="dashboard/settings">
                <Trans>Settings</Trans>
              </Link>
            </li>
            <li>
              <Link to="dashboard/promotions">
                <Trans>Promocodes</Trans>
              </Link>
            </li>
            <li>
              <Button variant="clear" onClick={logout}>
                <Trans>Logout</Trans>
              </Button>
            </li>
          </ul>
        </>
      ) : (
        <>
          <h4>
            <Trans>New User</Trans>
          </h4>
          <ul>
            <li>
              <Link to={getUserAuthPath()}>
                <Trans>Sign Up</Trans>
              </Link>
            </li>
            <li>
              <Link to={getUserAuthPath({ page: 'login' })}>
                <Trans>Log In</Trans>
              </Link>
            </li>
          </ul>
        </>
      )}
    </section>
  );
};

const SocialMediaSection = () => (
  <section>
    <h4>
      <Trans>Social</Trans>
    </h4>
    <Flex mt={4}>
      <Link to="https://instagram.com/novelship/" aria-label="Novelship Instagram">
        <FaInstagram className="icon" />
      </Link>
      <Link to="https://facebook.com/novelship/" aria-label="Novelship Facebook">
        <FaFacebookF className="icon" />
      </Link>
      <Link to="https://twitter.com/Novelship" aria-label="Novelship Twitter">
        <FaTwitter className="icon" />
      </Link>
      <Link
        to="https://www.youtube.com/channel/UCrM3j2jFIbrJuT9zppM5vBQ"
        aria-label="Novelship Youtube"
      >
        <FaYoutube className="icon" />
      </Link>
      <Link
        to="https://liff.line.me/1645278921-kWRPP32q/?accountId=novelship"
        aria-label="Novelship Line"
      >
        <SiLine className="icon" />
      </Link>
    </Flex>
  </section>
);

const PreferencesSection = () => (
  <Flex flexWrap="wrap">
    <Box width={1 / 2}>
      <section>
        <h4>
          <Trans>Language</Trans>
        </h4>
        <Flex mt={7}>
          <LanguageSelector className="dark" style={{ width: 132 }} />
        </Flex>
      </section>
    </Box>
    {!isAuthenticated() && (
      <Box width={1 / 2}>
        <section>
          <h4>
            <Trans>Region</Trans>
          </h4>
          <Flex mt={7}>
            <RegionSelector className="dark" style={{ width: 132 }} />
          </Flex>
        </section>
      </Box>
    )}
  </Flex>
);

const PrimaryFooter = () => (
  <FooterContainer bg="black" color="white" px={[6, 4]} py={[8, 80]}>
    <PageContainer>
      <Flex justifyContent="space-between" flexWrap="wrap">
        <Box my={[6, 3]} width={[1, 1, 1 / 2, 1 / 4]}>
          <section>
            <h4>
              <Trans>About Us</Trans>
            </h4>
            <ul>
              <li>
                <Link to="/about">
                  <Trans>About</Trans>
                </Link>
              </li>
              <li>
                <Link to={getFaqLink('main')}>
                  <Trans>Support</Trans>
                </Link>
              </li>
              <li>
                <Link to="/authentication">
                  <Trans>Authenticity</Trans>
                </Link>
              </li>
              <li>
                <Link to="/terms">
                  <Trans>Terms</Trans>
                </Link>
              </li>
              <li>
                <Link to="/privacy">
                  <Trans>Privacy</Trans>
                </Link>
              </li>
              <li>
                <Link to="/careers">
                  <Trans>Careers</Trans>
                </Link>
              </li>
              <li>
                <Link to="/support" onClick={() => Analytics.contactUsClick()}>
                  <Trans>Contact</Trans>
                </Link>
              </li>
              {currentLanguage === 'ja' && (
                <li>
                  <Link to="/tokutei">特定商取引法に基づく表示</Link>
                </li>
              )}
              {currentLanguage === 'zh-Hant-TW' && (
                <li>
                  <Link to="/tw-branches">海外公司條款</Link>
                </li>
              )}
            </ul>
          </section>
        </Box>

        <Box my={[6, 3]} width={[1, 1, 1 / 2, 1 / 4]}>
          <section>
            <h4>
              <Trans>Buy and Sell</Trans>
            </h4>
            <ul>
              <li>
                <Link to="/sell">
                  <Trans>Sell</Trans>
                </Link>
              </li>
              <li>
                <Link to="/sneakers">
                  <Trans>Browse</Trans>
                </Link>
              </li>
              <li>
                <Link to="/authenticateforme">
                  <Trans>Authentication</Trans>
                </Link>
              </li>
              <li>
                <Link to="/shipping-info">
                  <Trans>Shipping Info</Trans>
                </Link>
              </li>
              <li>
                <Link to="/info-return-and-refund">
                  <Trans>Return and Refund</Trans>
                </Link>
              </li>

              <li>
                <Link to="/info-become-a-seller">
                  <Trans>Become A Seller</Trans>
                </Link>
              </li>
              <li>
                <Link to="/selling-info">
                  <Trans>Selling Info</Trans>
                </Link>
              </li>
              <li>
                <Link to="/info-product-standards">
                  <Trans>Product Standard</Trans>
                </Link>
              </li>
              <li>
                <Link to="/product-request">
                  <Trans>Product Request</Trans>
                </Link>
              </li>
            </ul>
          </section>
        </Box>

        <Box my={[6, 3]} width={[1, 1, 1 / 2, 1 / 4]}>
          <NewUserSection />
        </Box>
        <Box my={[6, 3]} width={[1, 1, 1 / 2, 1 / 4]}>
          <PreferencesSection />
          <Box my={10} />
          <SocialMediaSection />
          <Box my={8} />
        </Box>
      </Flex>
    </PageContainer>
  </FooterContainer>
);

export default PrimaryFooter;
