// @flow
import type { PromocodeType } from 'types/resources/promocode';
import type { UserType } from 'types/resources/user';
import type { CurrencyType } from 'types/resources/currency';
import Store from 'store';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { $ } from 'utils/currency';

const getPromocodes = (): PromocodeType[] => Store.getState().context.promocodes;

const getPromocodeExpiryDate = (promocode: PromocodeType, user: UserType) => {
  const promocodeValidDays =
    promocode.valid_days_after_signup && promocode.valid_days_after_signup > 0;
  let expiryDate = promocode.end_date;
  if (user.created_at && promocodeValidDays) {
    const userSignupDate = new Date(user.created_at);
    const expiryByExpiredDate = new Date(promocode.end_date);
    const expiryByValidDays = new Date(
      userSignupDate.setDate(userSignupDate.getDate() + promocode.valid_days_after_signup)
    );
    expiryDate = expiryByExpiredDate > expiryByValidDays ? expiryByValidDays : expiryByExpiredDate;
  }
  return expiryDate;
};

const getWelcomePromocodes = (
  currency: CurrencyType,
  user?: UserType,
  shouldCheckEmailOptin?: boolean = true
): PromocodeType[] => {
  const allPublicPromocodes = getPromocodes();

  const welcomePromocodes = allPublicPromocodes.filter(promocode => {
    const isApplicableCurrency = promocode.currency_id === currency.id;
    const isApplicableWelcomeCode = promocode.type === 'campaign_welcome';
    const isApplicablePlatform = promocode.platform === 'all' || promocode.platform === 'site';
    if (user && user.id) {
      let isApplicableForNewUser = false;
      if (user.created_at) {
        const userSignupDate = new Date(user.created_at);
        const promocodeStartDate = new Date(promocode.start_date);
        isApplicableForNewUser = promocode.start_date ? userSignupDate >= promocodeStartDate : true;
      }
      const isApplicableForEmailOptinUser = shouldCheckEmailOptin
        ? user.notification_preferences.promotions.email
        : true;
      const isPromoUsed = user.promocodes.includes(promocode.id);
      const isApplicableForRefereeUser = !(user.referred_by && promocode.is_referee_excluded);
      const isApplicableForFirstTimePurchase = promocode.first_purchase_only
        ? user.firstTimePromocodeEligible
        : true;

      const expiryDate = getPromocodeExpiryDate(promocode, user);
      const currentDate = new Date();
      const isWelcomeCodeValid = currentDate < new Date(expiryDate);
      return (
        isApplicableForNewUser &&
        isApplicableForEmailOptinUser &&
        isApplicableCurrency &&
        isApplicablePlatform &&
        isApplicableWelcomeCode &&
        !isPromoUsed &&
        isApplicableForRefereeUser &&
        isApplicableForFirstTimePurchase &&
        isWelcomeCodeValid
      );
    }
    return isApplicableCurrency && isApplicableWelcomeCode && isApplicablePlatform;
  });
  return welcomePromocodes;
};

const getHighestValueWelcomePromocode = (
  currency: CurrencyType,
  user?: UserType,
  shouldCheckEmailOptin?: boolean = true
) => {
  const welcomePromocodes = getWelcomePromocodes(currency, user, shouldCheckEmailOptin);

  const max = welcomePromocodes.reduce(
    (prev: any, current: any) => (prev.value > current.value ? prev : current),
    {}
  );

  const promoCodeDetails = { value: '', code: '' };
  if (max && max.id) {
    promoCodeDetails.code = max.code;
    if (max.value) {
      promoCodeDetails.value = max.is_percentage_discount ? `${max.value}%` : $(max.value);
    } else if (max.is_shipping_free) {
      promoCodeDetails.value = i18n._(t`Free Shipping`);
    }
  }

  return promoCodeDetails;
};

export {
  getPromocodeExpiryDate,
  getPromocodes,
  getWelcomePromocodes,
  getHighestValueWelcomePromocode,
};
