// @flow
import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { MdOutlineEmail, MdContentCopy } from 'react-icons/md';

import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import styled from 'styled-components';

import { Button, Flex, Image, Text } from 'components/base';
import { getUtmParams, share } from 'utils/share';
import useCopy from 'utils/hooks/useCopy';
import Dialog from 'components/dialogs/Dialog';
import { getImgixUrl } from 'constants/constants';
import { Theme, mediaQuery } from 'styles';

const ShareWidgetStyle = styled.div`
  .share-options {
    padding-left: 72px;
    padding-right: 72px;
    button {
      line-height: 1em;
      padding: 8px 0;
      margin: 11px 0;
      height: auto;
      width: 80px;
      flex-direction: column;
    }
    svg {
      line-height: 1em;
      font-size: 40px;
      margin-bottom: 1px;
    }
    ${mediaQuery.sm`
      padding-left: 20px;
      padding-right: 20px;
    `}
  }
`;

const ShareWidget = () => {
  const { isShareWidgetVisible, sharePayload } = useStoreState(s => s.share);
  const toggleShareWidget = useStoreActions(a => a.share.toggleShareWidget);

  const { copy, copyButtonText } = useCopy(
    `${sharePayload.text} ${sharePayload.url}`,
    i18n._(t`Copy Link`)
  );

  const shareOptions = [
    {
      platform: 'whatsapp',
      logo: '/logos/whatsapp.svg',
      label: <Trans>Whatsapp</Trans>,
      icon: null,
      marginLeft: '0px',
      specialClass: '',
    },
    {
      platform: 'facebook',
      logo: '/logos/facebook.svg',
      label: <Trans>Facebook</Trans>,
      icon: null,
      marginLeft: '24px',
      specialClass: '',
    },
    {
      platform: 'twitter',
      logo: '/logos/twitter.svg',
      label: <Trans>Twitter</Trans>,
      icon: null,
      marginLeft: '24px',
      specialClass: '',
    },
    {
      platform: 'email',
      logo: null,
      label: <Trans>Email</Trans>,
      icon: <MdOutlineEmail size={20} />,
      marginLeft: '0px',
      specialClass: '',
    },
    {
      platform: 'sms',
      logo: null,
      label: <Trans>SMS</Trans>,
      icon: <FaEnvelopeOpenText size={20} />,
      marginLeft: '24px',
      specialClass: 'sm-below',
    },
    {
      platform: 'copy',
      logo: null,
      label: i18n._(copyButtonText),
      icon: <MdContentCopy style={{ transform: 'scaleY(-1)' }} size={20} />,
      marginLeft: '24px',
      specialClass: '',
    },
  ];
  return (
    <Dialog
      positionY="middle"
      isOpen={isShareWidgetVisible}
      onClose={toggleShareWidget}
      width={[0.88, 432]}
    >
      <ShareWidgetStyle className="share-dialog">
        <Flex center py={4} style={{ borderBottom: `1px solid ${Theme.colors.divider}` }}>
          <Text fontWeight={700} style={{ lineHeight: '24px' }} className="upper" fontSize={3}>
            <Trans>Share Via</Trans>
          </Text>
        </Flex>
        <Flex fontSize={1} flexWrap="wrap" center className="share-options">
          {shareOptions.map(({ platform, logo, label, icon, marginLeft, specialClass }, index) => {
            let copyUrl;
            const _utmParams = getUtmParams(
              platform,
              sharePayload.url,
              sharePayload.utm_campaign,
              false
            );
            if (platform === 'copy') copyUrl = `${sharePayload.url}${_utmParams}`;

            return (
              <Button
                variant="clear"
                key={index}
                style={{ marginLeft }}
                className={specialClass}
                onClick={() =>
                  platform !== 'copy'
                    ? share(platform, sharePayload)
                    : copy(`${sharePayload.text} ${copyUrl}`)
                }
              >
                {logo ? (
                  <Image src={getImgixUrl(logo)} width={40} />
                ) : (
                  <Flex bg="gray7" center width={40} height={40} borderRadius={12}>
                    {icon}
                  </Flex>
                )}
                <Text className="medium" mt={2} style={{ lineHeight: '20px' }}>
                  {label}
                </Text>
              </Button>
            );
          })}
        </Flex>
      </ShareWidgetStyle>
      <Flex center p={5}>
        <Button
          type="button"
          variant="black"
          onClick={toggleShareWidget}
          style={{ height: 48 }}
          width={1}
        >
          <Trans>Close</Trans>
        </Button>
      </Flex>
    </Dialog>
  );
};

export default ShareWidget;
