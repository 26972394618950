// @flow
import Store from 'store';
import { currentLanguage } from 'store/constants/language';
import type { CountryType } from 'types/resources/country';

const getCurrentCountry = (): CountryType => Store.getState().country.current;
const getCountryByShortcode = (code: string): CountryType =>
  Store.getState().country.getByShortcode(code);

const getNewsSitePath = () => {
  if (currentLanguage === 'zh-Hant-TW' || getCurrentCountry().shortcode === 'TW') return '/news-tw';
  return '/news';
};

export { getCurrentCountry, getNewsSitePath, getCountryByShortcode };
