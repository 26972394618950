// @flow
import * as React from 'react';
import FallbackError from 'components/Layout/FallbackError';
import { sentryCapture } from 'services/sentry';

type FallbackProps = {
  onReset: () => void,
};

export type FallbackComponentType = React.ComponentType<FallbackProps>;

export type ErrorBoundaryProps = {
  children: React.Node,
  fallback?: FallbackComponentType,
};

type ErrorBoundaryState = {
  hasError: boolean,
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps & FallbackProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps & FallbackProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    // console.error('Caught an error:', error.message);
    sentryCapture(error.message);
  }

  render() {
    const { hasError } = this.state;
    const { children, fallback: FallbackComponent, onReset } = this.props;

    if (hasError) {
      return FallbackComponent ? (
        <FallbackComponent onReset={onReset} />
      ) : (
        <FallbackError onReset={onReset} />
      );
    }
    return children;
  }
}

export default ErrorBoundary;
