// @flow
import * as React from 'react';
import styled from 'styled-components';

import { Box } from 'components/base';

const P = styled(Box)`
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: 960px) {
    max-width: calc(98vw - 48px);
  }

  /* @media (min-width: 1024px) {
    max-width: 1000px;
  }

  @media (min-width: 1152px) {
    max-width: 1020px;
  } */

  @media (min-width: 1280px) {
    max-width: calc(98vw - 96px);
  }

  /* @media (min-width: 1408px) {
    max-width: 1280px;
  }

  @media (min-width: 1600px) {
    max-width: 1380px;
  } */

  @media (min-width: 1920px) {
    max-width: 1460px;
  }

  @media (min-width: 2400px) {
    max-width: 1600px;
  }
`;

type Props = {
  children?: React.Node,
};

const PageContainer = ({ children, ...props }: Props) => <P {...props}>{children}</P>;

export default PageContainer;
