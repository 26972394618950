// @flow
import { createStore } from 'easy-peasy';
import model from 'store/index';

const Store = createStore(model);

// https://easy-peasy.now.sh/docs/recipes/hot-reloading.html
if (process.env.NODE_ENV === 'development') {
  // $FlowFixMe
  if (module.hot) {
    module.hot.accept(() => {
      Store.reconfigure(model);
    });
  }
}

export default Store;
