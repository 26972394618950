// @flow
import type { ProductType } from 'types/resources/product';
import type { AlgoliaParamsType } from 'store/views/search';

// title case bUt DON'T lower case letter that are ALREADY CAPITAL
// 👇
// Title Case BUt DON'T Lower Case Letter That Are ALREADY CAPITAL
const titleCase = (str: string) =>
  str
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substr(1))
    .join(' ');

export type TrackingProperties = { [key: string]: string | number | boolean | typeof undefined };

const mapProductForTracking = (product: ProductType) => ({
  'Product ID': product.id,
  Name: product.name,
  Brand: product.main_brand,
  Category: product.class,
});

const mapBrowseForTracking = (params: AlgoliaParamsType) => ({
  Brand: params.filter?.category_level_1 || undefined,
  Category: params.filter?.class || undefined,
  Collection: params.filter?.collection || undefined,
  Page: (params.page || 0) + 1,
  Sort: params.sort,
  // $FlowFixMe
  Color: params.filter?.main_color?.join(', ') || undefined,
  'Release Year': params.filter?.drop_year || undefined,
  // $FlowFixMe
  'Size Type': params.filter?.gender?.join(', ') || undefined,
});

const mapEditOptionForTracking = {
  beatLowestListByValue: `Beat Lowest List`,
  decreaseByValue: `Decrease List Price`,
  setToValue: `New List Price`,
  increaseByValue: `Increase List Price`,
};

const mapFBCustomParamForTracking = (product: ProductType, currencyCode: string) => ({
  currency: currencyCode,
  content_category: product.class,
  content_name: product.name,
  content_type: 'product', // $FlowFixMe
  content_ids: [String(product.id)],
});

// Ref: https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
// const trackProductImpressions = (list: string, products: ProductType[], currency: CurrencyType) => {
//   const { dataLayer } = window;
//   dataLayer.push({
//     ecommerce: {
//       currencyCode: (currency && currency.code) || 'SGD',
//       impressions: products.map((p, i) => ({
//         ...mapProductForTracking(p),
//         price: p.lowest_listing_price,
//         position: i + 1,
//         list,
//       })),
//     },
//   });
// };

// Ref: https://developers.google.com/tag-manager/enhanced-ecommerce#details
// const trackProductDetailsImpressions = (product: ProductType) => {
//   window.dataLayer.push({
//     ecommerce: {
//       detail: {
//         actionField: { list: product.class },
//         products: [
//           {
//             ...mapProductForTracking(product),
//             price: product.lowest_listing_price,
//           },
//         ],
//       },
//     },
//   });
// };

// Ref: https://developers.google.com/tag-manager/enhanced-ecommerce#checkout
/*
  Step Ref:
  1. Size Select
  2. View Product Info
  3. Confirm Purchase
 */
// const trackProductCheckout = (
//   step: number,
//   sale: (TransactionType & OfferListType) | any,
//   product: ProductType,
//   operation: string,
//   option?: string
// ) => {
//   window.dataLayer.push({
//     event: 'checkout',
//     ecommerce: {
//       checkout: {
//         actionField: {
//           step,
//           operation: mapOperationMode(operation),
//           option: option || sale.payment_method || '',
//         },
//         products: [
//           {
//             ...mapProductForTracking(product),
//             price: getPrice(sale),
//           },
//         ],
//       },
//     },
//   });
// };

// const trackSizeSelect = (product: ProductType, operation: string) => {
//   trackProductCheckout(1, {}, product, operation);
// };

// const trackProductReview = (
//   checkout: TransactionType & OfferListType,
//   product: ProductType,
//   operation: string
// ) => {
//   trackProductCheckout(2, checkout, product, operation);
// };

export {
  titleCase,
  mapBrowseForTracking,
  mapProductForTracking,
  mapEditOptionForTracking,
  mapFBCustomParamForTracking,
};
