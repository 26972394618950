// @flow
import React from 'react';
import { Trans } from '@lingui/macro';

import { Text, Box, Link } from 'components/base';

const SearchResultsNotFound = ({
  search,
  onProductRequestClick,
}: {
  search: string,
  onProductRequestClick: () => void,
}) => (
  <>
    <Box center p={5} color="gray2" bg="gray8" textAlign="center" fontWeight="bold">
      <Text>
        <Trans>SEARCH RESULTS FOR</Trans>
      </Text>
      <Text my={2} fontSize={2} textTransform="uppercase">
        "{search}"
      </Text>
      <Text mb={2} fontSize={2} fontWeight="normal">
        <Trans>
          We couldn't find what you're looking for. please try another search or request for this
          product{' '}
          <Link
            to="/product-request"
            className="blue link underline bold"
            onClick={() => onProductRequestClick()}
          >
            here
          </Link>
        </Trans>{' '}
      </Text>
    </Box>
    <Box className="h-divider" />
  </>
);

export default SearchResultsNotFound;
