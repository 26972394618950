// @flow
import type { UserType } from 'types/resources/user';

import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { IoMdClose } from 'react-icons/io';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Image, Text, Box, Button, Flex, Link } from 'components/base';
import { getUserAuthPath } from 'utils/route';
import { isAuthenticated } from 'utils/user';
import { PromotionLink } from 'components/promotion/SiteWidePromotion';
import { getFaqLink } from 'store/constants/language';
import { Theme } from 'styles';
import ShipmentPendingAlert from 'views/user/components/ShipmentPendingAlert';
import LanguageSelector from 'components/misc/LanguageSelector';
import RegionSelector from 'components/misc/RegionSelector';
import Analytics from 'services/analytics';
import { getImgixUrl } from 'constants/constants';
import { referralDiscountString } from 'constants/referrals';
import { getNewsSitePath } from 'utils/country';

const ReferralPromo = () => (
  <Link to="/referrals">
    <Flex bg="yellow" px={5} py={3} alignItems="center">
      <Image src={getImgixUrl('icons/referral_gift.svg')} alt="" width={34} minWidth={34} mr={5} />
      <Text className="bold" lineHeight={1.2} fontSize={2}>
        <Trans>Invite Friends To Earn {referralDiscountString('referrer')}</Trans>
      </Text>
    </Flex>
  </Link>
);

const SideMenu = ({
  toggleSideMenu,
  isSideMenuOpen,
}: {
  toggleSideMenu: () => void,
  isSideMenuOpen: boolean,
}) => {
  const logout = useStoreActions(a => a.user.logout);
  const user: UserType = useStoreState(s => s.user.user);

  return (
    <Box>
      <Side onClick={toggleSideMenu} className={isSideMenuOpen && 'menu-open'}>
        <Box className="side-menu-header">
          <Flex width={1} justifyContent="space-between" alignItems="center">
            <Link to="/">
              <Image
                src="/images/ns-black-logo.svg"
                alt="Novelship Logo"
                height={16}
                width={169}
                ml={5}
              />
            </Link>
            <Button variant="icon">
              <IoMdClose color="black" size={25} aria-label="close side menu" />
            </Button>
          </Flex>
        </Box>

        <Flex className="side-menu-body">
          <Box width={1}>
            {isAuthenticated() && <ReferralPromo />}
            <Box className="primary-nav" py={3} px={5}>
              <PromotionLink />
              <Link to={getNewsSitePath()}>
                <Trans>News</Trans>
              </Link>
              <Link to="/sell" onClick={Analytics.sellInitiate}>
                <Trans>Sell</Trans>
              </Link>
              <Link to="/browse">
                <Trans>Browse</Trans>
              </Link>
              <Link to="/sneakers">
                <Trans>Sneakers</Trans>
              </Link>
              <Link to="/apparel">
                <Trans>Apparel</Trans>
              </Link>
              <Link to="/collectibles">
                <Trans>Collectibles</Trans>
              </Link>
              <Link to="/trading-cards">
                <Trans>Trading Cards</Trans>
              </Link>
              <Link to="/authenticateforme">
                <Trans>Authentication</Trans>
              </Link>
            </Box>

            <Box className="auth-nav" py={3} px={5}>
              <Link to="/mobile-app">
                <Trans>Download App</Trans>
              </Link>
            </Box>

            <div className="h-divider" />

            <Box className="auth-nav" py={3} px={5}>
              <Link to="/about">
                <Trans>About Us</Trans>
              </Link>
              <Link to="/authentication">
                <Trans>Authenticity</Trans>
              </Link>
              <Link to="/product-request">
                <Trans>Product Request</Trans>
              </Link>
              <Link to={getFaqLink('main')}>
                <Trans>Support</Trans>
              </Link>
              <Link to="/support" onClick={() => Analytics.contactUsClick()}>
                <Trans>Contact</Trans>
              </Link>
            </Box>

            <div className="h-divider" />

            {user.id && (
              <Box className="auth-nav" py={4} px={5}>
                <>
                  <Text mb={3} className="email">
                    {user.email}
                  </Text>
                  <Box>
                    <Link to="/dashboard/profile">
                      <Trans>Profile</Trans>
                    </Link>
                    <Link to="/dashboard/buying">
                      <Trans>Buying</Trans>
                    </Link>
                    <Link to="/dashboard/selling">
                      <ShipmentPendingAlert shippingStats={user.shipping_stats}>
                        <Text>
                          <Trans>Selling</Trans>
                        </Text>
                      </ShipmentPendingAlert>
                    </Link>
                    <Link to="/dashboard/wishlist">
                      <Trans>Wishlist</Trans>
                    </Link>
                    <Link to="/dashboard/storage">
                      <Trans>Storage</Trans>
                    </Link>
                    <Link to="/dashboard/payout-request">
                      <Trans>NS Payout</Trans>
                    </Link>
                    <Link to="/dashboard/promotions">
                      <Trans>Promocodes</Trans>
                    </Link>
                    <Link to="/dashboard/services">
                      <Trans>Authentication</Trans>
                    </Link>
                    <Link to="/dashboard/settings">
                      <Trans>Settings</Trans>
                    </Link>
                    <Link to="/" onClick={logout}>
                      <Trans>Logout</Trans>
                    </Link>
                  </Box>
                </>
              </Box>
            )}

            {!user.id && (
              <>
                <Box className="auth-nav" py={3} px={5} mt={2}>
                  <Link to={getUserAuthPath()}>
                    <Trans>Sign Up</Trans>
                  </Link>
                  <Link to={getUserAuthPath({ page: 'login' })}>
                    <Trans>Log In</Trans>
                  </Link>
                </Box>
              </>
            )}
            <div className="h-divider" />
            <Box pl={5} mt={3} mb={isAuthenticated() ? 100 : 3}>
              <LanguageSelector style={{ width: 132 }} />
            </Box>

            <div className="h-divider" />
            {!isAuthenticated() && (
              <>
                <Box pl={5} mt={3} mb={100}>
                  <RegionSelector style={{ width: 132 }} />
                </Box>
                <div className="h-divider" />
              </>
            )}
          </Box>
        </Flex>
      </Side>

      {isSideMenuOpen && <Overlay onClick={toggleSideMenu} />}
    </Box>
  );
};

const Side = styled.aside`
  background-color: ${Theme.colors.white};
  height: 100vh;
  width: 76vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  transition: transform 0.2s ease-in-out 0s;
  transform: translateX(-80vw);
  will-change: transform;
  font-size: 16px;

  &.menu-open {
    transform: translateX(0);
  }

  .side-menu-header {
    border-bottom: 1px solid rgba(7, 7, 7, 0.05);
    > div {
      min-height: 56px;
    }
  }
  .side-menu-body {
    height: calc(100vh - 56px);
    overflow-y: auto;
  }
  .primary-nav {
    border-bottom: 1px solid rgba(7, 7, 7, 0.05);
    a {
      line-height: 1.4;
      font-weight: bold;
      display: block;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  .auth-nav {
    font-weight: bold;

    .email {
      max-width: 70vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    a {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
`;

const Overlay = styled(Box)`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 9;
  right: 0;
  top: 0;
`;

export default SideMenu;
