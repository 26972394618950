// @flow
import { generateMedia } from 'styled-media-query';

const lg = '1280px';
const md = '1024px';
const sm = '768px';
const xs = '450px';

const mediaQuery = generateMedia({
  lg,
  md,
  sm,
  xs,
});

// Define media queries here
mediaQuery.xs = mediaQuery.lessThan(xs);
mediaQuery.sm = mediaQuery.lessThan('767px');
mediaQuery.md = mediaQuery.lessThan('1023px');

mediaQuery.sm_above = mediaQuery.greaterThan(sm);
mediaQuery.lg = mediaQuery.greaterThan(md);
mediaQuery.hg = mediaQuery.greaterThan(lg);

export default mediaQuery;
