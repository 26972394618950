// @flow

import styled from 'styled-components';
import { FaCircleNotch } from 'react-icons/fa';

const Spinner = styled(FaCircleNotch)`
  margin: 6px;
  animation-name: spin_ns;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`;

export default Spinner;
