// @flow
// Theme config for styled-system and Rebass

// Colors
const white = '#fff';
const white2 = '#F4F9FF';

const black = '#0a0a0a';
const black2 = '#131315';
const black3 = '#191b1b';

const gray1 = '#48484c';
const gray2 = '#69697c';
const gray3 = '#8f8f96';
const gray4 = '#a7a7ae';
const gray5 = '#cfcfd4';
const gray6 = '#e5e5e9';
const divider = '#f2f2fc';
const gray7 = '#f6f5f6';
const gray8 = '#fafafc';
const gray9 = '#fcfcfd';

const lightRed = '#EF5D55';

const red = '#E71524';
const orange = '#F56A19';
const orange2 = '#eba900';
const lightOrange = '#f9dabf';
const yellow = '#E7F021';
const blue = '#2366FF';
const pureBlue = '#00F';
const blueGrey = '#D3E0FF80';
const green = '#009D78';
const slateGray = '#90909c';
const darkSlateGray = '#51515D';
const mediumGray = '#6C6C7C';

const alert = '#EE9748';
// social
const facebook = '#3b5998';
const twitter = '#00acee';
const google = '#4285F4';
const whatsapp = '#25D366';
const line = '#06C755';

// power-seller
const bronze = '#cdae80';
const silver = '#aca9a9';
const gold = '#d4af37';
const platinum = '#dddce0';

// theme
export default {
  fontFamily: `'IBM Plex Sans', sans-serif`,
  breakpoints: ['450px', '768px', '1024px', '1280px'],
  //           0,  1,  2,  3,  4,  5,  6,  7,  8,  9
  fontSizes: [10, 12, 14, 16, 18, 20, 22, 24, 28, 34],
  //      0, 1, 2,  3,  4,  5,  6,  7,  8,  9, 10
  space: [0, 4, 8, 12, 16, 20, 24, 30, 36, 44, 52],
  colors: {
    black,
    black2,
    black3,
    gray1,
    gray2,
    gray3,
    gray4,
    gray5,
    gray6,
    gray7,
    gray8,
    gray9,
    slateGray,
    darkSlateGray,
    mediumGray,
    lightRed,
    red,
    white,
    white2,
    orange,
    orange2,
    lightOrange,
    yellow,
    pureBlue,
    blue,
    blueGrey,
    green,
    alert,
    facebook,
    twitter,
    google,
    whatsapp,
    line,
    bronze,
    silver,
    gold,
    platinum,
    divider,
  },

  // fonts: {
  //   sans: 'system-ui, sans-serif',
  //   mono: 'Menlo, monospace',
  // },

  // shadows: {
  //   small: '0 0 4px rgba(0, 0, 0, .125)',
  //   large: '0 0 24px rgba(0, 0, 0, .125)',
  // },

  buttons: {
    clear: {
      color: 'currentColor',
      border: 'none',
      backgroundColor: 'transparent',
      fontWeight: '400!important',
      letterSpacing: '0!important',
    },
    black: {
      color: white,
      border: '1px solid',
      borderColor: black,
      backgroundColor: black,
      textTransform: 'uppercase',
      '&:hover, &.active': {
        color: black,
        backgroundColor: white,
      },
    },
    white: {
      color: black2,
      border: '1px solid',
      borderColor: black2,
      backgroundColor: white,
      textTransform: 'uppercase',
      '&:hover': {
        textDecoration: 'underline',
      },
      '&.active': {
        color: white,
        backgroundColor: black2,
      },
    },
    icon: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      '&:hover, &:active, &:hover': {
        backgroundColor: 'transparent',
      },
    },
  },

  // textStyles: {
  //   caps: {
  //     textTransform: 'uppercase',
  //   },
  // },

  // colorStyles: {
  //   warning: {
  //     color: 'black',
  //     backgroundColor: 'orange',
  //   },
  //   error: {
  //     color: 'white',
  //     backgroundColor: 'red',
  //   },
  // },
};
