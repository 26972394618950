// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Flex } from 'components/base';
import Theme from 'styles/theme';

import { mediaQuery } from 'styles';
import Spinner from './Spinner';

const DelayedSpinner = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 700);
    return () => clearTimeout(timeout);
  }, []);

  return (
    show && (
      <Flex center mt={['2vh', '6vh']}>
        <LoadingSpinner />
      </Flex>
    )
  );
};

const LoadingSpinner = styled(Spinner)`
  color: ${Theme.colors.gray5};
  font-size: 64px;
  display: block;

  ${mediaQuery.sm`
    font-size: 38px;
  `}
`;

export default DelayedSpinner;
