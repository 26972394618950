// @flow
import type { ErrorBoundaryContextValue } from 'ErrorBoundaryContext';

import * as React from 'react';
import styled from 'styled-components';

import { useErrorBoundaryContext } from 'ErrorBoundaryContext';
import { mediaQuery } from 'styles';
import AddressUpdateAlertDialog from 'components/dialogs/AddressUpdateAlertDialog';
// import RegionAndLanguageDialog from 'components/dialogs/RegionAndLanguageDialog';
import WelcomeMobileAppDialog from 'components/dialogs/WelcomeMobileAppDialog';
import MarketingEmailOptInDialog from 'components/dialogs/MarketingEmailOptInDialog';
// import FlashSaleAnnouncement from './FlashSaleAnnouncement';
import ReferralInputDialog from 'components/dialogs/ReferralInputDialog';
import ErrorBoundary from 'ErrorBoundary';
import AuthCTABar from 'views/user/components/AuthCTABar';

import SiteWideTopTicker from './SiteWideTopTicker';
// import Announcement from './Announcement';
// import Header from './Header';
import Navbar from './Navbar';
import Footer from './Footer';

const Main = styled.main`
  margin: 0 auto;
  min-height: calc(80vh + 52px);
  ${mediaQuery.md`
    min-height: calc(100vh + 52px);
  `}
`;

const Layout = ({ children }: { children?: React.Node }) => {
  const { resetErrorBoundary }: ErrorBoundaryContextValue = useErrorBoundaryContext();
  return (
    <>
      {!window.isNativeApp && (
        <>
          <SiteWideTopTicker />
          {/* EVENT_ONLY */}
          {/* <Announcement /> */}
          <Navbar />
          {/* {process.env.REACT_APP_FLASH_SALE_BANNER && <FlashSaleAnnouncement />} */}
        </>
      )}
      <ErrorBoundary onReset={() => resetErrorBoundary()}>
        <Main>{children}</Main>

        {!window.isNativeApp && (
          <>
            <AuthCTABar />
            <Footer />
            {/* <WelcomeDialog /> */}
            {!window.isPrerender && (
              <>
                {/* <RegionAndLanguageDialog /> */}
                <ReferralInputDialog />
                <MarketingEmailOptInDialog />
                <WelcomeMobileAppDialog />
                <AddressUpdateAlertDialog />
              </>
            )}
          </>
        )}
      </ErrorBoundary>
    </>
  );
};

export default Layout;
