// @flow
import { Action, action, Computed, computed } from 'easy-peasy';
import { CurrencyType, defaultCurrency } from 'types/resources/currency';
import { cacheGet, cacheSet } from 'utils/localStorageCache';

type CurrencyStoreType = {
  currencies: CurrencyType[],
  current: CurrencyType,
  set: Action<CurrencyStoreType, { currencies: CurrencyType[], currentCurrency: CurrencyType }>,
  getById: Computed<CurrencyStoreType, (id: number & string) => CurrencyType>,
};

const CurrencyStore: CurrencyStoreType = {
  currencies: cacheGet('last_currencies', [defaultCurrency]),
  current: cacheGet('last_currency', defaultCurrency),
  set: action((store, { currencies, currentCurrency }) => {
    store.current = cacheSet('last_currency', currentCurrency);
    store.currencies = cacheSet('last_currencies', currencies);
  }),
  getById: computed(state => id =>
    state.currencies.find(c => c.id === parseInt(id)) || defaultCurrency
  ),
};

export default CurrencyStore;
