// @flow
import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

import { Flex, Text, Box, Image } from 'components/base';
import { mediaQuery } from 'styles';
import PageContainer from '../PageContainer';

const SecondaryFooterContainer = styled(Box)`
  .footer-logo {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-left: none;
    border-right: none;
  }

  img {
    ${mediaQuery.sm`
      height: 42px;
      width: 244px;
    `}
  }
`;

const SecondaryFooter = () => (
  <SecondaryFooterContainer bg="black" color="white" px={6} py={10}>
    <PageContainer>
      <Flex className="footer-logo" center py={[6, 8, 10]}>
        <Image
          src="/images/ns-white-logo.svg"
          alt="Novelship Logo"
          height={34}
          width={360}
          className="contain"
        />
      </Flex>

      <Flex center py={8} mt={4}>
        <Flex alignItems="center">
          <Text fontSize={2}>
            &copy; {new Date().getFullYear()} Novelship. <Trans>All rights reserved.</Trans>
          </Text>
          {/* <Text fontSize={1} ml={10} mr={5}>
              English
            </Text>
            <Text fontSize={1}>USD</Text> */}
        </Flex>
        {/* <Flex fontSize={2}>
            <Link to="/">Copyrights</Link>
            <Link to="/" px={8}>
              Privacy Policy
            </Link>
            <Link to="/">Terms &amp; Conditions</Link>
          </Flex> */}
      </Flex>
    </PageContainer>
  </SecondaryFooterContainer>
);

export default SecondaryFooter;
