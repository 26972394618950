// @flow
import type { CurrencyType } from 'types/resources/currency';
import React, { useRef, useState, useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { useStoreState } from 'easy-peasy';
import { Button, Box, Flex, Text, Link } from 'components/base';
import { goToUserAuth, getRoutePath } from 'utils/route';
import theme from 'styles/theme';
import { cacheGet, cacheSet } from 'utils/localStorageCache';
import { isMobile } from 'utils';
import { getHighestValueWelcomePromocode } from 'utils/promocode';
// $FlowFixMe
import { globalHistory } from '@reach/router';
import Analytics from 'services/analytics';
import { ReactComponent as StopwatchIcon } from 'assets/icons/stopwatch.svg';

const AuthCTABar = () => {
  // const isAuthenticated = useStoreState(s => s.user.isAuthenticated);
  const currency: CurrencyType = useStoreState(s => s.currency.current);
  const { value: welcomeDiscount } = getHighestValueWelcomePromocode(currency);
  const interval = useRef();
  const timerInterval = useRef(null);
  const [timer, setTimer] = useState();

  // const [hasTwoMinPassed, setHasTwoMinPassed] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [bottomOffset, setBottomOffset] = useState(0);
  // const [pageViewCount, setPageViewCount] = useState(cacheGet('page_view_count') || 0);

  const didMobileScroll = isMobile && window.scrollY !== 0;
  const firstViewTime = cacheGet('first_landed_at');
  // NP-775
  const showAuthCTABar = false;
  // !isAuthenticated &&
  // (pageViewCount > 5 || hasTwoMinPassed) &&
  // welcomeDiscount &&
  // !['/login', '/signup'].includes(window.location.pathname);

  const startTimer = timerFinishTime => {
    if (!timerFinishTime) return;
    const totalTimeRemaining = new Date(timerFinishTime) - new Date();
    const seconds = Math.floor((totalTimeRemaining / 1000) % 60);
    const minutes = Math.floor((totalTimeRemaining / 1000 / 60) % 60);

    if (totalTimeRemaining >= 0)
      setTimer(`${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`);
    else setTimer();
  };

  const getTimerFinishTime = () => {
    if (showAuthCTABar) {
      let finishTime = cacheGet('auth_cta_timer_end');
      if (!finishTime) {
        finishTime = new Date();
        // finishTime is set between 25 to 30 minutes, randomly
        finishTime.setSeconds(finishTime.getSeconds() + Math.trunc(Math.random() * 6 + 25) * 60);
        cacheSet('auth_cta_timer_end', finishTime);
      }
      return finishTime;
    }
  };

  useEffect(() => {
    const timerFinishTime = getTimerFinishTime();
    if (timerInterval.current) clearInterval(timerInterval.current);
    timerInterval.current = setInterval(() => {
      startTimer(timerFinishTime);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAuthCTABar]);

  const checkPosition = () => {
    const element = window.document.getElementById('sticky-action-bar');
    if (element) {
      const elementBottom = element.getBoundingClientRect().bottom;
      if (elementBottom < window.outerHeight) {
        setBottomOffset(0);
      } else {
        setBottomOffset(element.offsetHeight);
      }
    }
  };

  if (!firstViewTime) {
    const now = new Date();
    const twoMinutesLaterFromNow = now.setMinutes(now.getMinutes() + 2);
    cacheSet('first_landed_at', twoMinutesLaterFromNow);
  }

  useEffect(() => {
    interval.current = setInterval(() => {
      // setPageViewCount(cacheGet('page_view_count'));
    }, 5000);
    interval.current = setInterval(() => {
      if (cacheGet('first_landed_at') <= new Date().getTime()) {
        // setHasTwoMinPassed(true);
        clearInterval(interval.current);
      }
    }, 15000);
  });

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        const onPageLoad = () => {
          setBottomOffset(0);
          setInterval(checkPosition, 500);
        };
        if (document.readyState === 'complete') onPageLoad();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMobile) {
      checkPosition();
      window.addEventListener('scroll', () => setLastScrollY(window.scrollY));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY]);

  if (!showAuthCTABar) {
    return null;
  }

  return (
    <Flex
      center
      flexDirection="column"
      width={1}
      backgroundColor={theme.colors.yellow}
      color="black"
      style={{
        position: 'sticky',
        bottom: bottomOffset || 0,
        zIndex: 1,
        transition: 'all linear 0.05s',
      }}
    >
      <Box textAlign="center" width={[0.85, 1]} pt={3} pb={[3, 4]}>
        {timer ? (
          <Flex center mb={1} fontSize={7} fontWeight="bold">
            <StopwatchIcon />
            <Box ml={3}>{timer}</Box>
          </Flex>
        ) : null}
        {didMobileScroll ? (
          <Link to={getRoutePath('login')}>
            <Text fontSize={[2, 3]} lineHeight={1.3} fontWeight="bold">
              <Trans>SIGN UP now to get {welcomeDiscount} off your first purchase!</Trans>
            </Text>
          </Link>
        ) : (
          <Text fontSize={[2, 3]} lineHeight={1.3} fontWeight="bold">
            <Trans>SIGN UP now to get {welcomeDiscount} off your first purchase!</Trans>
          </Text>
        )}
        {!didMobileScroll && (
          <Flex center mt={[2, 3]}>
            <Button
              onClick={() => {
                Analytics.buttonClick('Auth Bar Login');
                goToUserAuth({ page: 'login' });
              }}
              style={{
                borderColor: theme.colors.black,
                color: theme.colors.black,
                height: 36,
                backgroundColor: theme.colors.yellow,
              }}
              variant="black"
              width={[84, 160]}
            >
              <Trans>LOG IN</Trans>
            </Button>
            <Button
              style={{
                height: 36,
                color: theme.colors.yellow,
                backgroundColor: theme.colors.black,
              }}
              width={[84, 160]}
              ml={3}
              onClick={() => {
                Analytics.buttonClick('Auth Bar Signup');
                goToUserAuth({ page: 'signup' });
              }}
            >
              <Trans>SIGN UP</Trans>
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default AuthCTABar;
