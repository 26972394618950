// @flow
import algoliasearch from 'algoliasearch/lite';

import env from 'constants/environment';

const client = algoliasearch(env.algolia.appId, env.algolia.apiKey);

const AlgoliaIndices = {
  search: env.algolia.indice,
  mostPopular: `${env.algolia.indice}-most_popular`,
  mostPopularSG: `${env.algolia.indice}-most_popular-sg`,
  mostPopularMY: `${env.algolia.indice}-most_popular-my`,
  mostPopularID: `${env.algolia.indice}-most_popular-id`,
  mostPopularAU: `${env.algolia.indice}-most_popular-au`,
  mostPopularNZ: `${env.algolia.indice}-most_popular-nz`,
  mostPopularTW: `${env.algolia.indice}-most_popular-tw`,
  mostPopularJP: `${env.algolia.indice}-most_popular-jp`,
  mostPopularUS: `${env.algolia.indice}-most_popular-us`,
  mostPopularGB: `${env.algolia.indice}-most_popular-gb`,
  mostPopularCA: `${env.algolia.indice}-most_popular-ca`,

  latestRelease: `${env.algolia.indice}-latest_release`,
  priceLowToHigh: `${env.algolia.indice}-price_low_to_high`,
  priceHighToLow: `${env.algolia.indice}-price_high_to_low`,

  upcomingReleaseUS: `${env.algolia.indice}-upcoming_release-us`,
  upcomingReleaseSG: `${env.algolia.indice}-upcoming_release-sg`,
  upcomingReleaseTW: `${env.algolia.indice}-upcoming_release-tw`,
  upcomingReleaseJP: `${env.algolia.indice}-upcoming_release-jp`,
  upcomingReleaseID: `${env.algolia.indice}-upcoming_release-id`,
  upcomingReleaseMY: `${env.algolia.indice}-upcoming_release-my`,
};

const AlgoliaClient: { [$Keys<typeof AlgoliaIndices>]: any } = {
  search: client.initIndex(AlgoliaIndices.search).search, // Most Relevant
  mostPopular: client.initIndex(AlgoliaIndices.mostPopular).search,
  mostPopularSG: client.initIndex(AlgoliaIndices.mostPopularSG).search,
  mostPopularMY: client.initIndex(AlgoliaIndices.mostPopularMY).search,
  mostPopularID: client.initIndex(AlgoliaIndices.mostPopularID).search,
  mostPopularAU: client.initIndex(AlgoliaIndices.mostPopularAU).search,
  mostPopularNZ: client.initIndex(AlgoliaIndices.mostPopularNZ).search,
  mostPopularTW: client.initIndex(AlgoliaIndices.mostPopularTW).search,
  mostPopularJP: client.initIndex(AlgoliaIndices.mostPopularJP).search,
  mostPopularCA: client.initIndex(AlgoliaIndices.mostPopularCA).search,
  mostPopularUS: client.initIndex(AlgoliaIndices.mostPopularUS).search,
  mostPopularGB: client.initIndex(AlgoliaIndices.mostPopularGB).search,

  latestRelease: client.initIndex(AlgoliaIndices.latestRelease).search,
  priceLowToHigh: client.initIndex(AlgoliaIndices.priceLowToHigh).search,
  priceHighToLow: client.initIndex(AlgoliaIndices.priceHighToLow).search,

  upcomingReleaseUS: client.initIndex(AlgoliaIndices.upcomingReleaseUS).search,
  upcomingReleaseSG: client.initIndex(AlgoliaIndices.upcomingReleaseSG).search,
  upcomingReleaseTW: client.initIndex(AlgoliaIndices.upcomingReleaseTW).search,
  upcomingReleaseJP: client.initIndex(AlgoliaIndices.upcomingReleaseJP).search,
  upcomingReleaseID: client.initIndex(AlgoliaIndices.upcomingReleaseID).search,
  upcomingReleaseMY: client.initIndex(AlgoliaIndices.upcomingReleaseMY).search,
};

const AlgoliaConstants = {
  hitsPerPage: 20,
  clickAnalytics: true,
  enablePersonalization: true,
  responseFields: ['hits', 'nbHits', 'facets'],
};

export default AlgoliaClient;
export { AlgoliaIndices, AlgoliaConstants };
