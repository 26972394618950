// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import environment from 'constants/environment';
import { LangMap, currentLanguage, getLocalizedUrl } from 'store/constants/language';
import { parseQuery } from 'utils';

const metaRobotsMap = {
  normal: 'noodp, noydir, index, follow, archive',
  gmc: 'nofollow',
  query: 'noindex nofollow noarchive',
};

const NSHelmet = ({
  title,
  description,
  image,
  imageWidth,
  imageHeight,
  keywords,
  children,
}: {
  title: string,
  image?: string,
  imageWidth?: number,
  imageHeight?: number,
  description?: string,
  keywords?: string,
  children?: any,
}) => {
  const { country, lang } = parseQuery();
  const fullUrl = window.location.href.replace(window.location.origin, 'https://novelship.com');
  const canonical = `https://novelship.com${window.location.pathname}`;

  const urlType =
    country && lang
      ? 'gmc'
      : fullUrl.includes('?') && !(fullUrl.includes('utm_') || fullUrl.includes('lang='))
      ? 'query'
      : 'normal';

  const appScheme = window.location.href.replace(
    window.location.origin,
    `${environment.mobileApp.scheme}:/`
  );

  return (
    <Helmet
      defaultTitle={title}
      titleTemplate={title.includes('Novelship') ? '' : `%s - Novelship`}
      title={title}
      htmlAttributes={{ lang: LangMap[currentLanguage] || 'en' }}
    >
      <meta name="title" content={i18n._(t`${title} - Novelship`)} />
      <meta property="og:title" content={i18n._(t`${title} - Novelship`)} />
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {image && <meta property="og:image:alt" content={title} />}
      {imageWidth && <meta property="og:image:width" content={imageWidth} />}
      {imageHeight && <meta property="og:image:height" content={imageHeight} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta name="robots" content={metaRobotsMap[urlType]} />
      <meta property="og:url" content={fullUrl} />
      <link rel="canonical" href={canonical} />
      {!window.isNativeApp && (
        <meta
          name="apple-itunes-app"
          content={`app-id=${environment.mobileApp.appleId}, app-argument=${appScheme}, affiliate-data=ct=smart_app_banner&amp;pt=122688706`}
        />
      )}
      {Object.values(LangMap).map(locale => (
        <link
          rel="alternate"
          // $FlowFixMe
          href={getLocalizedUrl(canonical, locale)}
          hrefLang={locale}
          key={String(locale)}
        />
      ))}
      <link href={getLocalizedUrl(canonical)} rel="alternate" hrefLang="x-default" />
      {children}
    </Helmet>
  );
};

export default NSHelmet;
