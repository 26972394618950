// @flow
import { parseQuery } from '../../utils';

function getUTMQueries() {
  const query = parseQuery();
  const campaignKeywords = 'utm_source utm_medium utm_campaign utm_content utm_term partner'.split(
    ' '
  );
  const utmQuery = campaignKeywords.reduce((a, c) => {
    if (query[c]) a[c] = query[c];
    return a;
  }, {});
  return utmQuery;
}

function getClevertapProperties(properties: any) {
  if (!properties) return {};
  const clevertapObject = Object.entries(properties).reduce((obj, [key, value]) => {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        value.slice(0, 3).forEach((element, index) => {
          obj[`${key} ${index + 1}`] = element;
        });
      }
    } else if (key === 'Product ID' && typeof value === 'number') {
      obj[key] = `P${value}`;
    } else {
      obj[key] = value;
    }
    return obj;
  }, {});

  return clevertapObject;
}

type SiteType =
  | 'home'
  | 'product_page'
  | 'class_page'
  | 'brand_page'
  | 'bl1_page'
  | 'bl2_page'
  | 'bl3_page'
  | 'bl4_page'
  | 'collection_page'
  | 'legit_page'
  | 'authentication_page'
  | 'trustpilot_page';

function mapUrlToSiteType(url: string): SiteType | null {
  const { pathname } = new URL(url);

  if (!pathname) return null;

  const siteTypePatterns: { pattern: RegExp, siteType: SiteType }[] = [
    { pattern: /^\/$/, siteType: 'home' },
    { pattern: /^\/collection\/[\w-]+\/?$/, siteType: 'collection_page' },
    { pattern: /^\/authentication\/?$/, siteType: 'authentication_page' },
    { pattern: /^\/(?:sneakers|apparel|collectibles|trading-cards)\/?$/, siteType: 'class_page' },
    { pattern: /^\/[\w-]+\/?$/, siteType: 'product_page' },
    {
      pattern: /^\/(?:sneakers|apparel|collectibles|trading-cards)\/[^/]+\/?$/,
      siteType: 'bl1_page',
    },
    {
      pattern: /^\/(?:sneakers|apparel|collectibles|trading-cards)\/[^/]+\/[^/]+\/?$/,
      siteType: 'bl2_page',
    },
    {
      pattern: /^\/(?:sneakers|apparel|collectibles|trading-cards)\/[^/]+\/[^/]+\/[^/]+\/?$/,
      siteType: 'bl3_page',
    },
    {
      pattern: /^\/(?:sneakers|apparel|collectibles|trading-cards)\/[^/]+\/[^/]+\/[^/]+\/[^/]+(\/[^/]+)?\/?$/,
      siteType: 'bl4_page',
    },
  ];

  for (const pattern of siteTypePatterns) {
    if (pathname.match(pattern.pattern)) {
      return pattern.siteType;
    }
  }

  return null;
}
export { getUTMQueries, getClevertapProperties, mapUrlToSiteType };
