// @flow
import type { ProductType } from 'types/resources/product';
import type { TikTokTrackingProperties } from './tiktok.type';
import { cleanObject } from '../../../utils';

const getContentProperties = (product: ProductType) => ({
  content_type: 'product',
  content_id: String(product.id),
  content_category: product.class,
  content_name: product.name,
});

const getTrackingProperties = (
  product: ProductType,
  currency: string,
  value: number | null
): TikTokTrackingProperties =>
  cleanObject({
    ...getContentProperties(product),
    quantity: 1,
    price: value,
    currency,
    value,
  });

export { getContentProperties, getTrackingProperties };
