// @flow
import type { CountryType } from './country';
import type { CourierType } from './courier';
import type { BaseType } from './base';

export interface CountryShippingOptionType extends BaseType {
  name: string;
  method: 'automated' | 'drop-off' | 'pickup' | 'self' | 'manual';
  single_select: boolean;
  bulk_select: boolean;
  couriers: string[];

  seller_type?: string;
  bulk_item_limit?: number;
  processing_country?: string;

  country_id: number;
  country?: CountryType;

  courier_id?: number;
  courier?: CourierType;
}

const defaultCountryShippingOption: CountryShippingOptionType = {
  id: 0,
  name: '',
  method: 'manual',
  single_select: true,
  bulk_select: false,
  couriers: ['Others'],
  country_id: 3,
};

export { defaultCountryShippingOption };
