// @flow
import { API } from 'api';
import { parseQuery } from 'utils';
import { cacheGet, cacheSet } from 'utils/localStorageCache';

const setCache = (country: string) => cacheSet('detected_country', { country }, 24 * 60 * 14);
const setCacheIP = (ip: string) => cacheSet('detected_ip_address', { ip }, 24 * 60 * 14);

/**
 * 'Cached' else then 'URL Query' else then 'ip-gep' service
 *
 * @returns
 */
const getLocation = (): Promise<{ country: string }> => {
  const { source, country } = parseQuery();
  const urlQueryCountryCode = (source || country || '').toUpperCase();

  const cachedLocation = cacheGet('detected_country');
  const cachedIPAddress = cacheGet('detected_ip_address');

  if (cachedLocation && cachedIPAddress) {
    return Promise.resolve(cachedLocation);
  }

  if (urlQueryCountryCode) {
    setCache(urlQueryCountryCode);
    return Promise.resolve({ country: urlQueryCountryCode });
  }

  return API.fetch(`ip-country-code`)
    .then(res => {
      setCache(res.countryCode);
      setCacheIP(res.ip);
    })
    .catch(() => {});
};

export default getLocation;
