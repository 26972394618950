// @flow
import React from 'react';
import styled from 'styled-components';
import { Theme, mediaQuery } from 'styles';
import { Flex } from 'components/base';
import ErrorMessage from './ErrorMessage';

const Select = ({
  error,
  className = '',
  isLanguage,
  id,
  placeholder,
  ...props
}: {
  className?: string,
  error?: string,
  isLanguage?: boolean,
  placeholder?: string,
  id?: string,
}) => (
  <StyledSelect flexDirection="column" width={1} isLanguage={isLanguage}>
    {placeholder && <span>{placeholder}</span>}
    <select className={`${className} ${error ? 'error' : ''}`} {...props} aria-label={id} />
    <ErrorMessage mt="2px">{error}</ErrorMessage>
  </StyledSelect>
);

const StyledSelect = styled(Flex)`
  flex-direction: column;
  position: relative;
  display: flex;
  width: 100%;

  > span {
    color: ${Theme.colors.gray3};
    position: absolute;
    background: white;
    font-weight: 600;
    padding: 0px 3px;
    font-size: 12px;
    left: 10px;
    top: -9px;
  }

  select {
    width: 100%;
    height: 50px;
    color: ${Theme.colors.gray1};
    border: 1px solid ${Theme.colors.gray6};
    border-radius: 0;
    padding: 0 12px;
    font-size: 16px;
    outline: none;
    background: url('/images/chevron-down-black.svg') no-repeat right white;
    appearance: none;
    background-size: 14px;
    background-position-x: calc(100% - 14px);
    &[disabled] {
      background: none;
    }

    &.borderless {
      width: auto;
      height: auto;
      background-position: 100% 50%;
      background-size: 15%;
      border: none !important;
    }

    &.md {
      height: 36px;
      padding: 0 10px;
    }

    &:focus {
      border-color: ${Theme.colors.gray5}!important;
    }
    &.error {
      border: 1px solid #f2a6ae !important;
    }
  }

  ${p =>
    p.isLanguage &&
    `width: 112px;
      select {
        font-size: 13px;
        padding: 0;
        height: 20px;
        outline: none !important;
        border: none!important;
        background-color: ${Theme.colors.white}!important;
        color: ${Theme.colors.black}!important;

        &.dark {
          background-color: ${Theme.colors.black}!important;
          color: ${Theme.colors.gray5}!important;
        }
      }

  `}

  ${mediaQuery.sm`
    ${p =>
      p.isLanguage &&
      `select {
          font-size: 15px;
        }
      `}
    `}
`;

export default Select;
