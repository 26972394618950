// @flow
import React, { useEffect, useRef } from 'react';
import { Text, Button, Flex, Box } from 'components/base';
import { Theme } from 'styles';
import { ThemeProvider } from 'styled-components';
import { Trans } from '@lingui/macro';

const FallbackError = ({ onReset }: { onReset: () => void }) => {
  const fallbackRef = useRef<?HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      fallbackRef.current &&
      event.target instanceof Node &&
      !fallbackRef.current.contains(event.target)
    )
      onReset();
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onReset]);

  return (
    <ThemeProvider theme={Theme}>
      <Box ref={fallbackRef}>
        <Flex center flexDirection="column" height="60vh">
          <Text textAlign="center" fontSize={3}>
            <Trans>Something's not right!</Trans>
          </Text>
          <Text fontSize={3}>
            <Trans>Please try again</Trans>
          </Text>
          <Button
            mt={3}
            onClick={() => {
              onReset();
              window.location.reload(true);
            }}
            variant="black"
            width={100}
          >
            <Trans>Retry</Trans>
          </Button>
        </Flex>
      </Box>
    </ThemeProvider>
  );
};

export default FallbackError;
