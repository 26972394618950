// @flow
import React from 'react';
import { IoFlashSharp } from 'react-icons/io5';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { Text, Flex } from 'components/base';

const InstantAvailableIndicator = ({
  isInstantAvailable,
  view = 'full',
  size = 'md',
  ...props
}: {
  isInstantAvailable: boolean,
  view?: 'icon' | 'half' | 'full',
  size?: 'sm' | 'md',
}) =>
  !!isInstantAvailable && (
    <Flex
      className="bold upper"
      color="green"
      fontSize={11}
      alignItems="center"
      title={i18n._(t`Instant Available`)}
      {...props}
    >
      {view === 'icon' ? (
        <Text>
          <IoFlashSharp size={size === 'sm' ? 14 : 20} className="md-above" />
          <IoFlashSharp size={size === 'sm' ? 12 : 16} className="md-below" />
        </Text>
      ) : (
        <>
          <IoFlashSharp size={size === 'sm' ? 12 : 14} className="md-above" />
          <IoFlashSharp size={size === 'sm' ? 10 : 12} className="md-below" />
          <Text>
            {view === 'full' ? <Trans>Instant Available</Trans> : <Trans>Available</Trans>}
          </Text>
        </>
      )}
    </Flex>
  );

export default InstantAvailableIndicator;
