// @flow
import type { CountryType } from 'types/resources/country';

import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Select } from 'components/form';

const RegionSelector = (props: any) => {
  const setSelectedCountryId = useStoreActions(a => a.context.setSelectedCountryId);

  const currentCountry: CountryType = useStoreState(s => s.country.current);
  const _countries: CountryType[] = useStoreState(s => s.country.countries);
  const countries: CountryType[] = [..._countries]
    .sort((c1, c2) => (c1.name > c2.name ? 1 : c1.name < c2.name ? -1 : 0))
    .filter(c => c.buying_enabled || c.selling_enabled);

  const switchAppRegion = e => setSelectedCountryId(e.target.value);

  return (
    <Select id="region" value={currentCountry.id} onChange={switchAppRegion} isLanguage {...props}>
      {countries.map(c => (
        <option key={c.id} value={c.id}>
          {c.name}
        </option>
      ))}
    </Select>
  );
};

export default RegionSelector;
