// @flow
import { defaultCountry } from 'types/resources/country';
import { BaseType } from './base';
import type { CountryType } from './country';

export interface SellerFeeType extends BaseType {
  id: number;
  seller_country_id: number;
  country: CountryType;
  selling_fee_percent: number;
  selling_fee_fixed: number;
  processing_fee_percent: number;
  processing_fee_fixed: number;
  payout_fiat_fee_percent: number;
  payout_fiat_fee_fixed: number;
  payout_crypto_fee_percent: number;
  payout_crypto_fee_fixed: number;
  flake_fee_percent: number;
  flake_fee_fixed: number;
  qclc_fail_fee_percent: number;
  qclc_fail_fee_fixed: number;
  return_fee_percent: number;
  return_fee_fixed: number;
  is_default: boolean;
  power_features: boolean;
  consignment_enabled: boolean;
  name: string;
}

const defaultSellerFee: SellerFeeType = {
  id: 0,
  seller_country_id: 0,
  country: defaultCountry,
  selling_fee_percent: 0,
  selling_fee_fixed: 0,
  processing_fee_percent: 0,
  processing_fee_fixed: 0,
  payout_fiat_fee_percent: 0,
  payout_fiat_fee_fixed: 0,
  payout_crypto_fee_percent: 0,
  payout_crypto_fee_fixed: 0,
  flake_fee_percent: 0,
  flake_fee_fixed: 0,
  qclc_fail_fee_percent: 0,
  qclc_fail_fee_fixed: 0,
  return_fee_percent: 0,
  return_fee_fixed: 0,
  is_default: true,
  power_features: false,
  consignment_enabled: false,
  name: '',
};

export { defaultSellerFee };
