// @flow
import type { UserType } from 'types/resources/user';
import type { CountryType } from 'types/resources/country';
import type { CurrencyType } from 'types/resources/currency';

import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { Trans } from '@lingui/macro';

import { referralDiscountString } from 'constants/referrals';
import { PromotionTicker } from 'components/promotion/SiteWidePromotion';
import { isCampaignTime } from 'constants/campaign';
import { Button, Flex, Link } from 'components/base';
import { cacheGet, cacheSet } from 'utils/localStorageCache';
import { FaTimes } from 'react-icons/fa';
import { Theme } from 'styles';
import { getHighestValueWelcomePromocode } from 'utils/promocode';

const lastTickerCache = cacheGet('last_ticker_text');

const SiteWideTopTicker = () => {
  const country: CountryType = useStoreState(s => s.country.current);
  const currency: CurrencyType = useStoreState(s => s.currency.current);
  const user: UserType = useStoreState(s => s.user.user);
  const { value: welcomeDiscount, code: welcomeCode } = getHighestValueWelcomePromocode(
    currency,
    user
  );
  const countryTicker = country.site_ticker || {};
  const [tickerText, setTickerText] = useState(lastTickerCache);
  const [isShipmentTickerClosed, setIsShipmentTickerClosed] = useState(
    cacheGet('is_shipping_reminder_ticker_closed')
  );

  const hasToShip = user.shipping_stats.has_to_ship;
  const showShipmentTicker = !isShipmentTickerClosed && hasToShip;
  const hideTicker = countryTicker.text
    ? countryTicker.text === tickerText
    : tickerText === 'default';

  if (hasToShip ? !!(isShipmentTickerClosed && hideTicker) : hideTicker) return null;

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      fontSize={[1, 2]}
      py={1}
      px={[2, 3]}
      bg={showShipmentTicker ? 'red' : 'blue'}
      color="white"
      className="medium"
      id={(hasToShip ? !!(isShipmentTickerClosed && hideTicker) : hideTicker) ? '' : 'ticker'}
    >
      <Button variant="clear" aria-hidden="true">
        <FaTimes color="transparent" />
      </Button>
      {showShipmentTicker ? (
        <Link to="/dashboard/selling/confirmed-sales">
          <Trans>
            You have {user.shipping_stats.to_ship_count} items pending shipment.{' '}
            <span className="underline">Arrange shipment</span>
          </Trans>
        </Link>
      ) : isCampaignTime ? (
        <PromotionTicker />
      ) : countryTicker.text && countryTicker.link ? (
        <Link to={countryTicker.link}>{countryTicker.text}</Link>
      ) : user.refereeEligible ? (
        <Trans>
          Promocode WELCOMEFRIEND: Up to {referralDiscountString('referee')} Off on first order
        </Trans>
      ) : welcomeCode ? (
        <Trans>
          Promocode {welcomeCode}: Get up to {welcomeDiscount} Off on your order
        </Trans>
      ) : (
        <Link to="/mobile-app">
          <Trans>Download the Novelship Mobile App</Trans>
        </Link>
      )}
      <Button
        variant="clear"
        onClick={() => {
          if (showShipmentTicker) {
            setIsShipmentTickerClosed(
              cacheSet('is_shipping_reminder_ticker_closed', true, 60 * 12)
            );
          } else {
            setTickerText(cacheSet('last_ticker_text', countryTicker.text || 'default', 60 * 24));
          }
        }}
        aria-label="close ticker"
      >
        <FaTimes color={Theme.colors.white} />
      </Button>
    </Flex>
  );
};

export default SiteWideTopTicker;
