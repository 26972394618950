// @flow

/**
 * @param {string} cache key
 * @param {*} cache data
 * @param {number} [exp] expiration timeout in minutes
 * @returns
 */
const cacheSet = (key: string, data: any, exp?: number) => {
  // exp: expiry in minutes
  let item = data;
  if (typeof data === 'object') {
    item = JSON.stringify(data);
  }
  localStorage.setItem(key, item);
  if (exp) {
    // $FlowFixMe
    localStorage.setItem(`${key}_exp`, Date.now() + exp * 60 * 1000);
  }
  return data;
};

const cacheGet = (key: string, fallback: any): any => {
  const exp = localStorage.getItem(`${key}_exp`);
  if (exp) {
    // $FlowFixMe
    if (exp < Date.now()) {
      return fallback;
    }
  }
  const item = localStorage.getItem(key);
  if (!item) return fallback;
  try {
    return JSON.parse(item);
  } catch (_) {
    return item;
  }
};

const cacheRemove = (key: string) => localStorage.removeItem(key);

export { cacheGet, cacheSet, cacheRemove };
