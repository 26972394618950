// @flow
import { action, thunk } from 'easy-peasy';

import { SharePayload } from 'types/views/share';
import { getUtmParams } from 'utils/share';

type Store = {
  isShareWidgetVisible: boolean,
  sharePayload: SharePayload,
};
const getSharePayload = (payload: SharePayload) => {
  const url = (payload && payload.url) || window.location.href;
  const utmParams = getUtmParams('native-web-share', url, payload.utm_campaign, false);
  const utmUrl = utmParams ? `${url}${utmParams}` : url;

  return {
    url,
    utmUrl,
    text: payload.text || '',
    title: payload.title || '',
    hashtags: payload.hashtags || [],
    mediaLink: payload.mediaLink || '',
    utm_campaign: payload.utm_campaign || '',

    urlText: encodeURIComponent(`Checkout ${url}`),
    encodedUrl: encodeURIComponent(url),
    encodedText: encodeURIComponent(payload.text),
    encodedHashtags: encodeURIComponent((payload.hashtags && payload.hashtags.join(',')) || ''),
    encodedMediaLink: encodeURIComponent(payload.mediaLink || ''),
  };
};
export default {
  isShareWidgetVisible: false,
  sharePayload: {},
  storeSharePayload: action((store: Store, payload?: SharePayload) => {
    if (payload) {
      store.sharePayload = getSharePayload(payload);
    }
  }),
  toggleShareWidget: thunk((actions, payload?: SharePayload) => {
    actions.storeSharePayload(payload);
    actions.toggleShareWidgetVisibility();
  }),
  toggleShareWidgetVisibility: action((store: Store) => {
    store.isShareWidgetVisible = !store.isShareWidgetVisible;
  }),
  share: thunk((actions, payload) => {
    if (window.navigator.share) {
      try {
        payload.url = getSharePayload(payload).utmUrl;
        window.navigator.share(payload).catch(() => {});
      } catch (_) {
        actions.toggleShareWidget(payload);
      }
    } else {
      actions.toggleShareWidget(payload);
    }
  }),
};
