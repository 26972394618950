// @flow
import * as React from 'react';

import { Button, Link, Box } from 'components/base';

import Dialog from './Dialog';

const InfoDialog = ({
  isOpen,
  link,
  showClose,
  onClose = () => {},
  buttonText,
  children,
}: {|
  isOpen: boolean,
  onClose?: any => any,
  link?: string,
  buttonText?: string,
  showClose?: boolean,
  children?: React.Node,
|}) => (
  <Dialog width={[320, 360]} onClose={onClose} isOpen={isOpen} showClose={showClose}>
    <Box className="center" p={5}>
      {children}

      {buttonText &&
        (link ? (
          <Link to={link}>
            <Button variant="black" mt={3} width={1} onClick={onClose}>
              {buttonText}
            </Button>
          </Link>
        ) : (
          <Button variant="black" mt={4} width={1} onClick={onClose}>
            {buttonText}
          </Button>
        ))}
    </Box>
  </Dialog>
);

export default InfoDialog;
