// @flow
import { i18n } from '@lingui/core';
import { en, id, zh, ja, my, es, ko } from 'make-plural/plurals';

import { buildQueryString, parseQuery } from 'utils';
import { cacheGet, cacheSet } from 'utils/localStorageCache';

export type LanguageType = 'en' | 'id' | 'zh-Hant-TW' | 'zh-Hans' | 'my' | 'ja' | 'es' | 'ko';

const defaultLanguage: LanguageType = 'en';

// https://www.w3.org/International/articles/bcp47/
const LANGUAGES: { [key: string]: { name: string, codes: string[], plural: any } } = {
  en: { name: 'English', codes: ['en'], plural: en },
  id: { name: 'Indonesian', codes: ['id'], plural: id },
  'zh-Hans': {
    name: '简体中文',
    codes: ['zh-hans', 'zh-cn', 'zh-sg', 'zh'],
    plural: zh,
  },
  'zh-Hant-TW': { name: '繁體中文', codes: ['zh-hant', 'zh-tw', 'zh-hk'], plural: zh },
  ja: { name: '日本語', codes: ['ja'], plural: ja },
  my: { name: 'Malay', codes: ['my', 'ms'], plural: my },
  es: { name: 'Español (Spanish)', codes: ['es'], plural: es },
  ko: { name: '한국어 (Korean)', codes: ['ko'], plural: ko },
};

const LangMap: { [key: string]: string } = {
  en: 'en',
  id: 'id',
  'zh-Hans': 'zh-cn',
  'zh-Hant-TW': 'zh-tw',
  ja: 'ja',
  my: 'ms',
  es: 'es',
  ko: 'ko',
};

const getLocalizedUrl = (url: string, lang?: string) => {
  const queryParams = parseQuery();
  const newQuery = buildQueryString({ ...queryParams, lang });
  return `${url.split('?')[0]}${newQuery ? `?${newQuery}` : ''}`;
};

const languageCodesMap = Object.entries(LANGUAGES).reduce((prev, curr) => {
  // $FlowFixMe
  for (const code of curr[1].codes) {
    // eslint-disable-next-line prefer-destructuring
    prev[code] = curr[0];
  }
  return prev;
}, {});

const getBrowserLang = () => {
  if (navigator.language) {
    try {
      const languageTag = navigator.language.split('-')[0] || defaultLanguage;

      return (
        languageCodesMap[navigator.language.toLowerCase()] ||
        languageCodesMap[languageTag.toLowerCase()] ||
        defaultLanguage
      );
    } catch (_) {
      return defaultLanguage;
    }
  }
  return defaultLanguage;
};

const getUrlLang = () => {
  const { lang: urlLocale } = parseQuery();
  const localeToLoad = Object.entries(LangMap).find(l => l[1] === (urlLocale || '').toLowerCase());
  return localeToLoad && localeToLoad[0];
};

const currentLanguage: string = cacheGet('last_locale') || getUrlLang() || getBrowserLang();

const loadLanguage = (language: string) => {
  i18n.loadLocaleData(language, { plurals: LANGUAGES[language].plural });
  return import(`@lingui/loader!../../locales/${language}/messages.po`).then(res => {
    i18n.load(language, res.messages);
    i18n.activate(language);
  });
};

const setLanguage = (language: string) => {
  if (language && currentLanguage !== language) {
    cacheSet('last_locale', language);
    window.location.reload();
  }
};

// trigger initial locales setup
loadLanguage(currentLanguage);

const Faqs: {
  [key:
    | 'main'
    | 'sellers_guide'
    | 'buyers_guide'
    | 'selling'
    | 'buying'
    | 'loyalty'
    | 'storage'
    | 'duty_tax'
    | 'referral'
    | 'listing_guide'
    | 'offer_guide'
    | 'delivery_declare'
    | 'payout_request'
    | 'instant_delivery'
    | 'sell_from_storage'
    | 'bulk_shipping'
    | 'post_purchase_storage'
    | 'refund_policy'
    | 'return_policy'
    | 'custom_duties_and_taxes']: {|
    en: string,
    id?: string,
    'zh-Hant-TW'?: string,
    ja?: string,
    es?: string,
    ko?: string,
  |},
} = {
  main: {
    en: '',
    id: '-id',
    'zh-Hant-TW': '-tw',
    ja: '-jp',
  },
  sellers_guide: {
    en: 'info-become-a-seller',
  },
  buyers_guide: {
    en: 'category/buying-faqs',
  },
  listing_guide: {
    en: 'info-become-a-seller',
  },
  offer_guide: {
    en: 'article/380',
  },
  selling: {
    en: 'selling-info',
  },
  buying: {
    en: 'article/380',
  },
  loyalty: {
    en: 'article/380',
  },
  storage: {
    en: 'article/380',
  },
  duty_tax: {
    en: 'shipping-info',
  },
  delivery_declare: {
    en: 'article/381',
  },
  payout_request: {
    en: 'info-become-a-seller',
  },
  instant_delivery: {
    en: 'article/381',
  },
  sell_from_storage: {
    en: 'info-become-a-seller',
  },
  bulk_shipping: {
    en: 'selling-info',
  },
  post_purchase_storage: {
    en: 'article/380',
  },
  refund_policy: {
    en: 'article/383',
  },
  return_policy: {
    en: 'article/383',
  },
  custom_duties_and_taxes: {
    en: 'shipping-info',
  },
};

export type FAQ_LINK_TYPES = $Keys<typeof Faqs>;

const getFaqLink = (guide: FAQ_LINK_TYPES) => {
  const siteLink = [
    'sellers_guide',
    'listing_guide',
    'selling',
    'duty_tax',
    'custom_duties_and_taxes',
    'bulk_shipping',
    'sell_from_storage',
    'payout_request',
  ];

  const FAQ = Faqs[guide] || Faqs.main;

  const uri = siteLink.includes(guide)
    ? `/${FAQ.en}`
    : guide !== 'main'
    ? `https://support.novelship.com/${FAQ.en}`
    : `https://support${FAQ[currentLanguage] || ''}.novelship.com/`;

  return uri;
};

export { LANGUAGES, currentLanguage, LangMap, setLanguage, getFaqLink, getLocalizedUrl };
