// @flow
import * as React from 'react';
import { space, layout, flexbox } from 'styled-system';
import styled from 'styled-components';
import {
  DialogOverlay as ReachDialogOverlay,
  DialogContent as ReachDialogContent,
} from '@reach/dialog';
import { IoMdClose } from 'react-icons/io';
import { Theme } from 'styles';

const Dialog = ({
  isOpen,
  showClose,
  onClose = () => {},
  positionX = 'center',
  positionY = 'middle',
  children,
  dialogOverlayProps,
  ...props
}: {
  showClose?: boolean,
  positionX?: 'left' | 'center' | 'right',
  positionY?: 'top' | 'middle' | 'bottom',
  isOpen: boolean,
  onClose?: any => any,
  children: React.Node,
  dialogOverlayProps?: { dangerouslyBypassFocusLock: boolean },
}) => (
  <DialogOverlay
    {...dialogOverlayProps}
    onDismiss={onClose}
    isOpen={isOpen}
    position_x={positionX}
    position_y={positionY}
  >
    <DialogContent {...props}>
      {showClose && <CloseIcon onClick={onClose} />}
      {children}
    </DialogContent>
  </DialogOverlay>
);

const DialogOverlay = styled(ReachDialogOverlay)`
  /* [data-reach-dialog-overlay] */
  justify-content: ${p =>
    // eslint-disable-next-line no-nested-ternary
    p.position_x === 'center' ? 'center' : p.position_x === 'right' ? 'flex-end' : 'flex-start'};
  align-items: ${p =>
    // eslint-disable-next-line no-nested-ternary
    p.position_y === 'middle' ? 'center' : p.position_y === 'bottom' ? 'flex-end' : 'flex-start'};
  z-index: 1000;
  display: flex;
  background: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  ${flexbox}
  ${space}
  button:focus,
  a:focus {
    outline: none !important;
  }
`;

const DialogContent = styled(ReachDialogContent)`
  /* [data-reach-dialog-content] */
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
  background: white;
  outline: none;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
  ${layout}
  border-radius: 4px;
`;

const CloseIcon = styled(IoMdClose)`
  color: ${Theme.colors.gray3};
  position: absolute;
  height: 26px;
  width: 26px;
  right: 8px;
  top: 8px;
  &:hover {
    cursor: pointer;
  }
`;

export default Dialog;
