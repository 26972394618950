// @flow
import { v4 as uuidv4 } from 'uuid';

const { cacheGet, cacheSet } = require('utils/localStorageCache');

const buildQueryString = (params: { [key: string]: string | number | boolean }) =>
  Object.keys(params)
    .filter(key => params[key] !== undefined)
    .map((key: string) => `${key}=${params[key].toString()}`)
    .join('&');

function safeDecode(str: string) {
  try {
    return decodeURIComponent(str);
  } catch (e) {
    return str;
  }
}

const parseQuery = (queryString?: string): { [key: string]: string } => {
  const query = {};
  ((queryString || window.location.search || '').split('?')[1] || '').replace(
    /([^?&=]+)=([^&]*)/g,
    (_, k, v) => (query[k] = safeDecode(v))
  );
  return query;
};

const getNumericQuery = (input: string): number => parseInt(parseQuery()[input], 10);

const cleanObject = (obj: Object): Object => {
  if (!obj) return;
  const newObj = { ...obj };
  Object.keys(newObj).forEach(
    key => (newObj[key] === undefined || newObj[key] === null) && delete newObj[key]
  );
  return newObj;
};

const debounce = (fn: any => any, delay: number) => {
  let timeoutID;
  return function(...args: any) {
    clearTimeout(timeoutID);
    const that = this;
    timeoutID = setTimeout(function() {
      fn.apply(that, args);
    }, delay);
  };
};

const throttle = (func: any => any, limit: number) => {
  let inThrottle;
  return function(...args: any) {
    const that = this;
    if (!inThrottle) {
      func.apply(that, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

const uniqBy = (array: any[], property: string): any[] =>
  array.filter(
    (value, index, self) => self.findIndex(v => v[property] === value[property]) === index
  );

function getDeviceId() {
  const deviceIdCacheKey = 'device_id';
  const MINUTES_IN_NINETY_DAYS = 90 * 24 * 60;
  return cacheGet(deviceIdCacheKey) || cacheSet(deviceIdCacheKey, uuidv4(), MINUTES_IN_NINETY_DAYS);
}

function _getSiteVisitedCachedCount() {
  const siteVisitCountKey = 'site_visited_count';
  const siteVisitedCount = cacheGet(siteVisitCountKey);

  if (siteVisitedCount && typeof Number(siteVisitedCount) === 'number') {
    return cacheSet(siteVisitCountKey, Number(siteVisitedCount) + 1);
  }
  return cacheSet(siteVisitCountKey, 1);
}

const SITE_VISITED_COUNT_CACHED = _getSiteVisitedCachedCount();

const isMobile = window.innerWidth < 768;
const isTablet = window.innerWidth <= 1024;
const isDesktop = window.innerWidth >= 768;
const isXLDesktop = window.innerWidth >= 1280;
const is2XLDesktop = window.innerWidth >= 1536;

// $FlowFixMe
const isTouch = 'ontouchstart' in document.documentElement;
const isIOS = ['iPhone', 'iPad', 'iPod'].includes(window.navigator.platform);

function getSignupReference() {
  const { signup_reference } = parseQuery();
  const cachedSignupRef = cacheGet('utm_campaign_cached');
  const ref = signup_reference || cachedSignupRef;
  return ref ? String(ref) : null;
}

function getGuestToken() {
  const UUID_LENGTH = 36;
  const cached = cacheGet('guest-token');
  if (cached && cached.length === UUID_LENGTH) return cached;
  return cacheSet('guest-token', uuidv4());
}

const hasLatinChars = (str: string) =>
  /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/g.test(String(str));

// $FlowFixMe
const lowerArray = (arr: string[]) => arr.map(element => element.toLowerCase());

export {
  buildQueryString,
  cleanObject,
  debounce,
  getDeviceId,
  getGuestToken,
  getNumericQuery,
  getSignupReference,
  hasLatinChars,
  is2XLDesktop,
  isDesktop,
  isIOS,
  isMobile,
  isTablet,
  isTouch,
  isXLDesktop,
  lowerArray,
  parseQuery,
  SITE_VISITED_COUNT_CACHED,
  throttle,
  uniqBy,
};
