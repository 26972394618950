// @flow
import type { UserType, SignupMethodType } from 'types/resources/user';
import { CountryType } from 'types/resources/country';
import { getUserVisitingStatus } from 'utils/user';
import { cacheGet } from 'utils/localStorageCache';
import { cleanObject } from '../../utils';
import { titleCase } from './product';
import type { TrackingProperties } from './product';
import { currentLanguage } from '../../store/constants/language';
import { getClevertapProperties, mapUrlToSiteType } from './utils';

class ClevertapAnalyticsManager {
  country: string;

  ip: string;

  initialLandUrl = window.location.href;

  setContext({ country }: { country: CountryType }) {
    this.country = country.name;
  }

  identify = (user: UserType) => {
    if (!window.clevertap) return;
    if (user) {
      window.clevertap.onUserLogin.push({
        Site: {
          ...(user.fullname ? { Name: `${user.fullname} ` } : {}),
          Identity: user.id,
          Email: user.email,
          ...(user.country_code && user.phone && !user.phone.includes('deleted')
            ? { Phone: `${user.country_code}${user.phone}` }
            : {}),
          ...(user.dob ? { DOB: new Date(user.dob) } : {}),
          Photo: user.avatar || null,
          'Browser Language': window.navigator.language,
          'Preferred Language': currentLanguage,
          'MSG-email': user.notification_preferences.promotions.email,
          'MSG-push': true,
        },
      });
    }
  };

  track = (eventName: string, properties?: TrackingProperties) => {
    if (!window.clevertap) return;

    if (!this.ip) {
      this.ip = cacheGet('detected_ip_address')?.ip;
    }
    const initialLand = this.initialLandUrl ? mapUrlToSiteType(this.initialLandUrl) : undefined;

    window.clevertap.event.push(titleCase(eventName), {
      ...getClevertapProperties(cleanObject(properties)),
      Platform: 'Web',
      Country: this.country,
      IP: this.ip,
      ...(initialLand && { initial_land: initialLand }),
    });
  };

  signup = (user: UserType, method: SignupMethodType, properties?: TrackingProperties) => {
    if (!window.clevertap) return;
    this.identify(user);
    this.track('Signup: Complete', {
      Email: user.email,
      Method: method,
      ...properties,
    });
    window.clevertap.profile.push({
      Site: {
        Identity: user.id,
        'User ID': user.id,
        'Signup Date': new Date().toISOString(),
        'Signup Method': method,
        'Signup Reference': user.signup_reference || '',
      },
    });
  };

  productSearch = (operation: string, search: string, total: number) => {
    if (!window.clevertap) return;
    this.track(operation, {
      Term: search,
      'Term Length': search.length,
      '# of Results': total,
      'User Visiting Status': getUserVisitingStatus(),
    });
    if (window.clevertap.handleIncrementValue)
      window.clevertap.handleIncrementValue('# of Searches', 1);
  };

  unsubscribeEmail = (user: UserType, unsubscribe: boolean, reason: any) => {
    window.clevertap.onUserLogin.push({
      Site: {
        Identity: user.id,
        'MSG-email': !unsubscribe,
      },
    });

    reason !== '' && this.track('Email Unsubscribe', { Reason: reason });
  };
}

const ClevertapAnalytics = new ClevertapAnalyticsManager();

export default ClevertapAnalytics;
