// @flow
import type { ProductType } from 'types/resources/product';
import type { AlgoliaParamsType } from 'store/views/search';

import { action, thunk } from 'easy-peasy';

import Analytics from 'services/analytics';

import { fetchProducts } from './search';

type Store = {
  isBrowsing: boolean,
  isFilterWindowVisible: boolean,
  browseParams: { [key: string]: any },
  browseProducts: { total: number, results: ProductType[] },
};

const defaultBrowseParams: AlgoliaParamsType = {
  filter: {},
  filterString: '',
  sort: 'search',
  page: 0,
};

export default {
  browseParams: defaultBrowseParams,
  setBrowseParams: action((store: Store, browseParams = defaultBrowseParams) => {
    store.browseParams = browseParams;
  }),

  isFilterWindowVisible: false,
  toggleFilterWindow: action((store: Store) => {
    store.isFilterWindowVisible = !store.isFilterWindowVisible;
  }),

  isBrowsing: false,
  setIsBrowsing: action((store: Store, isBrowsing) => {
    store.isBrowsing = isBrowsing;
  }),
  browseProducts: {
    results: [],
    total: 0,
  },
  setBrowseProducts: action((store: Store, browseProducts) => {
    store.isBrowsing = false;
    if (browseProducts.searchProductsFound) {
      store.browseProducts = browseProducts;
    } else {
      store.browseProducts = { results: [], total: 0 };
    }
  }),

  fetchBrowseProducts: thunk((actions, { search, ...params }, { getStoreState }) => {
    actions.setIsBrowsing(true);
    Analytics.browseView({ search, ...params });
    fetchProducts(search, params, getStoreState()).then(actions.setBrowseProducts);
  }),
};
