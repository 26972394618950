// @flow
import type { ProductType } from 'types/resources/product';
import type { TransactionType } from 'types/resources/transaction';
import type { OfferListType } from 'types/resources/offerList';
import type { PromocodeType } from 'types/resources/promocode';

interface BuyType extends TransactionType, OfferListType {}

const getBuyItem = (
  buy: BuyType
): {
  item_variant?: string,
  price: number,
} => {
  const productPrice = parseInt(buy?.local_price || buy?.offer_price_local || 0);

  return {
    ...(buy?.size ? { item_variant: buy?.size } : {}),
    price: productPrice,
  };
};

const getProductItem = (product: ProductType) => ({
  item_brand: product.main_brand || typeof undefined,
  item_category: product.class || typeof undefined,
  item_category2: String(product.category) || typeof undefined,
  item_id: String(product.id) || typeof undefined,
  item_list_id: product.sku || typeof undefined,
  item_list_name: product.sku || typeof undefined,
  item_name: product.name || typeof undefined,
  quantity: 1,
});

const getPromocodeInfo = (promocode: PromocodeType) => ({
  creative_name: String(promocode.description),
  promotion_id: String(promocode.code),
  promotion_name: String(promocode.description),
  creative_slot: '',
  location_id: '',
});

export { getProductItem, getBuyItem, getPromocodeInfo };
