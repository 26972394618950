// @flow
import { Trans } from '@lingui/macro';

import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';

import { Button, Link, Text, Flex } from 'components/base';
import { cacheGet, cacheSet } from 'utils/localStorageCache';
import { GATrackConsent } from 'services/analytics/google-analytics';
import PageContainer from '../PageContainer';

const CookieUseConsent = () => {
  const countryCode = useStoreState(s => s.country.current.shortcode);
  const [cookieResponse, setCookieResponse] = useState<'granted' | 'denied'>(
    cacheGet('cookie_response')
  );

  if (cookieResponse) return null;

  // Only let the people of EU choose.
  // EU doesn't include the great britain, they don't get to choose, they left the EU
  if (!window.EU_REGIONS.includes(countryCode)) {
    return null;
  }

  const onAction = (response: 'granted' | 'denied') => {
    setCookieResponse(response);
    cacheSet('cookie_response', response);
    GATrackConsent(response);
  };

  return (
    <Flex
      bg="white"
      width={1}
      style={{
        boxShadow: '0 -4px 12px rgba(0, 0, 0, 0.1)',
        position: 'fixed',
        zIndex: 9999999,
        bottom: 0,
      }}
    >
      <PageContainer width={1}>
        <Flex
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
          alignItems="center"
          gap={12}
          p={5}
          pt={4}
        >
          <Text fontSize={[1, 2]} maxWidth={880}>
            <div style={{ lineHeight: 1.8, marginBottom: 2 }} className="upper">
              <b>
                <Trans>We value your privacy.</Trans>
              </b>
            </div>
            <div style={{ lineHeight: 1.4 }}>
              <Trans>
                We use cookies to enhance your browsing experience, serve personalized content and
                analyze our traffic. By clicking “Accept all Cookies”, you consent to our{' '}
                <Link to="/privacy" className="link-page">
                  Use of Cookies
                </Link>
                .
              </Trans>
            </div>
          </Text>
          <Flex gap={8} minWidth={170} flexDirection={['row', 'row', 'column']}>
            <Button className="sm" variant="black" onClick={() => onAction('granted')}>
              Accept All Cookies
            </Button>
            <Button className="sm" variant="white" onClick={() => onAction('denied')}>
              Reject
            </Button>
          </Flex>
        </Flex>
      </PageContainer>
    </Flex>
  );
};

export default CookieUseConsent;
